import { Injectable } from '@angular/core';
import { Permission } from '../shared/global';
import { User } from './user.service';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class TokenStorageService {
	private readonly _isLoggedInKey: string = 'auth-logged-in';
	private readonly _tokenKey: string = 'auth-token';
	private readonly _userKey: string = 'auth-user';

	constructor() { }

	signOut(): void {
		window.sessionStorage.clear();
	}

	public saveToken(token: string): void {
		window.sessionStorage.removeItem(this._tokenKey);
		window.sessionStorage.setItem(this._tokenKey, token);
	}

	public getToken(): string {
		return sessionStorage.getItem(this._tokenKey);
	}

	public saveUser(user: User): void {
		window.sessionStorage.removeItem(this._userKey);
		window.sessionStorage.setItem(this._userKey, JSON.stringify(user));
	}

	public getUser(): User {
		return JSON.parse(sessionStorage.getItem(this._userKey));
	}

	public saveIsLoggedIn(isLoggedIn: boolean): void {
		window.sessionStorage.removeItem(this._isLoggedInKey);
		window.sessionStorage.setItem(this._isLoggedInKey, isLoggedIn.toString());
	}

	public getIsLoggedIn(): boolean {
		const isLoggedIn = sessionStorage.getItem(this._isLoggedInKey);
		return isLoggedIn === 'true';
	}

	public hasPermission(permissionName: string): boolean {
		const user: User = this.getUser();
		if (!user) {
			return false;
		}

		if (_.includes(user.permissionList, Permission.SUPER_ADMIN)) {
			return true;
		}

		return _.includes(user.permissionList, permissionName);
	}
}