import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { HelperService } from 'src/app/helpers/helper.service';
import { AuthService, ChangePasswordArgs } from 'src/app/services/auth.service';
import * as _ from 'lodash';

@Component({
	selector: 'change-password',
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
	private def: any = {
		args: {
			oldPassword: null,
			newPassword: null,
			newPasswordConfirm: null
		},
		errors: {
			oldPassword: [],
			newPassword: [],
			newPasswordConfirm: [],
		}
	}

	public args: ChangePasswordArgs;
	public errors: any;

	public loading: boolean = false;

	constructor(
		private _authService: AuthService,
		private _snotify: SnotifyService,
		private _router: Router
	) { }

	ngOnInit(): void {
		this.reset();
	}

	public changePassword(): void {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (this.validateArguments()) {
			this._authService.changePassword(this.args)
				.subscribe((): void => {
					this.args = new ChangePasswordArgs();
					this.loading = false;
					this._snotify.success('Password change success');

					setTimeout((): void => {
						this._router.navigate(["/home"]);
					}, 1000);
				}, (err: any): void => {
					console.log(err);
					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						case 401:
							this._snotify.warning('Invalid username and/or password');
							break;
						default:
							this._snotify.error('Unexpected error has occurred. Please contact your system administrator.', 'App Error');
							break;
					}

					this.loading = false;
				});
		} else {
			this._snotify.warning('Please correct the indicated data fields', 'Invalid Data');
			this.loading = false;
		}
	}

	public reset(): void {
		this.args = _.cloneDeep(this.def.args);
		this.errors = _.cloneDeep(this.def.errors);
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	private validateArguments(): boolean {
		let returnValue: boolean = true;

		this.errors = _.cloneDeep(this.def.errors);

		if (!this.args.oldPassword) {
			this.errors.oldPassword.push('Password is required');
			returnValue = false;
		} else if (this.args.oldPassword.length < 8 || this.args.oldPassword.length > 100) {
			this.errors.oldPassword.push('Password must be between 8 and 100 characters in length');
			returnValue = false;
		}

		if (!this.args.newPassword) {
			this.errors.newPassword.push('Password is required');
			returnValue = false;
		} else if (this.args.newPassword.length < 8 || this.args.newPassword.length > 100) {
			this.errors.newPassword.push('Password must be between 8 and 100 characters in length');
			returnValue = false;
		}

		if (!this.args.newPasswordConfirm) {
			this.errors.newPasswordConfirm.push('Password is required');
			returnValue = false;
		} else if (this.args.newPasswordConfirm.length < 8 || this.args.newPasswordConfirm.length > 100) {
			this.errors.newPasswordConfirm.push('Password must be between 8 and 100 characters in length');
			returnValue = false;
		}

		if (this.args.newPassword && this.args.newPasswordConfirm && this.args.newPassword !== this.args.newPasswordConfirm) {
			this.errors.newPassword.push('Passwords do not match');
			this.errors.newPasswordConfirm.push('Passwords do not match');
			returnValue = false;
		}

		return returnValue;
	}
}
