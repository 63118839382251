import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { CustomerReportItem, CustomersService, GlobalNumber, GlobalNumberData, SetGlobalNumberDescriptionArgs, SetGlobalNumberNotesArgs, UnflagGlobalNumberArgs } from '../services/customers.service';
import { FacilityOption, SharedService } from '../services/shared.service';
import { TokenStorageService } from '../services/token-storage.service';

declare var $: any;

@Component({
  selector: 'global-numbers-edit-modal',
  templateUrl: './global-numbers-edit-modal.component.html'
})

export class GlobalNumbersEditModalComponent implements OnInit {
  @Input() customer: CustomerReportItem;

  public globalNumbers: GlobalNumber[];
  public selectedGlobalNumber: GlobalNumber = null;
  public selectedGlobalNumberIndex: number = -1;

  public facilities: Record<string, string> = {
    GLOBAL: "Global" 
  };

  public globalNumbersData: Record<number, GlobalNumberData> = {};
  public globalNumbersConfirmations: Record<number, boolean> = {};

  public isFacilityListLoaded: boolean = false;
  public isSavingDescription: boolean = false;
  public isSavingNotes: boolean = false;
  public isUnflagging: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _sharedService: SharedService,
    private readonly _snotify: SnotifyService,
    private readonly _tokenStorage: TokenStorageService,
    private readonly _customersService: CustomersService) { }

  public loadGlobalNumbersData(): void {

    this.globalNumbersData = {};
    this.globalNumbersConfirmations = {};

    for (let i = 0; i < this.globalNumbers.length; i++) {
      const globalNumber: GlobalNumber = this.globalNumbers[i];
      globalNumber.description = globalNumber.description ?? '';
      globalNumber.notes = globalNumber.notes ?? '';

      const data: GlobalNumberData = {
        description: _.cloneDeep(globalNumber.description),
        notes: _.cloneDeep(globalNumber.notes)
      };

      this.globalNumbersData[i] = data;
      this.globalNumbersConfirmations[i] = false;
    }
  }

  ngOnInit(): void {
    this.globalNumbers = this.customer.globalNumbers;

    this.loadGlobalNumbersData();
    this.loadFacilities();
  }

  public hasPermission(permission: string): boolean {
    return this._tokenStorage.hasPermission(permission);
  }

  public isGloballyRestricted(index: number): boolean {
    const globalNumber = this.globalNumbers[index];
    return (globalNumber.siteId.toUpperCase() === "GLOBAL");
  }

  private saveFlagDescription(index: number, newDescription: string): void {
    this.isSavingDescription = true;

    let globalNumber: GlobalNumber = this.globalNumbers[index];
    const args: SetGlobalNumberDescriptionArgs = {
      customerId: this.customer.id,
      siteId: globalNumber.siteId,
      description: newDescription
    };

    this._customersService.setGlobalNumberDescription(args)
      .subscribe((): void => {

        this.globalNumbersData[index].description = newDescription;
        this.globalNumbers[index].description = newDescription;
        this.isSavingDescription = false;
        this._snotify.success('Flag Description saved successfully');
      }, (err: any): void => {
        console.error('Failed to save flag description', err);
        this.isSavingDescription = false;
      }, (): void => {
        //
      });
  }

  private saveFlagNotes(index: number, newNotes: string): void {
    this.isSavingNotes = true;

    let globalNumber: GlobalNumber = this.globalNumbers[index];
    const args: SetGlobalNumberNotesArgs = {
      customerId: this.customer.id,
      siteId: globalNumber.siteId,
      notes: newNotes
    };

    this._customersService.setGlobalNumberNotes(args)
      .subscribe((): void => {

        this.globalNumbersData[index].notes = newNotes;
        this.globalNumbers[index].notes = newNotes;
        this.isSavingNotes = false;
        this._snotify.success('Flag Notes saved successfully');
      }, (err: any): void => {
        console.error('Failed to save flag notes', err);
        this.isSavingNotes = false;
      }, (): void => {
        this.isSavingNotes = false;
      });
  }

  public async loadFacilities() {
    const facilities: FacilityOption[] = await this._sharedService.getFacilities().toPromise();
    for (let i = 0; i < facilities.length; i++) {
      let facilityOption: FacilityOption = facilities[i];
      this.facilities[facilityOption.siteId] = facilityOption.name;
    }
    this.isFacilityListLoaded = true;
  }

  public isDataChanged(index: number): boolean {
    let status: boolean = false;

    const globalNumber: GlobalNumber = this.globalNumbers[index];
    const data: GlobalNumberData = this.globalNumbersData[index];

    if (globalNumber.description !== data.description)
      status = true;

    if (globalNumber.notes !== data.notes)
      status = true;

    return status;
  }

  private resetDataById(index: number): void {
    let globalNumber: GlobalNumber = this.globalNumbers[index];
    this.globalNumbersData[index].description = _.cloneDeep(globalNumber.description);
    this.globalNumbersData[index].notes = _.cloneDeep(globalNumber.notes);
  }

  public onRowClick(index: number): void {

    if (this.isSavingDescription || this.isSavingNotes)
      return;

    if (this.selectedGlobalNumberIndex >= 0 &&
        this.selectedGlobalNumberIndex !== index &&
        this.isDataChanged(this.selectedGlobalNumberIndex)) {

      this.resetDataById(this.selectedGlobalNumberIndex);
      this._snotify.warning('Data not saved.', 'Caution');
    }

    this.selectedGlobalNumberIndex = index;
    this.selectedGlobalNumber = this.globalNumbers[index];

  }

  public onRemoveClick(index: number): void {
    this.globalNumbersConfirmations[index] = true;
  }

  public onDeclineClick(index: number): void {
    this.globalNumbersConfirmations[index] = false;
  }

  public async onConfirmClick(index: number) {
    if (this.isUnflagging)
      return;

    const globalNumber: GlobalNumber = this.globalNumbers[index];

    const args: UnflagGlobalNumberArgs = {
      customerId: this.customer.id,
      siteId: globalNumber.siteId
    };
    this.isUnflagging = true;
    this._customersService.unflagCustomer(args)
      .subscribe((): void => {
        this._snotify.success('Unflagged successfully');
        this.selectedGlobalNumberIndex = -1;
        this.selectedGlobalNumber = null;
        //clean up element
        delete this.globalNumbersData[index];
        delete this.globalNumbersConfirmations[index];

        this.globalNumbers.splice(index, 1);
        
        this.isUnflagging = false;
        //reload stuff
        this.loadGlobalNumbersData();
        if (this.globalNumbers.length === 0)
          this.activeModal.close();

      }, (err: any): void => {
        console.error('Failed to save flag description', err);
        this.isUnflagging = false;
      }, (): void => {
        //
      });
  }

  public onResetClick(): void {
    const index: number = this.selectedGlobalNumberIndex;
    this.resetDataById(index);
  }

  

  public onSaveClick(): void {
    const index: number = this.selectedGlobalNumberIndex;
    let globalNumber: GlobalNumber = this.globalNumbers[index];
    let data: GlobalNumberData = this.globalNumbersData[index];

    if (globalNumber.description !== data.description) {
      if (data.description.length === 0) {
        this._snotify.warning('Description cannot be empty.', 'Invalid data')
        return;
      }
      this.saveFlagDescription(index, data.description);
    }

    if (globalNumber.notes !== data.notes) {
      if (data.notes.length === 0) {
        this._snotify.warning('Notes cannot be empty.', 'Invalid data')
        return;
      }
      this.saveFlagNotes(index, data.notes);
    }
  }

  

  public isSaveAvailable(): boolean {
    if (this.isSavingDescription || this.isSavingNotes || this.isUnflagging)
      return false;

    if (this.selectedGlobalNumberIndex === -1)
      return false;

    return this.isDataChanged(this.selectedGlobalNumberIndex);
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }
}
