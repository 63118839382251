import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerReport, CustomerReportArgs, CustomerReportItem, CustomersService, FlagCustomerArgs, GlobalNumber, SetGlobalNumberDescriptionArgs, SetGlobalNumberNotesArgs } from 'src/app/services/customers.service';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { CacheService } from 'src/app/services/cache.service';
import { HelperService, UniqueIdType } from 'src/app/helpers/helper.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { PsiModalSettings } from 'src/app/shared/components/psi-modal/psi-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalNumbersEditModalComponent } from '../../global-numbers-edit-modal/global-numbers-edit-modal.component';
import { OrderReport } from '../../services/orders.service';


@Component({
  selector: 'app-search-customers',
  templateUrl: './search-customers.component.html'
})
export class SearchCustomersComponent implements OnInit {
  public page: number = 1;
  public pageSize: number = 10;
  public collectionSize: number = 0;
  public sortField: string = 'Id';
  public sortDesc: boolean = true;

  private def: any = {
    filters: {
      firstName: null,
      lastName: null,
      address1: null,
      city: null,
      state: null,
      zip: null,
      phoneNumber: null,
      email: null,
      accountPin: null,
      userName: null
    },
    filterOptions: {
      states: HelperService.getUsStateList()
    },
    flagCustomerDescription: null,
    blockRefund: false

  };

  public flagCustomerModalSettings: PsiModalSettings = {
    openModalButtonText: 'Flag the customer account for fraud',
    title: 'Flag Customer Account For Fraud',
    spanClass: 'fas fa-flag clickable color-red',
    confirmBtnText: 'Yes',
    closeBtnText: 'No',
    openerType: 'icon',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public unflagCustomerModalSettings: PsiModalSettings = {
    openModalButtonText: 'Unflag the customer account for fraud',
    title: 'Unflag Customer Account For Fraud',
    spanClass: 'far fa-flag clickable color-green',
    confirmBtnText: 'Yes',
    closeBtnText: 'No',
    openerType: 'icon',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public filters: any = _.cloneDeep(this.def.filters);
  public filterOptions: any = _.cloneDeep(this.def.filterOptions);

  public flagCustomerDescription: string = _.cloneDeep(this.def.flagCustomerDescription);
  public blockRefund: boolean = _.cloneDeep(this.def.blockRefund);

  public reportArgs: CustomerReportArgs = new CustomerReportArgs();
  public report: CustomerReport = new CustomerReport();

  public flaggingCustomer: boolean = false;
  public loadingReport: boolean = false;
  public reportLoaded: boolean = false;
  public unflaggingCustomer: boolean = false;


  constructor(
    private readonly _customersService: CustomersService,
    private readonly _router: Router,
    private readonly _snotify: SnotifyService,
    private readonly _tokenStorage: TokenStorageService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    const savedFilters = CacheService.getItem(CacheService.keys.CUSTOMER_REPORT_FILTERS);
    if (savedFilters) {
      this.filters = _.cloneDeep(savedFilters);
    }

    //this.loadReport();
  }


  public cancelFlagCustomerBtnClick(customer: CustomerReportItem): void {
    this.flagCustomerDescription = _.cloneDeep(this.def.flagCustomerDescription);
    this.blockRefund = _.cloneDeep(this.def.blockRefund);
    this._snotify.warning(`Customer ${customer.firstName} ${customer.lastName} flag cancelled`, 'Flag Cancelled');
  }

  public cancelUnflagCustomerBtnClick(customer: CustomerReportItem): void {
    this._snotify.warning(`Customer ${customer.firstName} ${customer.lastName} unflag cancelled`, 'Unflag Cancelled');
  }

  public confirmFlagCustomerBtnClick(customer: CustomerReportItem): void {
    if (this.flaggingCustomer) {
      return;
    }

    this.flaggingCustomer = true;

    const args: FlagCustomerArgs = {
      customerId: customer.id,
      description: this.flagCustomerDescription,
      blockRefund: this.blockRefund
    };

    if (this.validateFlagCustomerArgs(args)) {
      this._customersService.flagCustomer(args)
        .subscribe((): void => {
          this.loadRestrictedGlobalNumbers(customer);
          this.flaggingCustomer = false;
          this.blockRefund = _.cloneDeep(this.def.blockRefund);
          this._snotify.success(`Customer ${customer.firstName} ${customer.lastName} successfully flagged`, 'Flag Success');
        }, (err: any): void => {
          console.error('Failed to flag the customer', err);
          this.flaggingCustomer = false;
          this.blockRefund = _.cloneDeep(this.def.blockRefund);
          switch (err.status) {
            case 400:
              HelperService.getModelStateErrors(err).forEach(errMsg => {
                this._snotify.warning(errMsg, 'Invalid Data');
              });
              break;
            default:
              this._snotify.error('An unexpected error occurred attempting to flag the customer.', 'Flag Failed');
              break;
          }
        });
    } else {
      this.flaggingCustomer = false;
    }
  }

  public confirmUnflagCustomerBtnClick(customer: CustomerReportItem): void {
    if (this.unflaggingCustomer) {
      return;
    }

    this.unflaggingCustomer = true;

    /*this._customersService.unflagCustomer(customer.id)
      .subscribe((): void => {
        this.loadRestrictedGlobalNumbers(customer);
        this.unflaggingCustomer = false;
        this._snotify.success(`Customer ${customer.firstName} ${customer.lastName} successfully unflagged`, 'Unflag Success');
      }, (err: any): void => {
        console.error('Failed to unflag the customer', err);
        this.unflaggingCustomer = false;
        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to unflag the customer.', 'Unflag Failed');
            break;
        }
      });*/

    // setTimeout((): void => {
    // 	customer.flaggedForFraud = false;
    // 	this.unflaggingCustomer = false;
    // 	this._snotify.success(`Customer ${customer.firstName} ${customer.lastName} successfully unflagged`, 'Unflag Success');
    // }, 1000);
  }

  public editCustomer(customer: CustomerReportItem): void {
    this._router.navigate(['/customers/edit', customer.id]);
  }

  public hasPermission(permission: string): boolean {
    return this._tokenStorage.hasPermission(permission);
  }
  public loadRestrictedGlobalNumbers(item: CustomerReportItem): void {
    if (item.loadingGlobalNumber) {
      return;
    }

    item.loadingGlobalNumber = true;

    this._customersService.getRestrictedGlobalNumbers(item.id)
      .subscribe((result: GlobalNumber[]): void => {
        item.globalNumbers = result;
        item.loadingGlobalNumber = false;
      }, (err: any): void => {
        console.error('Failed to retrieve customer global number', err);
        item.loadingGlobalNumber = false;
        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to load the customer\'s flagged status.', 'Load Failed');
            break;
        }
      }, (): void => {
        //
      });
  }
  /*public loadGlobalNumber(item: CustomerReportItem): void {
    if (item.loadingGlobalNumber) {
      return;
    }

    item.loadingGlobalNumber = true;

    this._customersService.getGlobalNumber(item.id)
      .subscribe((result: GlobalNumber): void => {
        item.globalNumber = result;
        item.loadingGlobalNumber = false;
      }, (err: any): void => {
        console.error('Failed to retrieve customer global number', err);
        item.loadingGlobalNumber = false;
        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to load the customer\'s flagged status.', 'Load Failed');
            break;
        }
      }, (): void => {
        //
      });
  }*/

  public loadReport(): void {
    if (this.loadingReport) {
      return;
    }

    this.loadingReport = true;

    this.updateReportArgs();

    this.report = new CustomerReport();

    this._customersService.getCustomerReport(this.reportArgs)
      .subscribe((result: CustomerReport): void => {
        this.report = result;
        this.collectionSize = result.totalRowCount;

        for (let i = 0; i < this.report.items.length; i++) {
          this.report.items[i].flagAccountModalSettings = _.cloneDeep(this.flagCustomerModalSettings);
          this.report.items[i].flagAccountModalSettings.uniqueId = HelperService.getUniqueId(UniqueIdType.MODAL);

          this.report.items[i].unflagAccountModalSettings = _.cloneDeep(this.unflagCustomerModalSettings);
          this.report.items[i].unflagAccountModalSettings.uniqueId = HelperService.getUniqueId(UniqueIdType.MODAL);
        }

        CacheService.setItem(CacheService.keys.CUSTOMER_REPORT_FILTERS, this.filters);

        this.reportLoaded = true;
        this.loadingReport = false;
      }, (err: any): void => {
        console.error('Failed to retrieve customer report', err);
        this.loadingReport = false;
        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to load the customers report.', 'Load Failed');
            break;
        }
      }, (): void => {
        //
      });
  }

  public redirectToCreateCustomer(): void {
    this._router.navigate(['/customers/create']);
  }

  public reset(): void {
    this.filters = _.cloneDeep(this.def.filters);
    CacheService.setItem(CacheService.keys.CUSTOMER_REPORT_FILTERS, this.filters);
  }
  /*
  public saveFlagDescription(item: CustomerReportItem): void {
    if (item.savingDescription) {
      return;
    }

    item.savingDescription = true;

    const args: SetGlobalNumberDescriptionArgs = {
      customerId: item.id,
      description: item.globalNumber.description
    };
    this._customersService.setGlobalNumberDescription(args)
      .subscribe((): void => {
        item.savingDescription = false;
        this._snotify.success('Flag Description saved successfully');
      }, (err: any): void => {
        console.error('Failed to save flag description', err);
        item.savingDescription = false;
        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to save the flag description.', 'Save Failed');
            break;
        }
      }, (): void => {
        //
      });
  }
  */
  /*public saveFlagNotes(item: CustomerReportItem): void {
    if (item.savingNotes) {
      return;
    }

    item.savingNotes = true;

    const args: SetGlobalNumberNotesArgs = {
      customerId: item.id,
      notes: item.globalNumber.notes
    };
    this._customersService.setGlobalNumberNotes(args)
      .subscribe((): void => {
        item.savingNotes = false;
        this._snotify.success('Flag Notes saved successfully');
      }, (err: any): void => {
        console.error('Failed to save flag notes', err);
        item.savingNotes = false;
        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to save the flag notes.', 'Save Failed');
            break;
        }
      }, (): void => {
        //
      });
  }
  */
  public sortTable($event: any, col: string): void {
    if (!this.loadingReport) {
      const target = $event.target || $event.srcElement || $event.currentTarget;
      for (let i = 0; i < target.parentElement.children.length; i++) {
        target.parentElement.children[i].classList.remove('headerSortUp');
        target.parentElement.children[i].classList.remove('headerSortDown');
      }

      this.sortDesc = !this.sortDesc;
      if (this.sortDesc) {
        target.classList.remove('headerSortUp');
        target.classList.add('headerSortDown');
      } else {
        target.classList.remove('headerSortDown');
        target.classList.add('headerSortUp');
      }
      this.sortField = col;

      this.loadReport();
    }
  }

  public toggleDetails(item: CustomerReportItem): void {
    item.showDetails = !item.showDetails;

    if (item.showDetails && !item.globalNumbers) {
      this.loadRestrictedGlobalNumbers(item);
    }
  }

  public updateReportArgs(): void {
    this.reportArgs = { ...this.filters };

    this.reportArgs.startIndex = this.pageSize * (this.page - 1);
    this.reportArgs.itemsPerPage = this.pageSize;
    this.reportArgs.sortField = this.sortField;
    this.reportArgs.sortOrder = this.sortDesc ? 'DESC' : 'ASC';
    this.reportArgs.phoneNumber = HelperService.stripNonNumeric(this.reportArgs.phoneNumber);
  }

  public validateFlagCustomerArgs(args: FlagCustomerArgs): boolean {
    if (!args.customerId) {
      this._snotify.warning('Invalid Customer. Please refresh the page and try again.');
      return false;
    }

    if (!args.description) {
      this._snotify.warning('Please enter a description');
      return false;
    } else if (args.description.length > 20) {
      this._snotify.warning('Description length cannot exceed 20 characters');
      return false;
    }

    return true;
  }

  public isFlagged(item: CustomerReportItem): boolean {
    const globalNumbers: GlobalNumber[] = item.globalNumbers;
    if (globalNumbers && globalNumbers.length > 0)
      return true;
    return false;
  }

  public getFlaggedStatusLabel(item: CustomerReportItem): string {
    const globalNumbers: GlobalNumber[] = item.globalNumbers;
    const flaggedStatus: boolean = this.isFlagged(item);
    const statusLabel: string = (flaggedStatus) ? `Yes (${globalNumbers.length})` : 'No';
    return statusLabel;
  }

  public openEditModal(item: CustomerReportItem): void {
    const modalRef = this.modalService.open(GlobalNumbersEditModalComponent);
    modalRef.componentInstance.customer = item;
  }

}
