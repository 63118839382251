import { Pipe, PipeTransform } from '@angular/core';

// Credit to https://stackoverflow.com/questions/44669340/how-to-truncate-text-in-angular2

@Pipe({
	name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
	transform(value: string | undefined | null, args: any[]): string {
		const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
		const trail = args.length > 1 ? args[1] : '...';

		if (!value) {
			return value;
		}

		return value.length > limit ? value.substring(0, limit) + trail : value;
	}
}