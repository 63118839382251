import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { HelperService } from 'src/app/helpers/helper.service';
import { CreateCustomerArgs, CustomersService } from 'src/app/services/customers.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-create-customer',
	templateUrl: './create-customer.component.html'
})
export class CreateCustomerComponent implements OnInit {
	private def: any = {
		filters: {
			userName: null,
			password: null,
			passwordConfirm: null,
			email: null,
			secretQuestion: null,
			secretAnswer: null,
			firstName: null,
			lastName: null,
			address1: null,
			address2: null,
			city: null,
			state: null,
			postalZip: null,
			phoneNumber: null,
			accountPin: null
		},
		errors: {
			userName: [],
			password: [],
			passwordConfirm: [],
			email: [],
			secretQuestion: [],
			secretAnswer: [],
			firstName: [],
			lastName: [],
			address1: [],
			address2: [],
			city: [],
			state: [],
			postalZip: [],
			phoneNumber: [],
			accountPin: []
		}
	};

	public filters: CreateCustomerFilters;
	public errors: any;

	public filterOptions: any = {
		states: HelperService.getUsStateList(),
		secretQuestions: HelperService.getSecurityQuestionList()
	};

	public creating: boolean = false;


	constructor(
		private _customersService: CustomersService,
		private _router: Router,
		private _snotify: SnotifyService,
		private _spinner: NgxSpinnerService
	) { }

	ngOnInit(): void {
		this.reset();
		/** spinner starts on init */
		this._spinner.show();

		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this._spinner.hide();
		}, 5000);
	}


	public cancel(): void {
		this._router.navigate(["/customers/search"]);
	}

	public create(): void {
		if (this.creating) {
			return;
		}

		this.creating = true;

		if (this.validateFormData()) {
			const args: CreateCustomerArgs = { ...this.filters };
			args.phoneNumber = HelperService.stripNonNumeric(args.phoneNumber);

			this._customersService.createCustomer(args)
				.subscribe(() => {
					this._snotify.success('Customer successfully created!', 'Create Success');
					this.reset();
					this.creating = false;
				}, (err: any): void => {
					console.error('Failed to create customer', err);

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred creating the customer.', 'Create Failed');
							break;
					}

					this.creating = false;
				});
		} else {
			this._snotify.warning('Please correct the indicated data fields', 'Invalid Data');
			this.creating = false;
		}
	}

	public reset(): void {
		this.filters = _.cloneDeep(this.def.filters);
		this.errors = _.cloneDeep(this.def.errors);
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	private validateFormData(): boolean {
		let returnValue: boolean = true;

		this.errors = _.cloneDeep(this.def.errors);

		// Validate username
		if (!this.filters.userName) {
			this.errors.userName.push('Username is required');
			returnValue = false;
		} else if (this.filters.userName.length < 5 || this.filters.userName.length > 100) {
			this.errors.userName.push('Username must be between 5 and 100 characters in length');
			returnValue = false;
		}

		// Validate email
		if (!this.filters.email) {
			this.errors.email.push('Email Address is required');
			returnValue = false;
		} else if (this.filters.email.length < 1 || this.filters.email.length > 256) {
			this.errors.email.push('Email Address must be between 5 and 256 characters in length');
			returnValue = false;
		} else if (!HelperService.isValidEmailAddress(this.filters.email)) {
			this.errors.email.push('Email Address is invalid');
			returnValue = false;
		}

		// Validate password
		if (!this.filters.password) {
			this.errors.password.push('Password is required');
			returnValue = false;
		} else if (this.filters.password.length < 8 || this.filters.password.length > 100) {
			this.errors.password.push('Password must be between 8 and 100 characters in length');
			returnValue = false;
		}

		if (!this.filters.passwordConfirm) {
			this.errors.passwordConfirm.push('Password is required');
			returnValue = false;
		} else if (this.filters.passwordConfirm.length < 8 || this.filters.passwordConfirm.length > 100) {
			this.errors.passwordConfirm.push('Password must be between 8 and 100 characters in length');
			returnValue = false;
		}

		if (this.filters.password && this.filters.passwordConfirm && this.filters.password !== this.filters.passwordConfirm) {
			this.errors.password.push('Passwords do not match');
			this.errors.passwordConfirm.push('Passwords do not match');
			returnValue = false;
		}

		// Validate Secret Question
		if (!this.filters.secretQuestion) {
			this.errors.secretQuestion.push('Security Question is required');
			returnValue = false;
		}

		// Validate Secret Answer
		if (!this.filters.secretAnswer) {
			this.errors.secretAnswer.push('Security Answer is required');
			returnValue = false;
		} else if (this.filters.secretAnswer.length > 100) {
			this.errors.secretAnswer.push('Security Answer cannot exceed 100 characters in length');
			returnValue = false;
		}

		// Validate first name
		if (!this.filters.firstName) {
			this.errors.firstName.push('First Name is required');
			returnValue = false;
		} else if (this.filters.firstName.length < 1 || this.filters.firstName.length > 50) {
			this.errors.firstName.push('First Name cannot exceed 50 characters in length');
			returnValue = false;
		}

		// Validate last name
		if (!this.filters.lastName) {
			this.errors.lastName.push('Last Name is required');
			returnValue = false;
		} else if (this.filters.lastName.length < 1 || this.filters.lastName.length > 50) {
			this.errors.lastName.push('Last Name cannot exceed 50 characters in length');
			returnValue = false;
		}

		// Validate address 1
		if (!this.filters.address1) {
			this.errors.address1.push('Address 1 is required');
			returnValue = false;
		} else if (this.filters.address1.length < 1 || this.filters.address1.length > 100) {
			this.errors.address1.push('Address 1 cannot exceed 100 characters in length');
			returnValue = false;
		}

		// Validate address 2
		if (this.filters.address2) {
			if (this.filters.address2.length < 1 || this.filters.address2.length > 100) {
				this.errors.address2.push('Address 2 cannot exceed 100 characters in length');
				returnValue = false;
			}
		}

		// Validate city
		if (!this.filters.city) {
			this.errors.city.push('City is required');
			returnValue = false;
		} else if (this.filters.city.length < 1 || this.filters.city.length > 50) {
			this.errors.city.push('City cannot exceed 50 characters in length');
			returnValue = false;
		}

		// Validate state
		if (!this.filters.state) {
			this.errors.state.push('State is required');
			returnValue = false;
		}

		// Validate Postal/Zip
		if (!this.filters.postalZip) {
			this.errors.postalZip.push('Postal/Zip is required');
			returnValue = false;
		} else if (this.filters.postalZip.length > 10) {
			this.errors.postalZip.push('Postal/Zip cannot exceed 10 characters in length');
			returnValue = false;
		}

		// Validate Phone Number
		if (!this.filters.phoneNumber) {
			this.errors.phoneNumber.push('Phone Number is required');
			returnValue = false;
		} else {
			const strippedPhone = HelperService.stripNonNumeric(this.filters.phoneNumber);

			if (strippedPhone.length < 10 || strippedPhone.length > 20) {
				this.errors.phoneNumber.push('Invalid Phone Number');
				returnValue = false;
			}
		}

		// Validate account PIN
		if (!this.filters.accountPin) {
			this.errors.accountPin.push('Account PIN is required');
			returnValue = false;
		} else if (this.filters.accountPin.length < 1 || this.filters.accountPin.length > 8) {
			this.errors.accountPin.push('Account PIN cannot exceed 50 characters in length');
			returnValue = false;
		}

		return returnValue;
	}
}

export class CreateCustomerFilters {
	public userName: string;
	public password: string;
	public passwordConfirm: string;
	public email: string;
	public secretQuestion: string;
	public secretAnswer: string;
	public firstName: string;
	public lastName: string;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public postalZip: string;
	public phoneNumber: string;
	public accountPin: string;
}