<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand -->
    <!-- <a class="navbar-brand" routerLink="home"><img src="/Scripts/client/assets/images/prodigy-logo.svg" class="company-logo" alt="Prodigy Solutions Inc." /></a> -->
    <a class="navbar-brand" href="#"><img src="assets/images/prodigy-logo.svg" class="company-logo" alt="Prodigy Solutions Inc." /></a>

    <!-- Links -->
    <ul class="navbar-nav" *ngIf="isLoggedIn">
        <!-- Dropdown -->
        <li class="nav-item dropdown" *ngIf="hasAnyAdminPermissions()">
            <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                Admin
            </a>
            <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="users/search" *ngIf="hasPermission('Users_View')">Users</a>
                <a class="dropdown-item" routerLink="user-actions" *ngIf="hasPermission('User_Actions_View')">User Actions</a>
                <!-- <a class="dropdown-item" href="#">Link 2</a>
                <a class="dropdown-item" href="#">Link 3</a> -->
            </div>
        </li>
        <li class="nav-item" *ngIf="hasPermission('Reports_Orders_View')">
            <a class="nav-link" routerLink="orders-report">Orders Report</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('Customers_View')">
            <a class="nav-link" routerLink="customers/search">Customers</a>
        </li>
        <li class="nav-item dropdown" *ngIf="hasAnyPaymentPermissions()">
            <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                Payments
            </a>
            <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="payments/payment-processing" *ngIf="hasPermission('Payments_Process')">Process Payments</a>
                <a class="dropdown-item" routerLink="payments/send-funds" *ngIf="hasPermission('Payments_Send_Funds')">Send Funds</a>
            </div>
        </li>
        <!-- <li class="nav-item" *ngIf="hasPermission('Payments_Process')">
            <a class="nav-link" routerLink="payment-processing">Process Payments</a>
        </li> -->
        <li class="nav-item" *ngIf="hasPermission('Product_Rates_View')">
            <a class="nav-link" routerLink="product-rates">Product Rates</a>
        </li>
    </ul>
    <ul class="navbar-nav" style="margin-left: auto;" *ngIf="isLoggedIn">
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                Account
            </a>
            <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="account/change-password">Change Password</a>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="login">Logout</a>
        </li>
    </ul>
</nav>

<router-outlet></router-outlet>
<ng-snotify></ng-snotify>