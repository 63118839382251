import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Inmate, InmatesService, SearchInmatesArgs } from 'src/app/services/inmates.service';

@Component({
	selector: 'inmates-typeahead',
	templateUrl: './inmates-typeahead.component.html'
})
export class InmatesTypeaheadComponent implements OnInit {
	@Input() public activeOnly: boolean = false;
	@Input() public allowMultiple: boolean = false;
	@Input() public siteIdList: string[] = [];
	@Input() public selectedInmates: Inmate[] = [];
	@Output() public selectedInmatesChange: EventEmitter<Inmate[]> = new EventEmitter<Inmate[]>();

	public inmates: Observable<Inmate[]>;
	public loadingInmatesTypeahead: boolean = false;
	public inmateSearchInput = new Subject<string>();


	constructor(
		private _inmatesService: InmatesService
	) { }

	ngOnInit(): void {
		this.loadInmates();
	}


	public emitChange(): void {
		this.selectedInmatesChange.emit(this.selectedInmates);
	}

	public trackInmatesByFn(item: Inmate) {
		return item.id;
	}

	public loadInmates() {
		this.inmates = concat(
			of([]), // default items
			this.inmateSearchInput.pipe(
				distinctUntilChanged(),
				tap((): void => {
					this.loadingInmatesTypeahead = true;
				}),
				switchMap((term): Observable<Inmate[]> => {
					const args: SearchInmatesArgs = {
						activeOnly: this.activeOnly,
						search: term,
						siteIdList: this.siteIdList,
						returnCount: 10
					};
					return this._inmatesService.searchInmates(args).pipe(
						catchError((): Observable<any[]> => {
							return of([]);
						}), // empty list on error
						tap((): void => {
							this.loadingInmatesTypeahead = false;
						})
					)
				})
			)
		);
	}
}
