import { Injectable } from "@angular/core";

@Injectable()
export class GlobalService {
	constructor() { }


}

export class Permission {
	public static readonly CUSTOMERS_VIEW: string = "Customers_View";
	public static readonly CUSTOMERS_EDIT: string = "Customers_Edit";
	public static readonly CUSTOMERS_CREATE: string = "Customers_Create";
	public static readonly CUSTOMERS_TRANSFER_FUNDS: string = "Customers_Transfer_Funds";
	public static readonly PAYMENTS_PROCESS: string = "Payments_Process";
	public static readonly PAYMENTS_REFUND: string = "Payments_Refund";
	public static readonly PAYMENTS_SEND_FUNDS: string = "Payments_Send_Funds";
	public static readonly PRODUCT_RATES_VIEW: string = "Product_Rates_View";
	public static readonly REPORTS_ORDERS_VIEW: string = "Reports_Orders_View";
	public static readonly REPORTS_ORDERS_SUMMARY_VIEW: string = "Reports_Orders_Summary_View";
	public static readonly SUPER_ADMIN: string = "Super_Admin";
	public static readonly USER_ACTIONS_VIEW: string = "User_Actions_View";
	public static readonly USERS_VIEW: string = "Users_View";
	public static readonly USERS_EDIT: string = "Users_Edit";
	public static readonly USERS_CREATE: string = "Users_Create";
}