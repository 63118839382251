<div class="page-container">
    <h2 class="page-title">Create Customer</h2>

    <div class="container large-container filters-backdrop">
        <form class="filters-container form-inline align-items-flex-start">
            <div class="col-6">
                <fieldset class="col-12 form-section">
                    <legend class="col-6">Account Information</legend>
                    <div class="form-row col-12">
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountUserName" class="inline-block">Username <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.userName" name="accountUserName" id="accountUserName" (input)="resetErrors('userName')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.userName && errors.userName.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.userName; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountEmail" class="inline-block">Email Address <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.email" name="accountEmail" id="accountEmail" (input)="resetErrors('email')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.email && errors.email.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.email; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountPassword" class="inline-block">Password <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.password" name="accountPassword" id="accountPassword" (input)="resetErrors('password')" type="password" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.password && errors.password.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.password; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountPasswordConfirm" class="inline-block">Confirm Password <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.passwordConfirm" name="accountPasswordConfirm" (input)="resetErrors('passwordConfirm')" id="accountPasswordConfirm" type="password" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.passwordConfirm && errors.passwordConfirm.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.passwordConfirm; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountFirstName" class="inline-block">First Name <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.firstName" name="accountFirstName" id="accountFirstName" (input)="resetErrors('firstName')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.firstName && errors.firstName.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.firstName; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountLastName" class="inline-block">Last Name <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.lastName" name="accountLastName" id="accountLastName" (input)="resetErrors('lastName')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.lastName && errors.lastName.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.lastName; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountPhoneNumber" class="inline-block">Phone Number <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.phoneNumber" name="accountPhoneNumber" id="accountPhoneNumber" (input)="resetErrors('phoneNumber')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.phoneNumber && errors.phoneNumber.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.phoneNumber; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountPin" class="inline-block">Account PIN <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.accountPin" name="accountPin" id="accountPin" type="text" (input)="resetErrors('accountPin')" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.accountPin && errors.accountPin.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.accountPin; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 filter-field-container ">
                            <label for="accountSecretQuestion" class="inline-block">Security Question <span class="color-red">*</span></label>
                            <ng-select [items]="filterOptions.secretQuestions" bindLabel="label" bindValue="label" name="accountSecretQuestion" [(ngModel)]="filters.secretQuestion" (change)="resetErrors('secretQuestion')" class="form-control full-width col-sm-12 no-padding" ngDefaultControl></ng-select>
                            <div *ngIf="errors.secretQuestion && errors.secretQuestion.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.secretQuestion; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 filter-field-container">
                            <label for="accountSecretAnswer" class="inline-block">Secret Answer <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.secretAnswer" name="accountSecretAnswer" id="accountSecretAnswer" type="text" (input)="resetErrors('secretAnswer')" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.secretAnswer && errors.secretAnswer.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.secretAnswer; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-6">
                <fieldset class="col-12 form-section">
                    <legend class="col-6">Billing Information</legend>
                    <div class="form-row col-12">
                        <div class="col-12 filter-field-container">
                            <label for="accountAddress1" class="inline-block">Address 1 <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.address1" name="accountAddress1" id="accountAddress1" (input)="resetErrors('address1')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.address1 && errors.address1.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.address1; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 filter-field-container">
                            <label for="accountAddress2" class="inline-block">Address 2</label>
                            <input [(ngModel)]="filters.address2" name="accountAddress2" id="accountAddress2" (input)="resetErrors('address2')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.address2 && errors.address2.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.address2; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-4 filter-field-container">
                            <label for="accountCity" class="inline-block">City <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.city" name="accountCity" id="accountCity" (input)="resetErrors('city')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.city && errors.city.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.city; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-6 col-md-4 filter-field-container">
							<label for="accountState" class="inline-block">State</label>
							<input [(ngModel)]="filters.state" name="accountState" id="accountState" type="text" class="form-control full-width col-sm-12" />
						</div> -->
                        <div class="col-6 col-lg-4 filter-field-container">
                            <label for="accountState" class="inline-block">State <span class="color-red">*</span></label>
                            <ng-select [items]="filterOptions.states" bindLabel="name" bindValue="abbreviation" name="accountState" [(ngModel)]="filters.state" (change)="resetErrors('state')" class="form-control full-width col-sm-12 no-padding" ngDefaultControl></ng-select>
                            <div *ngIf="errors.state && errors.state.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.state; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-4 filter-field-container">
                            <label for="accountPostalZip" class="inline-block">Postal/Zip <span class="color-red">*</span></label>
                            <input [(ngModel)]="filters.postalZip" name="accountPostalZip" id="accountPostalZip" (input)="resetErrors('postalZip')" type="text" class="form-control full-width col-sm-12" />
                            <div *ngIf="errors.postalZip && errors.postalZip.length > 0" class="psi-alert psi-alert-danger">
                                <div *ngFor="let err of errors.postalZip; let i = index">
                                    {{ err }}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
        <div class="filters-footer">
            <!--(keydown.enter)="create()"-->
            <button (click)="cancel()" type="button" class="btn btn-warning float-left report-button" [disabled]="creating"><span class="fas fa-arrow-circle-left"></span> Cancel</button>
            <button (click)="create()" type="button" class="btn btn-primary float-right report-button" [disabled]="creating"><span class="fa fa-search"></span> Create</button>
            <button (click)="reset()" type="button" class="btn btn-primary float-right report-button" [disabled]="creating"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>
</div>