import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PsiModalSettings } from '../shared/components/psi-modal/psi-modal.component';
import { Inmate } from './inmates.service';

@Injectable({
	providedIn: 'root'
})
export class CustomersService {
	private readonly _apiUrl = `${environment.apiUrl}/Customers`;

	constructor(private http: HttpClient) { }


	public changeCustomer(args: ChangeCustomerArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/Change`, args);
	}

	public changeCustomerPhone(args: ChangeCustomerPhoneArgs): Observable<number> {
		return this.http.post<any>(`${this._apiUrl}/ChangePhone`, args);
	}

	public createCustomer(args: CreateCustomerArgs): Observable<number> {
		return this.http.post<any>(`${this._apiUrl}/Create`, args);
	}

	public createCustomerNote(args: CreateCustomerNoteArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/CreateNote`, args);
	}

	public getCustomer(id: number): Observable<Customer> {
		return this.http.get<any>(`${this._apiUrl}/${id}`);
	}

	public getConnectedInmates(customerId: number): Observable<CustomerApproval[]> {
		return this.http.get<any>(`${this._apiUrl}/${customerId}/ConnectedInmates`);
	}

	public getCustomerByPhone(phoneNumber: string): Observable<Customer> {
		return this.http.get<any>(`${this._apiUrl}/CustomerByPhone?phoneNumber=${phoneNumber}`);
	}

	public getCustomerNotes(customerId: number): Observable<CustomerNote[]> {
		return this.http.get<any>(`${this._apiUrl}/${customerId}/Notes`);
	}

	public getCustomerReport(args: CustomerReportArgs): Observable<CustomerReport> {
		return this.http.post<any>(`${this._apiUrl}/Report`, args);
	}

	public getGlobalNumber(customerId: number): Observable<GlobalNumber> {
		return this.http.get<any>(`${this._apiUrl}/${customerId}/GlobalNumber`);
	}

  public getRestrictedGlobalNumbers(customerId: number): Observable<GlobalNumber[]> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/GetRestrictedGlobalNumbers`);
  }

	public getVideoApprovals(customerId: number): Observable<CustomerVerification[]> {
		return this.http.get<any>(`${this._apiUrl}/${customerId}/VideoApprovals`);
	}

	public getFeatureRestrictions(customerId: number): Observable<PhoneNumberRestriction[]> {
		return this.http.get<any>(`${this._apiUrl}/${customerId}/FeatureRestrictions`);
	}

	public flagCustomer(args: FlagCustomerArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/FlagCustomer`, args);
	}

	public setGlobalNumberDescription(args: SetGlobalNumberDescriptionArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/SetGlobalNumberDescription`, args);
	}

	public setGlobalNumberNotes(args: SetGlobalNumberNotesArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/SetGlobalNumberNotes`, args);
	}

  public unflagCustomer(args: UnflagGlobalNumberArgs): Observable<any> {
		//return this.http.get<any>(`${this._apiUrl}/${customerId}/UnflagCustomer`);
		return this.http.post<any>(`${this._apiUrl}/UnflagCustomer`, args);
  }

  public isPhoneNumberGloballyRestricted(phoneNumber: string): Observable<boolean> {
    return this.http.get<any>(`${this._apiUrl}/IsPhoneNumberGloballyRestricted?phoneNumber=${phoneNumber}`);
  }

  public enforcePhoneCalls(customerId: number): Observable<boolean> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/EnforcePhoneCalls`);
  }

  public resetCustomerPassword(customerId: number): Observable<boolean> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/ResetCustomerPassword`);
  }

  public getCustomerSelfiePhoto(customerId: number): Observable<CustomerPhoto> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/SelfiePhoto`);
  }

  public getCustomerDriverLicensePhoto(customerId: number): Observable<CustomerPhoto> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/DriverLincese`);
  }

  public send2FA(customerId: number): Observable<any> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/Send2FA`);
  }

  public verifyCode(args: VerifyCodeArgs): Observable<boolean> {
    return this.http.post<any>(`${this._apiUrl}/VerifyCode`, args);
  }

  public detachPhoneNumber(customerId: number): Observable<any> {
    return this.http.get<any>(`${this._apiUrl}/${customerId}/DetachPhoneNumber`);
  }
}

export class CreateCustomerArgs {
	public userName: string;
	public password: string;
	public email: string;
	public secretQuestion: string;
	public secretAnswer: string;
	public firstName: string;
	public lastName: string;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public postalZip: string;
	public phoneNumber: string;
	public accountPin: string;
}

export class CreateCustomerNoteArgs {
	public customerId: number;
	public note: string;
}

export class Customer {
	public parentCustomerId: number;
	public tFACode: string;
	public active: boolean;
	public timeToContact: string;
	public lastActivityDate: Date;
	public entryDate: Date;
	public phoneNumber: string;
	public email: string;
	public customerAccounts: CustomerAccount[];
	public accountPin: string;
	public countryId: number;
	public state: string;
	public city: string;
	public address1: string;
	public address2: string;
	public lastName: string;
	public firstName: string;
	public id: number;
	public zip: string;
  public customerDisplay: string;
  public username: string;
}

export class CustomerApproval {
	public id: number;
	public customerId: number;
	public kioskFacilityId: number;
	public customerDenialReasonId: number;
	public reviewed: Date;
	public approved: Date;
	public approverReferenceId: string;
	public denied: Date;
	public denierReferenceId: string;
	public denialReason: string;
	public inmateId: number;
	public customer: Customer;
	public inmate: Inmate;
}

export class CustomerNote {
	public id: number;
	public customerId: number;
	public note: string;
	public created: Date;
}

export class CustomerVerification {
	public customerId: number;
	public kioskFacilityId: number;
	public facilityName: string;
	public facilityState: string;
	public siteId: string;
	public customerVerificationStatus: CustomerVerificationStatus;
	public customerDenialReason: string;
	public connectedInmate: Inmate;
}

export class AccountType {
	public id: number;
	public description: string;
}

export class ChangeCustomerArgs {
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	//public phoneNumber: string;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public zip: string;
	public accountPin: string;
}

export class ChangeCustomerPhoneArgs {
	public customerId: number;
	public currentPhoneNumber: string;
	public phoneNumber: string;
	public userName: string;
}

export class CustomerAccount {
	public id: number;
	public customerId: number;
	public accountId: number;
	public account: ProdigyAccount;
	public selected: boolean;
}

export class CustomerReportArgs {
	public firstName: string;
	public lastName: string;
	public address1: string;
	public city: string;
	public state: string;
	public zip: string;
	public phoneNumber: string;
	public email: string;
	public accountPin: string;
	public userName: string;
	public startIndex: number;
	public itemsPerPage: number;
	public sortField: string;
	public sortOrder: string;
}

export class CustomerReport {
	public items: CustomerReportItem[];
	public totalRowCount: number;
}

export class CustomerReportItem {
	public id: number;
	public firstName: string;
	public lastName: string;
	public address1: string;
	public city: string;
	public state: string;
	public zip: string;
	public accountPin: string;
	public phoneNumber: string;
	public email: string;
	public userName: string;
	public flaggedForFraud: boolean;
	public flagAccountModalSettings: PsiModalSettings;
	public unflagAccountModalSettings: PsiModalSettings;
	public showDetails: boolean;
	public loadingGlobalNumber: boolean;
	public savingDescription: boolean;
	public savingNotes: boolean;
	public globalNumbers: GlobalNumber[];
}

export class FlagCustomerArgs {
	public customerId: number;
	public description: string;
	public blockRefund: boolean;
}

export class GlobalNumber {
	public description: string;
	public notes: string;
	public entryDate: Date;
  public siteId: string;
  public phone: string;
  public blockRefund: boolean;
}

export class PhoneNumberRestriction {
	public id: number;
	public phoneNumber: string;
	public siteId: string;
	public facilityName: string;
	public externalCustomerId: number;
	public prodigyFeature: number;
	public prodigyFeatureName: string;
	public created: Date;
	public restrictionEnd: Date;
	public currentlyActive: boolean;
	public notes: string;
}

// Backend version is named "Account", but DOM api already has a type named Account
export class ProdigyAccount {
	public id: number;
	public accountTypeId: number;
	public balance: number;
	public reserve: number;
	public active: boolean;
	public referenceId: string;
	public primary: boolean;
	public accountType: AccountType;
}

export class SetGlobalNumberDescriptionArgs {
	public customerId: number;
	public description: string;
	public siteId: string;
}

export class SetGlobalNumberNotesArgs {
	public customerId: number;
	public notes: string;
	public siteId: string;
}

export class VerifyCodeArgs {
  public customerId: number;
  public code: string;
}

export class UnflagGlobalNumberArgs {
  public customerId: number;
  public siteId: string;
}

export enum CustomerVerificationStatus {
	APPROVED = 1,
	DENIED = 2,
	PENDING = 3,
	AWAITING_ONSITE_REVIEW = 4,
	PERMANENTLY_DENIED = 5
}

export enum CustomerPhotoType {
  SELFIE = 0,
  DRIVERS_LICENSE = 1
}

export class CustomerPhoto {
  public id: number;
  public customerId: number;
  public photoType: CustomerPhotoType;
  public imageBytes: Uint8Array;
  public imageBase64String: string;
  public mimeType: string;
  public reviewed: boolean;
  public approved: boolean;
  public data: string;
}
export type GlobalNumberData = {
  description: string;
  notes: string;
};

export class DetachPhoneNumberReturnData {
    errorMsgs: string[]
};
