import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class InmatesService {
	private readonly _apiUrl = `${environment.apiUrl}/Inmates`;

	constructor(private http: HttpClient) { }


	public getInmateAccountBalance(pin: string, siteId: string): Observable<number> {
		return this.http.get<any>(`${this._apiUrl}/GetAccountBalance?pin=${pin}&siteId=${siteId}`);
	}

	public searchInmates(args: SearchInmatesArgs): Observable<Inmate[]> {
		return this.http.post<any>(`${this._apiUrl}/Search`, args);
	}

	public searchInmateAccounts(args: SearchInmatesArgs): Observable<InmateAccount[]> {
		return this.http.post<any>(`${this._apiUrl}/SearchAccounts`, args);
	}
}

export class Inmate {
	public id: number;
	public lastName: string;
	public firstName: string;
	public middleName: string;
	public created: Date;
	public dateOfBirth: Date;
	public locationId: number;
	public kioskFacilityId: number;
	public isIncarcerated: boolean;
	public inmateIdentifierAtFacility: string;
	public fullName: string;
	public display: string;
	public dateOfBirthDisplay: string;
}

export class InmateAccount {
	public id: number;
	public inmateId: number;
	public accountId: number;
	public lastName: string;
	public firstName: string;
	public middleName: string;
	public inmateCreated: Date;
	public dateOfBirth: Date;
	public locationId: number;
	public kioskFacilityId: number;
	public incarcerated: boolean;
	public inmateIdentifierAtFacility: string;
	public balance: number;
	public reserve: number;
	public accountActive: boolean;
	public pin: string;
	public siteId: string;
	public accountAdded: Date;
	public accountModified: Date;
	public primary: boolean;
	public fullName: string;
	public display: string;
	public dateOfBirthDisplay: string;
}

export class SearchInmatesArgs {
	public activeOnly: boolean;
	public search: string;
	public siteIdList: string[];
	public returnCount: number;
}