import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrdersReportComponent } from './orders-report/orders-report.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginComponent } from './login/login.component';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhoneNumberPipe } from './shared/pipes/phone-number.pipe';
import { CreateCustomerComponent } from './customers/create-customer/create-customer.component';
import { SearchCustomersComponent } from './customers/search-customers/search-customers.component';
import { EditCustomerComponent } from './customers/edit-customer/edit-customer.component';
import { BoolYesNoPipe } from './shared/pipes/bool-yes-no.pipe';
import { PsiModalComponent } from './shared/components/psi-modal/psi-modal.component';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaymentProcessingComponent } from './payments/payment-processing/payment-processing.component';
import { InmatesTypeaheadComponent } from './shared/components/inmates-typeahead/inmates-typeahead.component';
import { SearchUsersComponent } from './users/search-users/search-users.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { ProductRatesComponent } from './product-rates/product-rates.component';
import { CustomerPhoneComponent } from './shared/components/customer-phone/customer-phone.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { SendFundsComponent } from './payments/send-funds/send-funds.component';
import { InmateAccountsTypeaheadComponent } from './shared/components/inmate-accounts-typeahead/inmate-accounts-typeahead.component';
import { GlobalNumbersEditModalComponent } from './global-numbers-edit-modal/global-numbers-edit-modal.component';

@NgModule({
	declarations: [
		AppComponent,
		OrdersReportComponent,
		LoginComponent,
		HomeComponent,
		PhoneNumberPipe,
		CreateCustomerComponent,
		SearchCustomersComponent,
		EditCustomerComponent,
		BoolYesNoPipe,
		PsiModalComponent,
		UserActionsComponent,
		TruncatePipe,
		PaymentProcessingComponent,
		InmatesTypeaheadComponent,
		SearchUsersComponent,
		CreateUserComponent,
		EditUserComponent,
		ProductRatesComponent,
		CustomerPhoneComponent,
		ChangePasswordComponent,
		SendFundsComponent,
    InmateAccountsTypeaheadComponent,
    GlobalNumbersEditModalComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SnotifyModule,
		NgSelectModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		NgxSpinnerModule
	],
	providers: [
		{ provide: 'SnotifyToastConfig', useValue: ToastDefaults },
		SnotifyService,
		authInterceptorProviders,
		PhoneNumberPipe
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule { }
