import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { CacheService } from '../services/cache.service';
import { GetOrdersReportArgs, OrderReport, OrderReportItem, OrderReportSummary, OrdersService } from '../services/orders.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { HelperService, UniqueIdType } from '../helpers/helper.service';
import { FacilityOption, SelectOption, SharedService } from '../services/shared.service';
import { PsiModalSettings } from '../shared/components/psi-modal/psi-modal.component';
import { PaymentsService, ProcessRefundArgs } from '../services/payments.service';
import { TokenStorageService } from '../services/token-storage.service';
import { CustomersService, GlobalNumber } from '../services/customers.service';
import { saveAs } from 'file-saver';

@Component({
	selector: 'orders-report',
	templateUrl: './orders-report.component.html'
})
export class OrdersReportComponent implements OnInit {
	public page: number = 1;
	public pageSize: number = 10;
	public collectionSize: number = 0;
	public sortField: string = 'DepositedOn';
	public sortDesc: boolean = true;

	private defStart = moment().subtract(1, 'months');
	private defEnd = moment();
	// private defStart = moment().subtract(1, 'months').subtract(1, 'years');
	// private defEnd = moment().subtract(1, 'years');

	private def: any = {
		filters: {
			siteIdList: [],
			startDate: { day: this.defStart.date(), month: this.defStart.month() + 1, year: this.defStart.year() },
			endDate: { day: this.defEnd.date(), month: this.defEnd.month() + 1, year: this.defEnd.year() },
			depositTypeIdList: [],
			inmateName: null,
			callerFirstName: null,
			callerLastName: null,
			callerEmail: null,
			callerPhone: null,
			userName: null,
			paymentTypeIdList: [],
			outletIdList: []
		},
		filterOptions: {
			depositTypes: [],
			facilities: [],
			outlets: []
		},
		refundArgs: {
			amount: null,
      transactionId: null,
      phoneNumber: null
		}
	};

	public refundPaymentModalSettings: PsiModalSettings = {
		openModalButtonText: 'Refund order payment',
		title: 'Refund order payment',
		spanClass: 'fas fa-undo-alt clickable',
		confirmBtnText: 'Refund',
		closeBtnText: 'Cancel',
		openerType: 'icon',
		uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
  };

  public flagPaymentModalSettings: PsiModalSettings = {
		openModalButtonText: 'Flag order payment',
		title: 'Flag order payment',
		spanClass: 'fas fa-flag red clickable',
		confirmBtnText: 'Yes',
		closeBtnText: 'Cancel',
		openerType: 'icon',
		uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
  };

  public unflagPaymentModalSettings: PsiModalSettings = {
		openModalButtonText: 'Unflag order payment',
		title: 'Unflag order payment',
		spanClass: 'fas fa-flag clickable',
		confirmBtnText: 'Yes',
		closeBtnText: 'Cancel',
    openerType: 'icon',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
	};

	public filters: any = _.cloneDeep(this.def.filters);
	public filterOptions: any = _.cloneDeep(this.def.filterOptions);
	public refundArgs: ProcessRefundArgs = _.cloneDeep(this.def.refundArgs);
	public reportArgs: GetOrdersReportArgs;

	public reportItems: OrderReportItem[] = [];
	public summary: OrderReportSummary = null;

	public initLoad: boolean = true;

	public depositTypesLoaded: boolean = false;
	public facilitiesLoaded: boolean = false;
	public outletsLoaded: boolean = false;
	public loadingDepositTypes: boolean = false;
	public loadingFacilities: boolean = false;
	public loadingOutlets: boolean = false;
	public loadingReport: boolean = false;
	public reportLoaded: boolean = false;


	constructor(
		private _ordersService: OrdersService,
		private _paymentsService: PaymentsService,
    private _sharedService: SharedService,
    private _customersService: CustomersService,
		private _snotify: SnotifyService,
		private _tokenStorage: TokenStorageService
	) { }

	ngOnInit(): void {
		//this.loadReport();
		this.loadFacilities();
		this.loadOutlets();
		this.loadDepositTypes();
	}


	public busy(): boolean {
		return (this.loadingReport || this.loadingFacilities || this.loadingOutlets || this.loadingDepositTypes);
	}

	public confirmRefundBtnClick(item: OrderReportItem): void {
		this.refundPayment(item);
	}

	public cancelRefundBtnClick(): void {
		console.log('Cancel refund button clicked');
	}

	public hasPermission(permission: string): boolean {
		return this._tokenStorage.hasPermission(permission);
	}

	public loadDepositTypes(): void {
		if (this.loadingDepositTypes) {
			return;
		}

		this.loadingDepositTypes = true;

		if (this.depositTypesLoaded) {
			this.depositTypesLoaded = false;
		}

		this._sharedService.getDepositTypes()
			.subscribe((result: SelectOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.depositTypes = [...result];

				this.depositTypesLoaded = true;
				this.loadingDepositTypes = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all deposit types', err);
				this.depositTypesLoaded = false;
				this.loadingDepositTypes = false;
				this._snotify.error('Failed to retrieve list of all deposit types', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public loadFacilities(): void {
		if (this.loadingFacilities) {
			return;
		}

		this.loadingFacilities = true;

		if (this.facilitiesLoaded) {
			this.facilitiesLoaded = false;
		}

		this._sharedService.getFacilities()
			.subscribe((result: FacilityOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.facilities = [...result];

				this.facilitiesLoaded = true;
				this.loadingFacilities = false;

				// This is called here ot make sure that facilities are loaded first on page load
				if (this.initLoad) {
					// Here we can load anything that relies on facilities being loaded first.
					this.initLoad = false;
				}
			}, (err: any): void => {
				console.error('Failed to retrieve list of all facilities', err);
				this.facilitiesLoaded = false;
				this.loadingFacilities = false;
				this._snotify.error('Failed to retrieve list of all facilities', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public loadOutlets(): void {
		if (this.loadingOutlets) {
			return;
		}

		this.loadingOutlets = true;

		if (this.outletsLoaded) {
			this.outletsLoaded = false;
		}

		this._sharedService.getOutlets()
			.subscribe((result: SelectOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.outlets = [...result];

				this.outletsLoaded = true;
				this.loadingOutlets = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all outlets', err);
				this.outletsLoaded = false;
				this.loadingOutlets = false;
				this._snotify.error('Failed to retrieve list of all outlets', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public loadReport(): void {
		if (this.loadingReport) {
			return;
		}

		this.loadingReport = true;

		this.reportItems = [];

		this.updateReportArgs();

		this._ordersService.getOrdersReport(this.reportArgs).subscribe(
			(data: OrderReport) => {

				this.collectionSize = data.summary.numberOfTransactions;
				this.reportItems = data.reportItems;
				this.summary = data.summary;

				for (let i = 0; i < this.reportItems.length; i++) {
					this.reportItems[i].refundPaymentModalSettings = _.cloneDeep(this.refundPaymentModalSettings);
          this.reportItems[i].refundPaymentModalSettings.uniqueId = HelperService.getUniqueId(UniqueIdType.MODAL);

          this.reportItems[i].flagPaymentModalSettings = _.cloneDeep(this.flagPaymentModalSettings);
          this.reportItems[i].flagPaymentModalSettings.uniqueId = HelperService.getUniqueId(UniqueIdType.MODAL);

          this.reportItems[i].unflagPaymentModalSettings = _.cloneDeep(this.unflagPaymentModalSettings);
					this.reportItems[i].unflagPaymentModalSettings.uniqueId = HelperService.getUniqueId(UniqueIdType.MODAL);

					// Check if any of this order has been refunded
					if (this.reportItems[i].refundState && this.reportItems[i].refundState === 1) {
						// Refund State of 1 is 'Partial' so some of the money for this order has been refunded.
						// Set icon color to orange and leave it as clickable
						this.reportItems[i].refundPaymentModalSettings.spanClass = 'fas fa-undo-alt color-orange clickable';

						const titleText = 'Refund order payment (partially refunded)';
						this.reportItems[i].refundPaymentModalSettings.openModalButtonText = titleText;
						this.reportItems[i].refundPaymentModalSettings.title = titleText;
					}
				}

				CacheService.setItem(CacheService.keys.ORDERS_REPORT_FILTERS, this.filters);

				this.reportLoaded = true;
				this.loadingReport = false;
			},
			(err: any) => {
				console.error('Error getting orders report', err);
				this.loadingReport = false;

				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load the orders report.', 'Load Failed');
						break;
				}
			}
		);
  }

  public loadExcelReport(): void {
    if (this.loadingReport) {
      return;
    }

    this.loadingReport = true;

    //this.reportItems = [];

    this.updateReportArgs();

    this.reportArgs.page = null;
    this.reportArgs.recordCount = null;

    this._ordersService.getOrdersExcelReport(this.reportArgs).subscribe(
      (data: Blob) => {
        const contentType = data['contentType'];
        const fileContents = data['fileContents'];
        const fileName = data['fileDownloadName'];

        const binaryString = window.atob(fileContents);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: contentType });
        saveAs(blob, fileName);
        this.reportLoaded = true;
        this.loadingReport = false;
      },
      (err: any) => {
        console.error('Error getting orders report', err);
        this.loadingReport = false;

        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to load the orders report.', 'Load Failed');
            break;
        }
      }
    );
  }

	public toggleDetails(item: OrderReportItem): void {
		item.showDetails = !item.showDetails;

		if (item.showDetails && !item.refundedAmount) {
			this.getRefundAmount(item);
		}
	}

	public getRefundAmount(item: OrderReportItem): void {
		if (!item.refundState || item.refundState === 0) {
			item.refundedAmount = 0;
		} else {
			item.loadingRefundedAmount = true;
			this._ordersService.getAmountRefunded(item.creditCardProcessorTransactionId)
				.subscribe((result: number): void => {
					item.refundedAmount = result;
					item.loadingRefundedAmount = false;
				}, (err: any): void => {
					console.error('Error loading amount refunded', err);
					item.loadingRefundedAmount = false;

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred attempting to load the amount that has been refunded for the order.', 'Load Failed');
							break;
					}
				});
		}
	}

  public async flagPayment(item: OrderReportItem) {
    if (item.flagging) {
      return;
    }

    if (item.isFlagged) {
      this._snotify.warning('This payment has already been flagged', 'Warning');
      return;
    }

    try {
      item.flagging = true;
      const transactionId: string = item.prodigyTransactionId;
      let success = await this._ordersService.flagPayment(transactionId).toPromise();
      if (success) {
        item.isFlagged = true;
        this._snotify.success('Flagged payment successfully!', 'Success!')
      } else {
        this._snotify.error('Failed to flag payment.', 'Error');
      }
      item.flagging = false;
    } catch (err) {
      console.error(err);
      this._snotify.error('Failed to flag payment.', 'Error');
      item.flagging = false;
    }
  }

  public async unflagPayment(item: OrderReportItem) {
    if (item.unflagging) {
      return;
    }

    if (!item.isFlagged) {
      this._snotify.warning('This payment has already been unflagged', 'Warning');
      return;
    }

    try {
      item.unflagging = true;
      const transactionId: string = item.prodigyTransactionId;
      let success = await this._ordersService.unflagPayment(transactionId).toPromise();
      if (success) {
        item.isFlagged = false;
        this._snotify.success('Unflagged payment successfully!', 'Success!')
      } else {
        this._snotify.error('Failed to unflag payment.', 'Error');
      }
      item.unflagging = false;
    } catch (err) {
      console.error(err);
      this._snotify.error('Failed to unflag payment.', 'Error');
      item.unflagging = false;
    }
  }

	public async refundPayment(item: OrderReportItem) {
		if (item.refundingPayment) {
			return;
		}

    item.refundingPayment = true;

    if (this.validateRefundArgs(item)) {
      if (item.customerId || item.customerId > 0) {
        const globalNumbers: GlobalNumber[] = await this._customersService.getRestrictedGlobalNumbers(item.customerId).toPromise();
        const nonRefundableRestrictions: GlobalNumber = globalNumbers.find(x => x.blockRefund === true);
        const isNonRefundable: boolean = (nonRefundableRestrictions) ? true : false;

        if (isNonRefundable) {
          item.refundingPayment = false;
          this._snotify.warning('The customer is not allowed to receive refunds.', 'Cannot Refund');
          return;
        }
      }
      
			this.refundArgs.transactionId = item.creditCardProcessorTransactionId;
			this.refundArgs.depositTypeId = item.depositTypeId;
      this.refundArgs.customerId = item.customerId;
      this.refundArgs.phoneNumber = item.customerPhoneNumber;

			this._paymentsService.processRefund(this.refundArgs)
				.subscribe(() => {
					this._snotify.success('Refund processed successfully!', 'Refund Success');
					item.refundingPayment = false;

					this.refundArgs = _.cloneDeep(this.def.refundArgs);

					this.loadReport();
				}, (err: any) => {
					console.error('Error processing refund', err);

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred attempting to process the refund.', 'Refund Failed');
							break;
					}

					item.refundingPayment = false;
					this.refundArgs = _.cloneDeep(this.def.refundArgs);
				});
		} else {
			item.refundingPayment = false;
		}
	}

	public reset(): void {
		this.filters = _.cloneDeep(this.def.filters);
	}

	public sortTable($event: any, col: string): void {
		if (!this.loadingReport) {
			const target = $event.target || $event.srcElement || $event.currentTarget;
			for (let i = 0; i < target.parentElement.children.length; i++) {
				target.parentElement.children[i].classList.remove('headerSortUp');
				target.parentElement.children[i].classList.remove('headerSortDown');
			}

			this.sortDesc = !this.sortDesc;
			if (this.sortDesc) {
				target.classList.remove('headerSortUp');
				target.classList.add('headerSortDown');
			} else {
				target.classList.remove('headerSortDown');
				target.classList.add('headerSortUp');
			}
			this.sortField = col;

			this.loadReport();
		}
	}

	public updateReportArgs(): void {
    this.reportArgs = { ...this.filters };
    this.reportArgs.callerPhone = HelperService.stripNonNumeric(this.reportArgs.callerPhone);
		// Null is treated the same as all selected by stored proc (without having to convert lists to table variables in sql)
		// So, set the values to null for performance
		if (this.reportArgs.siteIdList.length === this.filterOptions.facilities.length) {
			this.reportArgs.siteIdList = null;
		}

		if (this.reportArgs.outletIdList.length === this.filterOptions.outlets.length) {
			this.reportArgs.outletIdList = null;
		}

		if (this.reportArgs.depositTypeIdList.length === this.filterOptions.depositTypes.length) {
			this.reportArgs.depositTypeIdList = null;
		}

		this.reportArgs.startDate = HelperService.getMomentDtNoTimeZone(new Date(this.filters.startDate.year, this.filters.startDate.month - 1, this.filters.startDate.day, 0, 0, 0));
		this.reportArgs.endDate = HelperService.getMomentDtNoTimeZone(new Date(this.filters.endDate.year, this.filters.endDate.month - 1, this.filters.endDate.day, 23, 59, 59));

		this.reportArgs.page = this.page;
		this.reportArgs.recordCount = this.pageSize;
		this.reportArgs.sortField = this.sortField;
		this.reportArgs.sortOrder = this.sortDesc ? 'DESC' : 'ASC';
	}

	private validateRefundArgs(item: OrderReportItem): boolean {
		let returnValue: boolean = true;

		if (this.refundArgs.amount > (item.amount - item.refundedAmount)) {
			this._snotify.warning('Refund amount cannot exceed the remaining transaction amount!');
			returnValue = false;
		}

		return returnValue;
	}
}
