import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PsiModalSettings } from '../shared/components/psi-modal/psi-modal.component';

@Injectable({
	providedIn: 'root'
})
export class OrdersService {
	private readonly _apiUrl = `${environment.apiUrl}/Orders`;

	constructor(private http: HttpClient) { }


	public getAmountRefunded(creditCardProcessorTransactionId: string): Observable<number> {
		return this.http.get<any>(`${this._apiUrl}/GetAmountRefunded?creditCardProcessorTransactionId=${creditCardProcessorTransactionId}`);
	}

	public getOrdersReport(args: GetOrdersReportArgs): Observable<OrderReport> {
		return this.http.post<any>(`${this._apiUrl}/Report`, args);
  }

  public getOrdersExcelReport(args: GetOrdersReportArgs): Observable<Blob> {
    return this.http.post<any>(`${this._apiUrl}/ExcelReport`, args);
  }

  public flagPayment(transactionGuid: string): Observable<boolean> {
    return this.http.get<any>(`${this._apiUrl}/FlagPayment?transactionGuid=${transactionGuid}`);
  }

  public unflagPayment(transactionGuid: string): Observable<boolean> {
    return this.http.get<any>(`${this._apiUrl}/UnflagPayment?transactionGuid=${transactionGuid}`);
  }

}

export class GetOrdersReportArgs {
	public siteIdList: string[];
	public startDate: string; // .NET should parse this to a DateTime automatically
	public endDate: string; // .NET should parse this to a DateTime automatically
	public depositTypeIdList: number[];
	public inmateName: string;
	public callerName: string;
	public callerEmail: string;
	public callerPhone: string;
	public userName: string;
	public paymentTypeIdList: number[];
	public outletIdList: number[];
	public transactionId: string;
	public page: number | null;
	public recordCount: number | null;
	public sortField: string;
	public sortOrder: string;
}

export class OrderReport {
	public reportItems: OrderReportItem[];
	public summary: OrderReportSummary;
}

export class OrderReportItem {
	public transactionHeaderId: number;
	public customerId: number | null;
	public customerFirstName: string | null;
	public customerLastName: string | null;
	public customerPhoneNumber: string | null;
	public customerEmail: string | null;
	public depositType: string;
	public depositTypeId: number;
	public amount: number | null;
	public billCount: number | null;
	public creditCardProcessorTransactionId: string;
	public customerPhoto: number[]; // Byte array
	public depositId: number | null;
	public depositedOn: Date | null;
	public facilityId: number | null;
	public facilityName: string | null;
	public siteId: string | null;
	public handlingFee: number | null;
	public purchaseMethod: string;
	public purchasedFromId: number | null;
	public refundState: number | null;
	public surcharge: number | null;
	public terminateCode: number | null;
	public terminateText: string;
	public prodigyTransactionId: string;
	public inmateName: string;
	public userId: string;
	public userName: string;
	public outlet: string;
	public creditCardFee: number | null;
	public accountPhone: string;
	public showDetails: boolean;
	public refundedAmount: number;
  public isFlagged: boolean;

  public loadingRefundedAmount: boolean;
  public refundingPayment: boolean;
  public flagging: boolean; //state
  public unflagging: boolean; //state
	public refundPaymentModalSettings: PsiModalSettings;
  public flagPaymentModalSettings: PsiModalSettings;
  public unflagPaymentModalSettings: PsiModalSettings;
}

export class OrderReportSummary {
	public cashDeposits: number;
	public cashHandlingFees: number;
	public cashCreditCardFees: number;
	public cashSurchargeFees: number;
	public cashTotalFees: number;
	public cashTotal: number;
	public creditCardDeposits: number;
	public creditCardHandlingFees: number;
	public creditCardCreditCardFees: number;
	public creditCardSurchargeFees: number;
	public creditCardTotalFees: number;
	public creditCardTotal: number;
	public interfaceCardDeposits: number;
	public interfaceHandlingFees: number;
	public interfaceCreditCardFees: number;
	public interfaceSurchargeFees: number;
	public interfaceTotalFees: number;
	public interfaceTotal: number;
	public billCount: number;
	public numberOfTransactions: number;
}
