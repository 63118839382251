<div class="page-container">
    <h2 class="page-title">Send Funds</h2>

    <div *ngIf="loadingPageData()" class="text-center">
        <div class="spinner-border text-info psi-spinner-lg" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="container large-container col-12" *ngIf="!loadingPageData()">
        <!-- <div class="text-center" style="padding-top: 20px;">
            <button type="button" class="text-center btn btn-primary" (click)="swapSrcAndDest()" title="Swap Source and Destination" [disabled]="busy()"><span class="fas fa-3x fa-exchange-alt"></span></button>
        </div> -->
        <div class="col-6" style="display:inline-block">
            <div class="filters-backdrop col-12" [ngClass]="{'box-shadow-red': isSourceAccountRestricted}">
                <div *ngIf="sendingFunds || loadingSrcData" class="text-center">
                    <div class="spinner-border text-info psi-spinner-lg" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <form *ngIf="!sendingFunds && !loadingSrcData" class="filters-container form-inline align-items-flex-start">
                    <div class="col-sm-12">
                        <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isSourceAccountRestricted}">
                            <legend class="col-sm-9 col-md-6 col-lg-3">Source</legend>
                            <div class="form-row col-sm-12">
                                <div class="col-sm-8 col-md-6 filter-field-container">
                                    <label for="srcUserType" class="inline-block">User Type</label>
                                    <ng-select [items]="filterOptions.srcUserTypes" bindLabel="name" bindValue="id" name="srcUserType" [(ngModel)]="source.userType" (change)="resetErrors('srcUserType')" class="form-control full-width col-sm-12 no-padding" ngDefaultControl></ng-select>
                                    <div *ngIf="errors.srcUserType && errors.srcUserType.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.srcUserType; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row col-12" *ngIf="source.userType === 1">
                                <!-- Select Facility -->
                                <div class="col-12">
                                    Please select a Facility
                                </div>
                                <br />
                                <div class="form-row col-12">
                                    <div class="col-12 filter-field-container">
                                        <label for="sourceFacilityState" class="col-form-label col-lg-4 inline-block">State</label>
                                        <ng-select [items]="filterOptions.facilityStates" bindLabel="name" bindValue="abbreviation" (change)="loadFacilities('src')" name="sourceFacilityState" [(ngModel)]="source.state" class="col-lg-8 no-padding inline-block" [disabled]="loadingFacilityStates || loadingFacilities" [loading]="loadingFacilityStates || loadingFacilities" ngDefaultControl></ng-select>
                                    </div>
                                    <div class="col-12 filter-field-container" *ngIf="source.state">
                                        <label for="srcFacility" class="col-form-label col-lg-4 inline-block">Facility</label>
                                        <ng-select [items]="filterOptions.srcFacilities" bindLabel="name" bindValue="siteId" name="srcFacility" [(ngModel)]="source.siteId" (change)="setInmateSiteId('src')" class="col-lg-8 no-padding inline-block" [disabled]="loadingFacilityStates || loadingFacilities" [loading]="loadingFacilityStates || loadingFacilities" ngDefaultControl></ng-select>
                                    </div>
                                </div>
                                <!-- Select inmate Account -->
                                <div class="col-12" *ngIf="source.siteId">
                                    Please select an inmate account
                                </div>
                                <br *ngIf="source.siteId" />
                                <div class="col-12 inline-block" *ngIf="source.siteId">
                                    <div class="col-12 filter-field-container inline-block">
                                        <label for="filterInmateAccounts" class="col-form-label col-12 col-lg-4 inline-block">Inmate Account</label>
                                        <inmate-accounts-typeahead [(selectedInmateAccounts)]="srcInmateAccountTypeaheadFilters.selectedInmateAccount" (selectedInmateAccountsChange)="getBalance('src')" [activeOnly]="true" [allowMultiple]="false" [siteIdList]="srcInmateAccountTypeaheadFilters.siteIdList"></inmate-accounts-typeahead>
                                    </div>
                                    <div class="col-12 filter-field-container inline-block" *ngIf="srcInmateAccountTypeaheadFilters.selectedInmateAccount && srcInmateAccountTypeaheadFilters.selectedInmateAccount.fullName" style="padding-left: 15px;">
                                        <span class="color-brand-blue bold">Selected Inmate Account Information:</span>
                                        <br />
                                        <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ srcInmateAccountTypeaheadFilters.selectedInmateAccount.fullName }}
                                        <br />
                                        <span class="bold">DOB:</span>&nbsp;&nbsp;&nbsp;{{ srcInmateAccountTypeaheadFilters.selectedInmateAccount.dateOfBirthDisplay | phoneNumber }}
                                        <br />
                                        <span class="bold">Balance:</span>&nbsp;&nbsp;&nbsp;{{ srcInmateAccountTypeaheadFilters.selectedInmateAccount.balance | currency }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-row col-12" *ngIf="source.userType === 2">
                                <div class="col-12 filter-field-container">
                                    <label for="filterSrcPhoneNumber" class="inline-block">Please enter the source account phone number:</label>
                                    <input [(ngModel)]="source.phoneNumber" (ngModelChange)="searchCustomer('src')" [disabled]="loadingSrcData" name="filterSrcPhoneNumber" id="filterSrcPhoneNumber" type="text" class="form-control full-width col-12" />
                                </div>
                                <br />
                                <div class="col-12 filter-field-container" style="padding-left: 15px;">
                                    <div *ngIf="loadingSrcData && !source.customerInfo" class="text-center">
                                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!loadingSrcData && source.customerInfo">
                                        <span class="color-brand-blue bold">Source Customer Information:</span>
                                        <br />
                                        <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ source.customerInfo.firstName }} {{ source.customerInfo.lastName }}
                                        <br />
                                        <span class="bold">Phone Number:</span>&nbsp;&nbsp;&nbsp;{{ source.customerInfo.phoneNumber | phoneNumber }}
                                        <br />
                                        <span class="bold">Email:</span>&nbsp;&nbsp;&nbsp;{{ source.customerInfo.email }}
                                        <br />
                                        <span class="bold"><span class="fas fa-question-circle" title="Used to verify a customer's account"></span> Account PIN:</span>&nbsp;&nbsp;&nbsp;{{ source.customerInfo.accountPin }}
                                    </div>
                                </div>
                                <br />
                                <div class="col-12 filter-field-container" *ngIf="source.customerInfo && source.customerInfo.customerAccounts" style="padding-left: 15px;">
                                    <span class="color-brand-blue bold">Select a source account:</span>
                                    <br />
                                    <table class="table table-dark table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">Balance</th>
                                                <th scope="col">Reserve</th>
                                                <th scope="col">Active</th>
                                                <th scope="col">Primary</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="source.customerInfo && source.customerInfo.customerAccounts && source.customerInfo.customerAccounts.length > 0">
                                            <ng-container *ngFor="let customerAccount of source.customerInfo.customerAccounts; let i = index">
                                                <tr class="clickable" [ngClass]="{'row-selected': customerAccount.selected}" (click)="selectCustomerAccount('src', customerAccount.id)">
                                                    <td scope="row">{{ customerAccount.account.accountType.description }}</td>
                                                    <td scope="row">{{ customerAccount.account.balance | currency }}</td>
                                                    <td scope="row">{{ customerAccount.account.reserve | currency }}</td>
                                                    <td scope="row" [ngClass]="{'color-green': customerAccount.account.active, 'color-red': !customerAccount.account.active}">{{ customerAccount.account.active | boolYesNo }}</td>
                                                    <td scope="row" [ngClass]="{'color-green': customerAccount.account.primary, 'color-red': !customerAccount.account.primary}">{{ customerAccount.account.primary | boolYesNo }}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </fieldset>
                        <button (click)="resetSource()" type="button" class="btn btn-primary report-button margins"><span class="fas fa-trash"></span> Clear Source</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-6 filters-backdrop customer-accounts-container" [ngClass]="{'box-shadow-red': isDestinationAccountRestricted}">
            <div *ngIf="sendingFunds || loadingDestData" class="text-center">
                <div class="spinner-border text-info psi-spinner-lg" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <form *ngIf="!sendingFunds && !loadingDestData" class="filters-container form-inline align-items-flex-start">
                <div class="col-sm-12">
                    <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isDestinationAccountRestricted}">
                        <legend class="col-sm-9 col-md-6 col-lg-3">Destination</legend>
                        <div class="form-row col-sm-12">
                            <div class="col-sm-6 filter-field-container">
                                <label for="destUserType" class="inline-block">User Type</label>
                                <ng-select [items]="filterOptions.destUserTypes" bindLabel="name" bindValue="id" name="destUserType" [(ngModel)]="destination.userType" (change)="resetErrors('destUserType')" class="form-control full-width col-sm-12 no-padding" ngDefaultControl></ng-select>
                                <div *ngIf="errors.destUserType && errors.destUserType.length > 0" class="psi-alert psi-alert-danger">
                                    <div *ngFor="let err of errors.destUserType; let i = index">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row col-12" *ngIf="destination.userType === 1">
                            <!-- Select Facility -->
                            <div class="col-12">
                                Please select a Facility
                            </div>
                            <br />
                            <div class="form-row col-12">
                                <div class="col-12 filter-field-container">
                                    <label for="destinationFacilityState" class="col-form-label col-lg-4 inline-block">State</label>
                                    <ng-select [items]="filterOptions.facilityStates" bindLabel="name" bindValue="abbreviation" (change)="loadFacilities('dest')" name="destinationFacilityState" [(ngModel)]="destination.state" class="col-lg-8 no-padding inline-block" [disabled]="loadingFacilityStates || loadingFacilities" [loading]="loadingFacilityStates || loadingFacilities" ngDefaultControl></ng-select>
                                </div>
                                <div class="col-12 filter-field-container" *ngIf="destination.state">
                                    <label for="destFacility" class="col-form-label col-lg-4 inline-block">Facility</label>
                                    <ng-select [items]="filterOptions.destFacilities" bindLabel="name" bindValue="siteId" name="destFacility" [(ngModel)]="destination.siteId" (change)="setInmateSiteId('dest')" class="col-lg-8 no-padding inline-block" [disabled]="loadingFacilityStates || loadingFacilities" [loading]="loadingFacilityStates || loadingFacilities" ngDefaultControl></ng-select>
                                </div>
                            </div>
                            <!-- Select inmate Account -->
                            <div class="col-12" *ngIf="source.siteId">
                                Please select an inmate account
                            </div>
                            <br *ngIf="destination.siteId" />
                            <div class="col-12 inline-block" *ngIf="destination.siteId">
                                <div class="col-12 filter-field-container inline-block">
                                    <label for="filterInmateAccounts" class="col-form-label col-12 col-lg-4 inline-block">Inmate Account</label>
                                    <inmate-accounts-typeahead [(selectedInmateAccounts)]="destInmateAccountTypeaheadFilters.selectedInmateAccount" (selectedInmateAccountsChange)="getBalance('dest')" [activeOnly]="true" [allowMultiple]="false" [siteIdList]="destInmateAccountTypeaheadFilters.siteIdList"></inmate-accounts-typeahead>
                                </div>
                                <div class="col-12 filter-field-container inline-block" *ngIf="destInmateAccountTypeaheadFilters.selectedInmateAccount && destInmateAccountTypeaheadFilters.selectedInmateAccount.fullName" style="padding-left: 15px;">
                                    <span class="color-brand-blue bold">Selected Inmate Account Information:</span>
                                    <br />
                                    <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ destInmateAccountTypeaheadFilters.selectedInmateAccount.fullName }}
                                    <br />
                                    <span class="bold">DOB:</span>&nbsp;&nbsp;&nbsp;{{ destInmateAccountTypeaheadFilters.selectedInmateAccount.dateOfBirthDisplay | phoneNumber }}
                                    <br />
                                    <span class="bold">Balance:</span>&nbsp;&nbsp;&nbsp;{{ destInmateAccountTypeaheadFilters.selectedInmateAccount.balance | currency }}
                                </div>
                            </div>
                        </div>
                        <div class="form-row col-12" *ngIf="destination.userType === 2">
                            <div class="col-12 filter-field-container">
                                <label for="filterDestPhoneNumber" class="inline-block">Please enter the destination account phone number:</label>
                                <input [(ngModel)]="destination.phoneNumber" (ngModelChange)="searchCustomer('dest')" [disabled]="loadingDestData" name="filterDestPhoneNumber" id="filterDestPhoneNumber" type="text" class="form-control full-width col-12" />
                            </div>
                            <br />
                            <div class="col-12 filter-field-container" style="padding-left: 15px;">
                                <div *ngIf="loadingDestData && !destination.customerInfo" class="text-center">
                                    <div class="spinner-border text-info psi-spinner-lg" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div *ngIf="!loadingDestData && destination.customerInfo">
                                    <span class="color-brand-blue bold">Destination Customer Information:</span>
                                    <br />
                                    <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ destination.customerInfo.firstName }} {{ destination.customerInfo.lastName }}
                                    <br />
                                    <span class="bold">Phone Number:</span>&nbsp;&nbsp;&nbsp;{{ destination.customerInfo.phoneNumber | phoneNumber }}
                                    <br />
                                    <span class="bold">Email:</span>&nbsp;&nbsp;&nbsp;{{ destination.customerInfo.email }}
                                    <br />
                                    <span class="bold"><span class="fas fa-question-circle" title="Used to verify a customer's account"></span> Account PIN:</span>&nbsp;&nbsp;&nbsp;{{ destination.customerInfo.accountPin }}
                                </div>
                            </div>
                            <br />
                            <div class="col-12 filter-field-container" *ngIf="destination.customerInfo && destination.customerInfo.customerAccounts" style="padding-left: 15px;">
                                <span class="color-brand-blue bold">Select a destination account:</span>
                                <br />
                                <table class="table table-dark table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Account Type</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Reserve</th>
                                            <th scope="col">Active</th>
                                            <th scope="col">Primary</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="destination.customerInfo && destination.customerInfo.customerAccounts && destination.customerInfo.customerAccounts.length > 0">
                                        <ng-container *ngFor="let customerAccount of destination.customerInfo.customerAccounts; let i = index">
                                            <tr class="clickable" [ngClass]="{'row-selected': customerAccount.selected}" (click)="selectCustomerAccount('dest', customerAccount.id)">
                                                <td scope="row">{{ customerAccount.account.accountType.description }}</td>
                                                <td scope="row">{{ customerAccount.account.balance | currency }}</td>
                                                <td scope="row">{{ customerAccount.account.reserve | currency }}</td>
                                                <td scope="row" [ngClass]="{'color-green': customerAccount.account.active, 'color-red': !customerAccount.account.active}">{{ customerAccount.account.active | boolYesNo }}</td>
                                                <td scope="row" [ngClass]="{'color-green': customerAccount.account.primary, 'color-red': !customerAccount.account.primary}">{{ customerAccount.account.primary | boolYesNo }}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </fieldset>
                    <button (click)="resetDestination()" type="button" class="btn btn-primary report-button margins"><span class="fas fa-trash"></span> Clear Destination</button>
                </div>
            </form>
        </div>
        <div class="text-center" style="padding-top: 40px;">
            <label class="col-form-label" for="amountDollars">Amount ($)</label>
            <input class="form-control" style="width: 90px; display: inline-block; margin-left: 5px;" type="number" step="0.01" [(ngModel)]="amountDollars" name="amountDollars" id="amountDollars" /><!-- (keydown.enter)="sendFunds()" -->
        </div>
        <div class="text-center" style="padding-top: 10px;">
            <psi-modal [settings]="sendFundsModalSettings" (confirmBtnClick)="sendFunds()" (cancelBtnClick)="cancelSendFundsBtnClick()" [disableAction]="busy() || disableSendFunds() || accountRestricted()" class="modal-button">
                <p>Are you sure you want to send <span class="bold color-green">{{ amountDollars | currency }}</span> from
                    <span *ngIf="source.userType === 1">Inmate Account</span>&nbsp;<span *ngIf="source.userType === 1" class="bold">{{ srcInmateAccountTypeaheadFilters && srcInmateAccountTypeaheadFilters.selectedInmateAccount ? srcInmateAccountTypeaheadFilters.selectedInmateAccount.fullName + ' (' + srcInmateAccountTypeaheadFilters.selectedInmateAccount.pin + ')' : 'N/A' }}</span>
                    <span *ngIf="source.userType === 2">PPC Account</span>&nbsp;<span *ngIf="source.userType === 2" class="bold">{{ source && source.customerInfo ? source.customerInfo.customerDisplay : 'N/A' }}</span> to <span *ngIf="destination.userType === 1">Inmate Account</span>&nbsp;
                    <span *ngIf="destination.userType === 1" class="bold">{{ destInmateAccountTypeaheadFilters && destInmateAccountTypeaheadFilters.selectedInmateAccount ? destInmateAccountTypeaheadFilters.selectedInmateAccount.fullName + ' (' + destInmateAccountTypeaheadFilters.selectedInmateAccount.pin + ')' : 'N/A' }}</span>
                    <span *ngIf="destination.userType === 2">PPC Account</span>&nbsp;<span *ngIf="destination.userType === 2" class="bold">{{ destination && destination.customerInfo ? destination.customerInfo.customerDisplay : 'N/A' }}</span>
                    ?</p>
                <div *ngIf="sendingFunds" class="spinner-border text-info psi-spinner-lg" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </psi-modal>
            <!-- <button type="button" class="text-center btn btn-success" (click)="sendFunds()" title="Send Funds" [disabled]="busy()"><span class="fas fa-3x fa-paper-plane"></span></button> -->
        </div>
    </div>
</div>
