<div class="page-container">
    <h2 class="page-title">Change Password</h2>

    <div *ngIf="!loading" class="filters-backdrop backdrop backdrop-sm">
        <form class="filters-container form-inline">
            <div class="form-row col-sm-12">
                <div class="col-sm-12 filter-field-container">
                    <label for="credentialsOldPassword" class="col-form-label col-sm-4 inline-block">Current password</label>
                    <input [(ngModel)]="args.oldPassword" name="credentialsOldPassword" id="credentialsOldPassword" type="password" (input)="resetErrors('oldPassword')" class="form-control full-width col-sm-8" required />
                    <div *ngIf="errors.oldPassword && errors.oldPassword.length > 0" class="psi-alert psi-alert-danger col-sm-8 offset-sm-4">
                        <div *ngFor="let err of errors.oldPassword; let i = index">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row col-sm-12">
                <div class="col-sm-12 filter-field-container">
                    <label for="credentialsNewPassword" class="col-form-label col-sm-4 inline-block">New password</label>
                    <input [(ngModel)]="args.newPassword" name="credentialsNewPassword" id="credentialsNewPassword" type="password" (input)="resetErrors('newPassword')" class="form-control full-width col-sm-8" (keydown.enter)="changePassword()" required />
                    <div *ngIf="errors.newPassword && errors.newPassword.length > 0" class="psi-alert psi-alert-danger col-sm-8 offset-sm-4">
                        <div *ngFor="let err of errors.newPassword; let i = index">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row col-sm-12">
                <div class="col-sm-12 filter-field-container">
                    <label for="credentialsNewPasswordConfirm" class="col-form-label col-sm-4 inline-block">Confirm new password</label>
                    <input [(ngModel)]="args.newPasswordConfirm" name="credentialsNewPasswordConfirm" id="credentialsNewPasswordConfirm" type="password" (input)="resetErrors('newPasswordConfirm')" class="form-control full-width col-sm-8" (keydown.enter)="changePassword()" required />
                    <div *ngIf="errors.newPasswordConfirm && errors.newPasswordConfirm.length > 0" class="psi-alert psi-alert-danger col-sm-8 offset-sm-4">
                        <div *ngFor="let err of errors.newPasswordConfirm; let i = index">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="filters-footer">
            <button (click)="changePassword()" type="button" class="btn btn-primary float-right report-button"><span class="fas fa-key"></span> Change Password</button>
            <button (click)="reset()" type="button" class="btn btn-secondary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>
    <div *ngIf="loading" class="col-sm-12 text-center">
        <div class="spinner-border text-info psi-spinner-lg" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>