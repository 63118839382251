<div class="page-container">
    <h2 class="page-title">Create User</h2>

    <div class="container large-container filters-backdrop">
        <form class="filters-container form-inline">
            <div class="col-12">
                <div class="form-row col-sm-12">
                    <div class="col-12 col-md-6 filter-field-container">
                        <label for="accountUserName" class="inline-block">Username <span class="color-red">*</span></label>
                        <input [(ngModel)]="filters.userName" name="accountUserName" id="accountUserName" (input)="resetErrors('userName')" type="text" class="form-control full-width col-12" required />
                        <div *ngIf="errors.userName && errors.userName.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.userName; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 filter-field-container">
                        <label for="accountEmail" class="inline-block">Email Address <span class="color-red">*</span></label>
                        <input [(ngModel)]="filters.email" name="accountEmail" id="accountEmail" (input)="resetErrors('email')" type="text" class="form-control full-width col-12" />
                        <div *ngIf="errors.email && errors.email.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.email; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 filter-field-container">
                        <label for="accountPassword" class="inline-block">Password <span class="color-red">*</span></label>
                        <input [(ngModel)]="filters.password" name="accountPassword" id="accountPassword" (input)="resetErrors('password')" type="password" class="form-control full-width col-12" />
                        <div *ngIf="errors.password && errors.password.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.password; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 filter-field-container">
                        <label for="accountPasswordConfirm" class="inline-block">Confirm Password <span class="color-red">*</span></label>
                        <input [(ngModel)]="filters.passwordConfirm" name="accountPasswordConfirm" (input)="resetErrors('passwordConfirm')" id="accountPasswordConfirm" type="password" class="form-control full-width col-12" />
                        <div *ngIf="errors.passwordConfirm && errors.passwordConfirm.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.passwordConfirm; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 filter-field-container">
                        <label for="accountSecretQuestion" class="inline-block">Security Question <span class="color-red">*</span></label>
                        <ng-select [items]="filterOptions.secretQuestions" bindLabel="label" bindValue="label" name="accountSecretQuestion" [(ngModel)]="filters.secretQuestion" (change)="resetErrors('secretQuestion')" class="form-control full-width col-sm-12 no-padding" ngDefaultControl></ng-select>
                        <div *ngIf="errors.secretQuestion && errors.secretQuestion.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.secretQuestion; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 filter-field-container">
                        <label for="accountSecretAnswer" class="inline-block">Secret Answer <span class="color-red">*</span></label>
                        <input [(ngModel)]="filters.secretAnswer" name="accountSecretAnswer" id="accountSecretAnswer" type="text" (input)="resetErrors('secretAnswer')" class="form-control full-width col-sm-12" />
                        <div *ngIf="errors.secretAnswer && errors.secretAnswer.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.secretAnswer; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 filter-field-container">
                        <label for="accountRole" class="inline-block">Role <span class="color-red">*</span></label>
                        <ng-select [items]="filterOptions.roles" bindLabel="name" bindValue="id" name="accountRole" [(ngModel)]="filters.roleId" (change)="resetErrors('role')" class="form-control full-width col-sm-12 no-padding" ngDefaultControl></ng-select>
                        <div *ngIf="errors.role && errors.role.length > 0" class="psi-alert psi-alert-danger">
                            <div *ngFor="let err of errors.role; let i = index">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="filters-footer">
            <button (click)="cancel()" type="button" class="btn btn-warning float-left report-button" [disabled]="creating"><span class="fas fa-arrow-circle-left"></span> Cancel</button>
            <button (click)="create()" type="button" class="btn btn-primary float-right report-button" [disabled]="creating"><span class="fa fa-search"></span> Create</button>
            <button (click)="reset()" type="button" class="btn btn-primary float-right report-button" [disabled]="creating"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>
</div>