import { Component, Input, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { HelperService } from 'src/app/helpers/helper.service';
import { Customer, CustomersService } from 'src/app/services/customers.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
	selector: 'customer-phone',
	templateUrl: './customer-phone.component.html'
})
export class CustomerPhoneComponent implements OnInit {
	@Input() public phoneNumber: string = null;

	public loadingCustomer: boolean = false;


	constructor(
		private _customersService: CustomersService,
		private _router: Router,
		private _snotify: SnotifyService,
		private _tokenStorage: TokenStorageService
	) { }

	ngOnInit(): void {
	}


	public hasPermission(permission: string): boolean {
		return this._tokenStorage.hasPermission(permission);
	}

	public redirectToEditCustomer(): void {
		this.getCustomerId().subscribe(
			(customer: Customer): void => {
				this._router.navigate(['/customers/edit', customer.id]);
			}, (err: any): void => {
				console.error('Error getting customer by phone', err);

				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to retrieve customer info.', 'Load Failed');
						break;
				}
			});
	}

	private getCustomerId(): Observable<Customer> {
		if (this.loadingCustomer) {
			return;
		}

		this.loadingCustomer = true;

		return this._customersService.getCustomerByPhone(this.phoneNumber)
			.pipe(
				map((result: Customer) => {
					this.loadingCustomer = false;
					return result;
				})
			);

		// this._customersService.getCustomerByPhone(this.phoneNumber)
		// 	.subscribe((result: Customer): void => {

		// 		this.loadingCustomer = false;
		// 	}, (err: any): void => {
		// 		console.error('Error getting customer by phone', err);

		// 		switch (err.status) {
		// 			case 400:
		// 				HelperService.getModelStateErrors(err).forEach(errMsg => {
		// 					this._snotify.warning(errMsg, 'Invalid Data');
		// 				});
		// 				break;
		// 			default:
		// 				this._snotify.error('An unexpected error occurred attempting to retrieve customer info.', 'Load Failed');
		// 				break;
		// 		}

		// 		this.loadingCustomer = false;
		// 	});
	}
}