<div class="page-container">
    <h2 class="page-title">Customer</h2>

    <div class="container large-container col-sm-12">
        <div class="col-sm-6" style="display:inline-block">
            <div class="filters-backdrop col-sm-11" [ngClass]="{'box-shadow-red': isRestricted()}">
                <div *ngIf="loadingCustomer || loadingConnectedInmates || loadingGlobalNumber || loadingFeatureRestrictions || loadingFacilities || loadingProdigyFeatures || loadingNotes || savingCustomer || !pendingCustomer" class="text-center">
                    <div class="spinner-border text-info psi-spinner-lg" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <form *ngIf="!loadingCustomer && !loadingConnectedInmates && !loadingGlobalNumber && !loadingNotes && !savingCustomer && pendingCustomer" class="filters-container form-inline align-items-flex-start">
                    <div class="col-sm-12">
                        <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isRestricted()}">
                            <legend class="col-sm-6">Customer Info</legend>
                            <div class="form-row col-sm-12">
                              <div class="col-sm-12 filter-field-container">
                                <label for="username" class="inline-block">Username</label>
                                <input [(ngModel)]="pendingCustomer.username" name="username" id="username" (input)="resetErrors('username')" type="text" class="form-control full-width col-sm-12" [disabled]="true" />
                              </div>
                              <div class="col-sm-12 col-md-6 filter-field-container">
                                <label for="customerFirstName" class="inline-block">First Name</label>
                                <input [(ngModel)]="pendingCustomer.firstName" name="customerFirstName" id="customerFirstName" (input)="resetErrors('firstName')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.firstName && errors.firstName.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.firstName; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-6 filter-field-container">
                                <label for="customerLastName" class="inline-block">Last Name</label>
                                <input [(ngModel)]="pendingCustomer.lastName" name="customerLastName" id="customerLastName" (input)="resetErrors('lastName')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.lastName && errors.lastName.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.lastName; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-6 filter-field-container">
                                <label for="customerEmail" class="inline-block">Email Address</label>
                                <input [(ngModel)]="pendingCustomer.email" name="customerEmail" id="customerEmail" (input)="resetErrors('email')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.email && errors.email.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.email; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-6 filter-field-container">
                                <label for="customerPhoneNumber" class="inline-block">Phone Number <span class="fas fa-question-circle" title="To change the account phone number, please use the 'Change Phone Number' button below"></span></label>
                                <input [(ngModel)]="pendingCustomer.phoneNumber" name="customerPhoneNumber" id="customerPhoneNumber" type="text" class="form-control full-width col-sm-12" [disabled]="true" />
                                <!-- <input [(ngModel)]="pendingCustomer.phoneNumber" name="customerPhoneNumber" id="customerPhoneNumber" (input)="resetErrors('phoneNumber')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
    <div *ngIf="errors.phoneNumber && errors.phoneNumber.length > 0" class="psi-alert psi-alert-danger">
                          <div *ngFor="let err of errors.phoneNumber; let i = index">
                              {{ err }}
                          </div>
                      </div> -->
                              </div>
                              <div class="col-sm-12 filter-field-container">
                                <label for="customerAddress1" class="inline-block">Address 1</label>
                                <input [(ngModel)]="pendingCustomer.address1" name="customerAddress1" id="customerAddress1" (input)="resetErrors('address1')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.address1 && errors.address1.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.address1; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 filter-field-container">
                                <label for="customerAddress2" class="inline-block">Address 2</label>
                                <input [(ngModel)]="pendingCustomer.address2" name="customerAddress2" id="customerAddress2" (input)="resetErrors('address2')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.address2 && errors.address2.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.address2; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6 col-md-4 filter-field-container">
                                <label for="customerCity" class="inline-block">City</label>
                                <input [(ngModel)]="pendingCustomer.city" name="customerCity" id="customerCity" (input)="resetErrors('city')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.city && errors.city.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.city; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6 col-md-4 filter-field-container">
                                <label for="customerState" class="inline-block">State</label>
                                <ng-select [items]="filterOptions.states" bindLabel="name" bindValue="abbreviation" name="customerState" [(ngModel)]="pendingCustomer.state" (change)="resetErrors('state')" class="form-control full-width col-sm-12 no-padding" [ngClass]="{'disabled': !customerEditable}" [disabled]="!customerEditable" ngDefaultControl></ng-select>
                                <div *ngIf="errors.state && errors.state.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.state; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6 col-md-4 filter-field-container">
                                <label for="customerPostalZip" class="inline-block">Postal/Zip</label>
                                <input [(ngModel)]="pendingCustomer.zip" name="customerPostalZip" id="customerPostalZip" (input)="resetErrors('zip')" type="text" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.zip && errors.zip.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.zip; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6 col-md-4 filter-field-container">
                                <label for="customerAccountPin" class="inline-block">Account PIN <span class="fas fa-question-circle" title="The account PIN is used to verify a customer's account."></span></label>
                                <input [(ngModel)]="pendingCustomer.accountPin" name="customerAccountPin" id="customerAccountPin" type="text" (input)="resetErrors('accountPin')" class="form-control full-width col-sm-12" [disabled]="!customerEditable" />
                                <div *ngIf="errors.accountPin && errors.accountPin.length > 0" class="psi-alert psi-alert-danger">
                                  <div *ngFor="let err of errors.accountPin; let i = index">
                                    {{ err }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12" *ngIf="isGloballyRestricted()" style="margin-top: 10px;">
                        <div class="bold col-12 color-red" style="vertical-align: top;">CUSTOMER IS FLAGGED WITH REASON: {{ getGlobalRestrictionReason() }}</div>
                        <div class="bold col-12 color-red" style="vertical-align: top;">CUSTOMER IS FLAGGED WITH REASON: {{ getGlobalRestrictionReason() }}</div>
                        <div class="bold col-12 color-red" style="vertical-align: top;">CUSTOMER IS FLAGGED WITH REASON: {{ getGlobalRestrictionReason() }}</div>
                    </div>
                </form>
                <div *ngIf="!loadingCustomer && pendingCustomer" class="filters-footer">
                  <psi-modal *ngIf="!customerEditable" [settings]="changePhoneModalSettings" (confirmBtnClick)="confirmChangePhoneBtnClick()" (cancelBtnClick)="cancelChangePhoneBtnClick()" [disableConfirmBtn]="savingChangePhone" [disableCancelBtn]="savingChangePhone">
                    <form *ngIf="!savingChangePhone" class="text-center">
                      <div class="form-row">
                        <label for="currentPhoneNumber" class="col-form-label col-sm-4 inline-block">Current Phone #</label>
                        <input name="currentPhoneNumber" id="currentPhoneNumber" type="text" class="form-control col-sm-8 inline-block" [(ngModel)]="changePhoneArgs.currentPhoneNumber" [disabled]="true" />
                      </div>
                      <div class="form-row">
                        <label for="newPhoneNumber" class="col-form-label col-sm-4">New Phone #</label>
                        <input name="newPhoneNumber" id="newPhoneNumber" type="text" class="form-control col-sm-8 inline-block" [(ngModel)]="changePhoneArgs.phoneNumber" />
                      </div>
                      <div class="form-row" *ngIf="customer.parentCustomerId && customer.parentCustomerId > 0">
                        <label for="newUserName" class="col-form-label col-sm-4">New Username <span class="fas fa-question-circle" title="A new username is required when the phone number of an account has already been changed."></span></label>
                        <input name="newUserName" id="newUserName" type="text" class="form-control col-sm-8 inline-block" [(ngModel)]="changePhoneArgs.userName" />
                      </div>
                      <br />
                      <div class="form-row">
                        <span *ngIf="!customer.parentCustomerId || customer.parentCustomerId === 0" class="color-red">After a phone number change, the customer will need to reset the password for their Prodigy Sales account through Prodigy Sales.</span>
                        <span *ngIf="customer.parentCustomerId && customer.parentCustomerId > 0" class="color-red">This will create a new Prodigy Sales account for the customer. They will need to reset their password through Prodigy Sales using the New Username provided. Their new account will have the same email address as their existing account.</span>
                      </div>
                    </form>
                    <!-- <div *ngIf="errors.addVendorLink.length > 0" class="color-red">
      <p *ngFor="let msg of errors.addVendorLink">{{ msg }}</p>
    </div> -->
                    <div *ngIf="savingChangePhone" class="spinner-border text-info psi-spinner-lg" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </psi-modal>

                  <psi-modal *ngIf="!customerEditable" class="modal-button" [settings]="enforcePhoneCallsModalSettings" (confirmBtnClick)="enforcePhoneCalls()">
                    <p>Enforcing phone calls will make it so that calls to Friends & Family accounts will be sent to the phone number on file rather than through the mobile app.</p>
                  </psi-modal>

                  <psi-modal *ngIf="customerEditable" class="modal-button" [settings]="resetPasswordModalSettings" (confirmBtnClick)="resetCustomerPassword()">
                    <p>Are you sure you want to reset customer's password?</p>
                  </psi-modal>

                  <psi-modal *ngIf="customerEditable && !isCustomerVerified" class="modal-button" [settings]="verifyPhoneNumberModalSettings" (confirmBtnClick)="verifyCode()" [disableConfirmBtn]="!isCodeInputValid()" >
                    <div>
                      <button [disabled]="isCodeSent" (click)="sendVerificationCode()" class="btn btn-primary">Send Verification code</button>
                    </div>
                    <div style="margin-top: 16px;">
                      <p>Ender 6-digit code:</p>
                      <input [disabled]="!isCodeSent" [(ngModel)]="verificationCode" type="text" class="form-control col-4 inline-block" />
                    </div>
                  </psi-modal>

                  <psi-modal *ngIf="customerEditable && isCustomerVerified" class="modal-button" [settings]="detachPhoneNumberModalSettings" (confirmBtnClick)="detachPhone()">
                    <p>Are you sure you want to detach customer's phone number?</p>
                  </psi-modal>

                  <button *ngIf="customerEditable" (click)="cancel()" type="button" class="btn btn-warning float-left report-button"><span class="fas fa-ban"></span> Cancel</button>
                  <button *ngIf="customerEditable" (click)="save()" type="button" class="btn btn-success float-right report-button"><span class="fa fa-save"></span> Save</button>

                  <button *ngIf="!customerEditable" (click)="edit()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-user-edit"></span> Edit</button>
                </div>
                <div *ngIf="!loadingCustomer">
                  <psi-modal class="modal-button" [settings]="driverLicensePhotoModalSettings" *ngIf="driverLicenseFound">
                    <div *ngIf="!driverLicensePhoto" class="text-center">
                      <div class="spinner-border text-info psi-spinner-lg" role="status">
                        <span class="sr-only">Loading Driver License Photo...</span>
                      </div>
                    </div>
                    <!--<img *ngIf="driverLicensePhoto" [src]="driverLicensePhoto.url" alt="Driver License">-->
                    <img *ngIf="driverLicensePhoto" [src]="driverLicensePhoto.data" alt="Driver License N/A" style="max-width: 100%; width: 100%;">
                  </psi-modal>

                  <psi-modal class="modal-button" [settings]="selfiePhotoModalSettings" *ngIf="selfiePhotoFound">
                    <div *ngIf="!selfiePhoto" class="text-center">
                      <div class="spinner-border text-info psi-spinner-lg" role="status">
                        <span class="sr-only">Loading Selfie Photo...</span>
                      </div>
                    </div>
                    <!--<img *ngIf="driverLicensePhoto" [src]="driverLicensePhoto.url" alt="Driver License">-->
                    <img *ngIf="selfiePhoto" [src]="selfiePhoto.data" alt="Selfie Photo N/A" style="max-width: 100%; width: 100%;">
                  </psi-modal>

                </div>
            </div>
            <div class="filters-backdrop col-sm-11" [ngClass]="{'box-shadow-red': isRestricted()}">
                <div *ngIf="loadingCustomer || loadingNotes" class="text-center">
                    <div class="spinner-border text-info psi-spinner-lg" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!loadingNotes && !loadingCustomer && !savingNote" class="col-12" style="padding-top: 20px;">
                    <input class="col-9 form-control inline-block" type="text" [(ngModel)]="newNote" minlength="1" maxlength="1000" (keydown.enter)="createNote()" />
                    <button (click)="createNote()" type="button" class="btn btn-success col-3" [disabled]="!newNote"><span class="fas fa-plus"></span> Add Note</button>
                </div>
                <form *ngIf="!loadingCustomer && !loadingNotes" class="filters-container form-inline align-items-flex-start">
                    <div class="col-sm-12">
                        <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isRestricted()}">
                            <legend class="col-sm-4">Notes</legend>
                            <div class="form-row col-sm-12">
                                <div *ngIf="notes && notes.length > 0" class="circuit-group-upload-list">
                                    <ul>
                                        <li *ngFor="let note of notes">
                                            <span class="color-brand-blue">{{ note.created | date: 'MM/dd/yyyy @ HH:mm' }}:</span> {{ note.note }}
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="notes && notes.length === 0">
                                    <p>No Customer Notes Found</p>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </form>
            </div>
        </div>

        <div class="filters-backdrop customer-accounts-container col-sm-6" [ngClass]="{'box-shadow-red': isRestricted()}">
            <form class="filters-container form-inline align-items-flex-start">
                <div class="col-sm-12">
                    <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isRestricted()}">
                        <legend class="col-sm-4">Accounts</legend>
                        <table class="table table-dark table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Account Type</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Phone Reserve</th>
                                    <th scope="col">Video Reserve</th>
                                    <th scope="col">Active</th>
                                    <th scope="col">Primary</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="customer && customer.customerAccounts && customer.customerAccounts.length > 0">
                                <ng-container *ngFor="let customerAccount of customer.customerAccounts; let i = index">
                                    <tr [ngClass]="{'odd': (i % 2 == 0)}">
                                        <td scope="row">{{ customerAccount.account.accountType.description }}</td>
                                        <td scope="row">{{ customerAccount.account.balance | currency }}</td>
                                        <td scope="row">{{ customerAccount.account.reserve | currency }}</td>
                                        <td scope="row">{{ customerAccount.account.videoReserve | currency }}</td>
                                        <td scope="row" [ngClass]="{'color-green': customerAccount.account.active, 'color-red': !customerAccount.account.active}">{{ customerAccount.account.active | boolYesNo }}</td>
                                        <td scope="row" [ngClass]="{'color-green': customerAccount.account.primary, 'color-red': !customerAccount.account.primary}">{{ customerAccount.account.primary | boolYesNo }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="!loadingCustomer && customer && customer.customerAccounts && customer.customerAccounts.length === 0">
                                <tr>
                                    <td colspan="12">No Results Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="loadingCustomer" class="text-center">
                                <tr>
                                    <td colspan="12">
                                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>
            </form>
            <form class="filters-container form-inline align-items-flex-start" *ngIf="hasPermission('Customers_Connected_Inmates_View')">
                <div class="col-sm-12">
                    <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isRestricted()}">
                        <legend class="col-sm-6">Connected Inmates</legend>
                        <table class="table table-dark table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Date of Birth</th>
                                    <th scope="col">Facility</th> 
                                    <th scope="col">Reviewed</th>
                                    <th scope="col">Approved</th>
                                    <th scope="col">Denied</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="connectedInmates && connectedInmates.length > 0">
                                <ng-container *ngFor="let connectedInmate of connectedInmates; let i = index">
                                    <tr [ngClass]="{'odd': (i % 2 == 0)}">
                                        <td scope="row">{{ connectedInmate.inmate.firstName }}</td>
                                        <td scope="row">{{ connectedInmate.inmate.lastName }}</td>
                                        <td scope="row">{{ connectedInmate.inmate.dateOfBirth | date: 'MM/dd/yyyy' }}</td>
                                        <td scope="row">{{ connectedInmate.facility.name }}</td>
                                        <td scope="row" [ngClass]="{'color-green': connectedInmate.reviewed, 'color-red': !connectedInmate.reviewed}">{{ (connectedInmate.reviewed !== null) | boolYesNo }}</td>
                                        <td scope="row" [ngClass]="{'color-green': connectedInmate.approved, 'color-red': !connectedInmate.approved}">{{ (connectedInmate.approved !== null) | boolYesNo }}</td>
                                        <td scope="row" [ngClass]="{'color-green': connectedInmate.denied, 'color-red': !connectedInmate.denied}">{{ (connectedInmate.denied !== null) | boolYesNo }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="!loadingConnectedInmates && connectedInmates && connectedInmates.length === 0">
                                <tr>
                                    <td colspan="12">No Connected Inmates Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="loadingConnectedInmates" class="text-center">
                                <tr>
                                    <td colspan="12">
                                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>
            </form>
            <form class="filters-container form-inline align-items-flex-start" *ngIf="hasPermission('Customers_Feature_Restrictions_View')">
                <div class="col-sm-12">
                    <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isRestricted()}">
                        <legend class="col-sm-6">Feature Restrictions</legend>
                        <table class="table table-dark table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Facility</th>
                                    <th scope="col">Feature</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Active</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="featureRestrictions && featureRestrictions.length > 0">
                                <ng-container *ngFor="let restriction of featureRestrictions; let i = index">
                                    <tr [ngClass]="{'odd': (i % 2 == 0)}">
                                        <td scope="row">{{ restriction.facilityName }}</td>
                                        <td scope="row">{{ restriction.prodigyFeatureName }}</td>
                                        <td scope="row">{{ restriction.created | date: 'MM/dd/yyyy' }}</td>
                                        <td scope="row">{{ restriction.restrictionEnd | date: 'MM/dd/yyyy' }}</td>
                                        <td scope="row" [ngClass]="{'color-green': restriction.currentlyActive, 'color-red': !restriction.currentlyActive}">{{ restriction.currentlyActive | boolYesNo }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="!loadingFeatureRestrictions && !loadingFacilities && !loadingProdigyFeatures && featureRestrictions && featureRestrictions.length === 0">
                                <tr>
                                    <td colspan="12">No Feature Restrictions Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="loadingFeatureRestrictions || loadingFacilities || loadingProdigyFeatures" class="text-center">
                                <tr>
                                    <td colspan="12">
                                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>
            </form>
            <form class="filters-container form-inline align-items-flex-start" *ngIf="hasPermission('Customers_Video_Approvals_View')">
                <div class="col-sm-12">
                    <fieldset class="col-sm-12 form-section" [ngClass]="{'border-red': isRestricted()}">
                        <legend class="col-sm-6">Video Approvals</legend>
                        <table class="table table-dark table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Facility Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Denial Reason</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="videoApprovals && videoApprovals.length > 0">
                                <ng-container *ngFor="let videoApproval of videoApprovals; let i = index">
                                    <tr [ngClass]="{'odd': (i % 2 == 0)}">
                                        <td scope="row">{{ videoApproval.facilityName }}</td>
                                        <td scope="row">
                                            <span *ngIf="videoApproval.customerVerificationStatus === CustomerVerificationStatus.APPROVED">
                                                <span class="color-green">Approved</span>
                                            </span>
                                            <span *ngIf="videoApproval.customerVerificationStatus === CustomerVerificationStatus.DENIED">
                                                <span class="color-red">Denied</span>
                                            </span>
                                            <span *ngIf="videoApproval.customerVerificationStatus === CustomerVerificationStatus.PENDING">
                                                <span class="color-orange">Pending</span>
                                            </span>
                                            <span *ngIf="videoApproval.customerVerificationStatus === CustomerVerificationStatus.AWAITING_ONSITE_REVIEW">
                                                <span class="color-orange">Awaiting Onsite Review</span>
                                            </span>
                                            <span *ngIf="videoApproval.customerVerificationStatus === CustomerVerificationStatus.PERMANENTLY_DENIED">
                                                <span class="color-red">Permanently Denied</span>
                                            </span>
                                        </td>
                                        <td scope="row">{{ videoApproval.customerDenialReason }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="!loadingVideoApprovals && videoApprovals && videoApprovals.length === 0">
                                <tr>
                                    <td colspan="12">No Video Approvals Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="loadingVideoApprovals" class="text-center">
                                <tr>
                                    <td colspan="12">
                                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>
                <div class="col-12" *ngIf="isGloballyRestricted()" style="margin-top: 10px;">
                    <div class="bold col-12 color-red" style="vertical-align: top;">CUSTOMER IS FLAGGED WITH REASON: {{ getGlobalRestrictionReason() }}</div>
                    <div class="bold col-12 color-red" style="vertical-align: top;">CUSTOMER IS FLAGGED WITH REASON: {{ getGlobalRestrictionReason() }}</div>
                    <div class="bold col-12 color-red" style="vertical-align: top;">CUSTOMER IS FLAGGED WITH REASON: {{ getGlobalRestrictionReason() }}</div>
                </div>
            </form>
            <div class="filters-footer">
                <div *ngIf="!loadingCustomer && !loadingConnectedInmates && !transferringFunds && !refundingOrders">
                    <psi-modal *ngIf="hasPermission('Customers_Transfer_Funds')" [settings]="transferFundsModalSettings" (confirmBtnClick)="transferFunds()" (cancelBtnClick)="cancelTransferFundsBtnClick()" [disableConfirmBtn]="transferringFunds" [disableCancelBtn]="transferringFunds">
                        <form *ngIf="!transferringFunds" class="text-center">
                            <div class="form-row">
                                <label for="transferFundsSourceAccount" class="col-form-label col-sm-4">Source</label>
                                <ng-select [items]="filterOptions.sourceAccounts" bindLabel="display" bindValue="id" name="transferFundsSourceAccount" [(ngModel)]="transferFilters.sourceAccountId" class="col-sm-8 no-padding" ngDefaultControl></ng-select>
                            </div>
                            <div class="form-row">
                                <label for="transferFundsDestinationAccount" class="col-form-label col-sm-4">Destination</label>
                                <ng-select [items]="filterOptions.destinationAccounts" bindLabel="display" bindValue="id" name="transferFundsDestinationAccount" [(ngModel)]="transferFilters.destinationAccountId" class="col-sm-8 no-padding" ngDefaultControl></ng-select>
                            </div>
                            <div class="form-row">
                                <label for="transferAmount" class="col-form-label col-sm-4 inline-block">Transfer Amount ($)</label>
                                <div class="col-sm-6 inline-block">
                                    <input [disabled]="!transferFilters.sourceAccountId" name="transferAmount" id="transferAmount" type="number" min="0.01" step="0.01" max="{{getSourceMaxAmount()}}" class="form-control col-sm-12 inline-block" [(ngModel)]="transferFilters.amount" />
                                </div>
                            </div>
                          <div class="form-row">
                                <label for="transferFee" class="col-form-label col-sm-4 inline-block">Transfer Fee ($)</label>
                                <div class="col-sm-6 inline-block">
                                    <input [disabled]="true" name="transferFee" id="transferFee" type="text" class="form-control col-sm-12 inline-block" [(ngModel)]="transferFundFeeLabel"/>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="transferringFunds" class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </psi-modal>
                    <psi-modal class="modal-button" *ngIf="hasPermission('Payments_Refund')" [settings]="refundOrdersModalSettings" (confirmBtnClick)="processBulkRefund()" (cancelBtnClick)="cancelRefundOrdersBtnClick()" [disableConfirmBtn]="refundingOrders || loadingRefundSummary || !refundSummaryLoaded" [disableCancelBtn]="refundingOrders || loadingRefundSummary" [disableAction]="refundingOrders">
                        <form *ngIf="!refundingOrders && !loadingRefundSummary" class="text-center">
                            <div *ngIf="!refundSummaryLoaded">
                                <div class="form-row">
                                    <label for="refundOrdersAccounts" class="col-form-label col-sm-4">Account</label>
                                    <ng-select [items]="filterOptions.refundAccounts" bindLabel="display" bindValue="id" name="refundOrdersAccounts" [(ngModel)]="refundFilters.accountId" class="col-sm-8 no-padding" ngDefaultControl></ng-select>
                                </div>
                                <div class="form-row">
                                    <label for="refundAmount" class="col-form-label col-sm-4 inline-block">Refund Amount ($)</label>
                                    <div class="col-sm-6 inline-block">
                                        <input name="refundAmount" id="refundAmount" type="number" min="0.01" step="0.01" max="1000" class="form-control col-sm-12 inline-block" [(ngModel)]="refundFilters.amount" />
                                    </div>
                                </div>
    
                                <br />
    
                                <button type="button" (click)="loadRefundSummary()" class="btn btn-primary text-center"><span class="far fa-newspaper"></span> Load Refund Summary</button>
                            </div>
                            <div *ngIf="refundSummaryLoaded && refundOrders && refundOrders.length > 0" class="circuit-group-upload-list">
                                Please read and confirm the below refund summary:
                                <ul>
                                    <li *ngFor="let order of refundOrders">
                                        Transaction ID: {{ order.transactionId }} -- Amount: {{ order.amount | currency }}
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="loadedCopySummary && circuitFeatureCopyItems && circuitFeatureCopyItems.length === 0" class="color-red">
                                <p>The selected circuits already have all the same features enabled/disabled. There are no changes to be made.</p>
                            </div>
                        </form>
                        <div *ngIf="refundingOrders || loadingRefundSummary" class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </psi-modal>
                </div>
                <div *ngIf="transferringFunds || refundingOrders" class="spinner-border text-info psi-spinner-lg" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6" style="display:inline-block">

        </div>
    </div>
</div>
