import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user.service';

// const httpOptions = {
// 	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly _apiUrl = `${environment.apiUrl}/Accounts`;


	constructor(
		private http: HttpClient
	) { }

	public login(credentials: Credentials): Observable<User> {
		return this.http.post<any>(`${this._apiUrl}/Login`, credentials);
	}

	public logout(): Observable<any> {
		return this.http.get(`${this._apiUrl}/Logout`);
	}

	public changePassword(args: ChangePasswordArgs): Observable<any> {
		return this.http.post(`${this._apiUrl}/ChangePassword`, args);
	}

	public resetPassword(username: string): Observable<any> {
		return this.http.get(`${this._apiUrl}/ResetPassword?username=${username}`);
	}

	// public register(user): Observable<any> {
	// 	return this.http.post(AUTH_API + 'signup', {
	// 		username: user.username,
	// 		email: user.email,
	// 		password: user.password
	// 	}, httpOptions);
	// }
}

export class ChangePasswordArgs {
	public oldPassword: string;
	public newPassword: string;
	public newPasswordConfirm: string;
}

export class Credentials {
	public username: string;
	public password: string;
}