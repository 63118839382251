<div class="page-container">
    <h2 class="page-title">Login</h2>

    <div *ngIf="!loading" class="filters-backdrop backdrop backdrop-sm">
        <form class="filters-container form-inline">
            <div class="form-row col-sm-12">
                <div class="col-sm-12 filter-field-container">
                    <label for="credentialsUsername" class="col-form-label col-sm-4 inline-block">Username</label>
                    <input [(ngModel)]="credentials.username" name="credentialsUsername" id="credentialsUsername" type="text" class="form-control full-width col-sm-8" />
                </div>
            </div>
            <div class="form-row col-sm-12" *ngIf="!forgotPasswordOpen">
                <div class="col-sm-12 filter-field-container">
                    <label for="credentialsPassword" class="col-form-label col-sm-4 inline-block">Password</label>
                    <input [(ngModel)]="credentials.password" name="credentialsPassword" id="credentialsPassword" type="password" class="form-control full-width col-sm-8" (keydown.enter)="login()" required />
                </div>
            </div>
        </form>
        <div class="filters-footer">
            <div *ngIf="forgotPasswordOpen">
                <button (click)="resetPassword()" type="button" class="btn btn-primary float-right report-button"><span class="fas fa-sign-in-alt"></span> Reset Password</button>
                <button (click)="closeForgotPassword()" type="button" class="btn btn-secondary float-right report-button"><span class="fas fa-ban"></span> Cancel</button>
            </div>
            <div *ngIf="!forgotPasswordOpen">
                <button (click)="openForgotPassword()" type="button" class="btn btn-warning float-left report-button"><span class="fas fa-key"></span> Forgot Password</button>
                <button (click)="login()" type="button" class="btn btn-primary float-right report-button"><span class="fas fa-sign-in-alt"></span> Login</button>
                <button (click)="reset()" type="button" class="btn btn-secondary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="col-sm-12 text-center">
        <div class="spinner-border text-info psi-spinner-lg" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>