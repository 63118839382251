import { Component } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { SnotifyDefaults, SnotifyService } from 'ng-snotify';
import { TokenStorageService } from './services/token-storage.service';
import * as _ from 'lodash';
import { BroadcastService } from './services/broadcast.service';
import { Permission } from './shared/global';
import { User } from './services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	public permissionList: string[];
	public isLoggedIn: boolean = false;
	public userName: string;

	public title = 'Prodigy CSR';

	constructor(
		private _broadcastService: BroadcastService,
		private _ngSelectConfig: NgSelectConfig,
		private _snotify: SnotifyService,
		private _tokenStorage: TokenStorageService
	) {
		this._ngSelectConfig.placeholder = 'Select Item';
		const defaults: SnotifyDefaults = {
			toast: {
				timeout: 7000
			}
		};
		this._snotify.setDefaults(defaults);

		this._broadcastService.subscribe("LOGIN", () => { this.isLoggedIn = true; });
		this._broadcastService.subscribe("LOGOUT", () => { this.isLoggedIn = false; });
	}

	ngOnInit(): void {
		//this.isLoggedIn = !!this._tokenStorage.getToken();
		this.isLoggedIn = this._tokenStorage.getIsLoggedIn();

		if (this.isLoggedIn) {
			const user = this._tokenStorage.getUser();
			this.permissionList = user.permissionList;
			this.userName = user.userName;
		}
	}

	public hasAnyAdminPermissions(): boolean {
		const user: User = this._tokenStorage.getUser();
		if (!user) {
			return false;
		}

		return (
			_.includes(user.permissionList, Permission.SUPER_ADMIN) ||
			_.includes(user.permissionList, Permission.USER_ACTIONS_VIEW) ||
			_.includes(user.permissionList, Permission.USERS_VIEW)
		);
	}

	public hasAnyPaymentPermissions(): boolean {
		const user: User = this._tokenStorage.getUser();
		if (!user) {
			return false;
		}

		return (
			_.includes(user.permissionList, Permission.SUPER_ADMIN) ||
			_.includes(user.permissionList, Permission.PAYMENTS_PROCESS) ||
			_.includes(user.permissionList, Permission.PAYMENTS_SEND_FUNDS)
		);
	}

	public hasPermission(permission: string): boolean {
		return this._tokenStorage.hasPermission(permission);
	}

	public logout(): void {
		this._tokenStorage.signOut();
		window.location.reload();
	}
}