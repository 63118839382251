import { Injectable } from "@angular/core";

@Injectable()
export class CacheService {
	public static keys = {
		ORDERS_REPORT_FILTERS: 'OrdersReportFilters',
		CUSTOMER_REPORT_FILTERS: 'CustomerReportFilters',
		PRODUCT_RATE_FILTERS: 'ProductRateFilters',
		USER_ACTION_FILTERS: 'UserActionFilters',
		USER_REPORT_FILTERS: 'UserReportFilters'
	};

	private static _cache = {};


	constructor() {

	}

	public static clearCache(): void {
		this._cache = {};
	}

	public static getItem(key: string): any {
		return this._cache[key];
	}

	public static setItem(key: string, val: any): void {
		this._cache[key] = val;
	}
}