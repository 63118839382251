<div class="page-container">
    <h2 class="page-title">Users</h2>

    <div class="container large-container filters-backdrop">
        <form class="filters-container form-inline">
            <div class="form-row col-12">
                <div class="col-6 col-md-4 filter-field-container">
                    <label for="filterUserName" class="col-form-label col-4 inline-block">Username</label>
                    <input [(ngModel)]="filters.userName" name="filterUserName" id="filterUserName" type="text" class="form-control full-width col-8" maxlength="256" />
                </div>
                <div class="col-6 col-md-4 filter-field-container">
                    <label for="filterEmail" class="col-form-label col-4 inline-block">Email</label>
                    <input [(ngModel)]="filters.email" name="filterEmail" id="filterEmail" type="text" class="form-control full-width col-8" maxlength="256" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filtersRoleIdList" class="col-form-label col-md-4 inline-block">Roles</label>
                    <ng-select [multiple]="true" name="filtersRoleIdList" [(ngModel)]="filters.roleIdList" [loading]="loadingRoles" [disabled]="loadingReport || loadingRoles || !rolesLoaded" class="col-md-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let role of filterOptions.roles" [value]="role.id">{{ role.name }}</ng-option>
                    </ng-select>
                </div>
            </div>
        </form>
        <div class="filters-footer">
            <button type="button" class="btn btn-success float-left report-button" (click)="redirectToCreateUser()" *ngIf="hasPermission('Users_Create')"><span class="fas fa-plus"></span> Create User</button>
            <button (click)="loadReport()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-search"></span> Search</button>
            <button (click)="reset()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>

    <div class="container large-container results-container">
        <table class="table table-dark table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'UserName')">Username</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'Email')">Email</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'Role')">Role</th>
                    <th scope="col" class="sortable headerSortDown" (click)="sortTable($event, 'CreateDate')">Created</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'LastLoginDate')">Last Login</th>
                    <th scope="col" *ngIf="hasPermission('Users_Edit')">Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="reportItems && reportItems.length > 0">
                <ng-container *ngFor="let item of reportItems; let i = index">
                    <tr [ngClass]="{'odd': (i % 2 == 0)}">
                        <td scope="row" (click)="toggleDetails(item)">
                            <span class="clickable fas" [ngClass]="{'fa-minus': item.showDetails, 'fa-plus': !item.showDetails}"></span>
                        </td>
                        <td scope="row">{{ item.userName }}</td>
                        <td scope="row">{{ item.email }}</td>
                        <td scope="row">{{ item.roleName }}</td>
                        <td scope="row">{{ item.createDate | date: 'MM/dd/yyyy @ HH:mm' }}</td>
                        <td scope="row">{{ item.lastLoginDate | date: 'MM/dd/yyyy @ HH:mm' }}</td>
                        <td scope="row" *ngIf="hasPermission('Users_Edit')">
                            <span (click)="editUser(item)" class="fas fa-user-edit clickable" *ngIf="hasPermission('Users_Edit')"></span>
                        </td>
                    </tr>
                    <tr *ngIf="item.showDetails" [ngClass]="{'odd': (i % 2 == 0)}">
                        <td colspan="15" class="col-sm-12 order-report-row-details">
                            <div class="col-md-4">
                                <div class="bold">Approved:</div>
                                <div [ngClass]="{'color-green': item.isApproved, 'color-red': !item.isApproved}">&nbsp;&nbsp;{{ item.isApproved | boolYesNo }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Locked Out:</div>
                                <div [ngClass]="{'color-red': item.isLockedOut, 'color-green': !item.isLockedOut}">&nbsp;&nbsp;{{ item.isLockedOut | boolYesNo }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Last Lockout:</div>
                                <div>&nbsp;&nbsp;{{ item.lastLockoutDate | date: 'MM/dd/yyyy @ HH:mm' }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Last Pwd Change:</div>
                                <div>&nbsp;&nbsp;{{ item.lastPasswordChangedDate | date: 'MM/dd/yyyy @ HH:mm' }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Failed Pwd Attempt Count:</div>
                                <div>&nbsp;&nbsp;{{ item.failedPasswordAttemptCount ? item.failedPasswordAttemptCount : 0 }}</div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="!loadingReport && reportItems && reportItems.length === 0">
                <tr>
                    <td colspan="12">No Results Found</td>
                </tr>
            </tbody>
            <tbody *ngIf="loadingReport" class="text-center">
                <tr>
                    <td colspan="12">
                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="!loadingReport && reportItems && reportItems.length > 0 && collectionSize > pageSize" class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadReport()" [boundaryLinks]="true" [maxSize]="7"></ngb-pagination>
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadReport()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>