import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { HelperService } from '../../helpers/helper.service';
import { CacheService } from '../../services/cache.service';
import { SelectOptionGuid, SharedService } from '../../services/shared.service';
import { GetUserReportArgs, UserReport, UserReportItem, UserService } from '../../services/user.service';

@Component({
	selector: 'app-search-users',
	templateUrl: './search-users.component.html'
})
export class SearchUsersComponent implements OnInit {
	public page: number = 1;
	public pageSize: number = 10;
	public collectionSize: number = 0;
	public sortField: string = 'CreateDate';
	public sortDesc: boolean = true;

	private def: any = {
		filters: {
			userName: null,
			email: null,
			roleIdList: []
		},
		filterOptions: {
			roles: []
		}
	};

	public filters: any = _.cloneDeep(this.def.filters);
	public filterOptions: any = _.cloneDeep(this.def.filterOptions);

	public reportItems: UserReportItem[] = [];
	public reportArgs: GetUserReportArgs = new GetUserReportArgs();

	public loadingReport: boolean = false;
	public loadingRoles: boolean = false;
	public rolesLoaded: boolean = false;


	constructor(
		private readonly _router: Router,
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService,
		private readonly _tokenStorage: TokenStorageService,
		private readonly _userService: UserService
	) { }

	ngOnInit(): void {
		this.loadReport();
		this.loadRoles();
	}


	public editUser(item: UserReportItem): void {
		this._router.navigate(['/users/edit', item.userId]);
	}

	public hasPermission(permission: string): boolean {
		return this._tokenStorage.hasPermission(permission);
	}

	public loadReport(): void {
		if (this.loadingReport) {
			return;
		}

		this.loadingReport = true;

		this.updateArgs();

		this.reportItems = [];

		this._userService.getUserReport(this.reportArgs)
			.subscribe((result: UserReport): void => {
				this.reportItems = result.items;
				this.collectionSize = result.totalRowCount;

				CacheService.setItem(CacheService.keys.USER_ACTION_FILTERS, this.filters);

				this.loadingReport = false;
			}, (err: any): void => {
				console.error('Failed to retrieve users', err);
				this.loadingReport = false;
				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load users.', 'Load Failed');
						break;
				}
			}, (): void => {
				//
			});
	}

	public loadRoles(): void {
		if (this.loadingRoles) {
			return;
		}

		this.loadingRoles = true;

		if (this.rolesLoaded) {
			this.rolesLoaded = false;
		}

		this._sharedService.getRoles()
			.subscribe((result: SelectOptionGuid[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.roles = [...result];

				this.rolesLoaded = true;
				this.loadingRoles = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all user roles', err);
				this.rolesLoaded = false;
				this.loadingRoles = false;
				this._snotify.error('Failed to retrieve list of all user roles', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public redirectToCreateUser(): void {
		this._router.navigate(['/users/create']);
	}

	public reset(): void {
		this.filters = _.cloneDeep(this.def.filters);
	}

	public sortTable($event: any, col: string): void {
		if (!this.loadingReport) {
			const target = $event.target || $event.srcElement || $event.currentTarget;
			for (let i = 0; i < target.parentElement.children.length; i++) {
				target.parentElement.children[i].classList.remove('headerSortUp');
				target.parentElement.children[i].classList.remove('headerSortDown');
			}

			this.sortDesc = !this.sortDesc;
			if (this.sortDesc) {
				target.classList.remove('headerSortUp');
				target.classList.add('headerSortDown');
			} else {
				target.classList.remove('headerSortDown');
				target.classList.add('headerSortUp');
			}
			this.sortField = col;

			this.loadReport();
		}
	}

	public toggleDetails(item: UserReportItem): void {
		item.showDetails = !item.showDetails;
	}

	private updateArgs(): void {
		this.reportArgs = { ...this.filters };

		this.reportArgs.startIndex = this.pageSize * (this.page - 1);
		this.reportArgs.itemsPerPage = this.pageSize;
		this.reportArgs.sortField = this.sortField;
		this.reportArgs.sortOrder = this.sortDesc ? 'DESC' : 'ASC';
	}
}