import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { HelperService } from 'src/app/helpers/helper.service';
import { SelectOptionGuid, SharedService } from 'src/app/services/shared.service';
import { ChangeUserArgs, User, UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit {
	private userId: string = null;
	private def: any = {
		changeUserArgs: {
			email: null,
			comment: null,
			mobilePin: null,
			roleId: null,
			isApproved: false,
			isLockedOut: false
		},
		errors: {
			email: [],
			comment: [],
			mobilePin: [],
			role: []
		}
	};

	public changeUserArgs: ChangeUserArgs = _.cloneDeep(this.def.changeUserArgs);
	public user: User = null;
	public pendingUser: User = null;
	public errors: any = _.cloneDeep(this.def.errors);

	public filterOptions: any = {
		roles: [],
		secretQuestions: HelperService.getSecurityQuestionList()
	};

	public loadingUser: boolean = false;
	public loadingRoles: boolean = false;
	public rolesLoaded: boolean = false;
	public savingUser: boolean = false;


	constructor(
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _userService: UserService,
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService
	) { }

	ngOnInit(): void {
		this.userId = this._activatedRoute.snapshot.paramMap.get("userid");

		this.loadRoles();
		this.loadUser(this.userId);
	}


	public loadRoles(): void {
		if (this.loadingRoles) {
			return;
		}

		this.loadingRoles = true;

		if (this.rolesLoaded) {
			this.rolesLoaded = false;
		}

		this._sharedService.getRoles()
			.subscribe((result: SelectOptionGuid[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.roles = [...result];

				this.rolesLoaded = true;
				this.loadingRoles = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all user roles', err);
				this.rolesLoaded = false;
				this.loadingRoles = false;
				this._snotify.error('Failed to retrieve list of all user roles', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public loadUser(userId: string): void {
		if (this.loadingUser) {
			return;
		}

		this.loadingUser = true;

		this.user = null;
		this.pendingUser = null;

		this._userService.getUser(userId)
			.subscribe((user: User): void => {
				this.user = _.cloneDeep(user);
				this.pendingUser = _.cloneDeep(user);

				this.loadingUser = false;
			}, (err: any): void => {
				console.error('Failed to retrieve user data', err);

				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load user data.', 'Load Failed');
						break;
				}

				this.loadingUser = false;
			}, (): void => {
				//
			});
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	public resetUser(): void {
		this.pendingUser = _.cloneDeep(this.user);
		this.errors = _.cloneDeep(this.def.errors);
	}

	public save(): void {
		if (this.savingUser) {
			return;
		}

		this.savingUser = true;

		this.updateChangeUserArgs();

		if (this.validatePendingUser()) {
			this._userService.changeUser(this.changeUserArgs)
				.subscribe((): void => {
					this.loadUser(this.userId);
					this.savingUser = false;

					this._snotify.success('User changes saved!', 'Save Success');
				}, (err: any): void => {
					console.error('Failed to save user changes', err);

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred save user changes.', 'Save Failed');
							break;
					}

					this.pendingUser = _.cloneDeep(this.user);
					this.savingUser = false;
				}, (): void => {
					//
				});
		} else {
			this.savingUser = false;
		}
	}

	private updateChangeUserArgs(): void {
		this.changeUserArgs = new ChangeUserArgs();
		this.changeUserArgs.userId = this.userId;
		this.changeUserArgs.roleId = this.pendingUser.roleId;
		this.changeUserArgs.email = this.pendingUser.membership.email;
		this.changeUserArgs.comment = this.pendingUser.membership.comment;
		this.changeUserArgs.mobilePin = this.pendingUser.membership.mobilePin;
		this.changeUserArgs.isApproved = this.pendingUser.membership.isApproved;
		this.changeUserArgs.isLockedOut = this.pendingUser.membership.isLockedOut;
	}

	private validatePendingUser(): boolean {
		let returnValue: boolean = true;

		this.errors = _.cloneDeep(this.def.errors);

		// Validate email
		if (this.changeUserArgs.email !== this.user.membership.email) {
			if (!this.changeUserArgs.email) {
				this.errors.email.push('Email Address is required');
				returnValue = false;
			} else if (this.changeUserArgs.email.length < 5 || this.changeUserArgs.email.length > 256) {
				this.errors.email.push('Email Address must be between 5 and 256 characters in length');
				returnValue = false;
			} else if (!HelperService.isValidEmailAddress(this.changeUserArgs.email)) {
				this.errors.email.push('Email Address is invalid');
				returnValue = false;
			}
		}

		// Validate Comment
		if (this.changeUserArgs.comment !== this.user.membership.comment) {
			if (this.changeUserArgs.comment && this.changeUserArgs.comment.length > 5000) {
				this.errors.comment.push('Comment cannot exceed 5000 characters in length');
				returnValue = false;
			}
		}

		// Validate Role
		if (this.changeUserArgs.roleId !== this.user.roleId) {
			if (!this.changeUserArgs.roleId) {
				this.errors.roleId.push('Role is required');
				returnValue = false;
			}
		}

		return returnValue;
	}
}
