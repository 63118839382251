<div class="global-numbers-edit-modal table-dark">
  <div class="modal-header">
    <h4 class="modal-title">Global Numbers Edit</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span style="color: ghostwhite;">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!isFacilityListLoaded" class="spinner-border text-info psi-spinner-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="globalNumbers.length > 0 && isFacilityListLoaded" class="container large-container results-container">
      <table class="table table-dark table-bordered table-sm">
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Location</th>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody class="test-table">
          <tr [ngClass]="{'selected': selectedGlobalNumber === globalNumber }" (click)="onRowClick(i)" *ngFor="let globalNumber of globalNumbers; let i = index">
              <td scope="row" class="overflow-ellipsis">{{ globalNumber.firstName }}</td>
              <td scope="row" class="overflow-ellipsis">{{ globalNumber.lastName }}</td>
              <td scope="row" class="overflow-ellipsis">{{ facilities[globalNumber.siteId] }}</td>
              <td scope="row" class="overflow-ellipsis">{{ globalNumber.description }}</td>
              <td *ngIf="hasPermission('Customer_Unflag_Fraud')" [disabled]="!isSaveAvailable()" scope="row">
                <span *ngIf="!globalNumbersConfirmations[i]" class="fas fa-trash clickable trashcan" (click)="onRemoveClick(i)"></span>
                <span *ngIf="globalNumbersConfirmations[i]" class="fas fa-arrow-left clickable" style="margin-right: 8px;" (click)="onDeclineClick(i)"></span>
                <span *ngIf="globalNumbersConfirmations[i]" class="fas fa-check clickable" (click)="onConfirmClick(i)"></span>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="selectedGlobalNumberIndex >= 0">
      <form>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstName">First Name</label>
            <input type="text" class="form-control" id="firstName" placeholder="{{globalNumbers[selectedGlobalNumberIndex].firstName}}" [disabled]="true">
          </div>
          <div class="form-group col-md-6">
            <label for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" placeholder="{{globalNumbers[selectedGlobalNumberIndex].lastName}}" [disabled]="true">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="input3">Phone #</label>
            <input type="text" class="form-control" id="input3" placeholder="{{globalNumbers[selectedGlobalNumberIndex].phone | phoneNumber}}" [disabled]="true">
          </div>
          <div class="form-group col-md-6">
            <label for="username">Username</label>
            <input type="text" class="form-control" id="username" placeholder="{{customer.userName}}" [disabled]="true">
          </div>
        </div>
        <div *ngIf="isGloballyRestricted(selectedGlobalNumberIndex)"class="form-row">
          <div class="form-group col-md-6">
            <label for="description">Flag Description</label>
            <textarea maxlength="20" minlength="1" name="description" type="text" [(ngModel)]="globalNumbersData[selectedGlobalNumberIndex].description" class="form-control col-12" style="display: inline-block;" required></textarea>
          </div>
          <div class="form-group col-md-6">
            <label for="notes">Flag Notes</label>
            <textarea name="notes" type="text" [(ngModel)]="globalNumbersData[selectedGlobalNumberIndex].notes" class="form-control col-12" maxlength="20" style="display: inline-block;"></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!isSaveAvailable()" (click)="onResetClick()" type="button" class="btn btn-primary">Reset</button>
    <button [disabled]="!isSaveAvailable()" (click)="onSaveClick()" type="button" class="btn btn-success">Save</button>
    <button type="button" class="btn btn-secondary" (click)="onClose()">Close</button>
  </div>
</div>
