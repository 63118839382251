import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOption } from './shared.service';

@Injectable({
	providedIn: 'root'
})
export class ProductRatesService {
	private readonly _apiUrl = `${environment.apiUrl}/ProductRates`;

	constructor(private http: HttpClient) { }


	public getProductRateTypes(): Observable<SelectOption[]> {
		return this.http.get<any>(`${this._apiUrl}/Types`);
	}

	public getProductRatesReport(args: GetProductRateReportArgs): Observable<ProductRateReportItem[]> {
		return this.http.post<any>(`${this._apiUrl}/Report`, args);
	}
}

export class GetProductRateReportArgs {
	public siteIdList: string[];
	public productTypeIdList: number[];
	public productRateTypeIdList: number[];
}

export class ProductRateReportItem {
	public id: number;
	public siteId: string;
	public facilityName: string;
	public productTypeId: number;
	public productTypeName: string;
	public productRateTypeId: number;
	public productRateTypeName: string;
	public rateCents: number;
	public commissionPercent: number;
	public rentedRate: boolean;
	public rateMinutes: number;
}