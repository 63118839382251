<ng-select
	[items]="inmates | async"
	(change)="emitChange()"
	bindLabel="display"
	[addTag]="true"
	[multiple]="allowMultiple"
	[hideSelected]="true" 
	[trackByFn]="trackInmatesByFn"
	[minTermLength]="3"
	[loading]="loadingInmatesTypeahead"
	typeToSearchText="Please enter 3 or more characters" 
	[typeahead]="inmateSearchInput"
	[(ngModel)]="selectedInmates"
	name="filterInmates"
	id="filterInmates" 
	class="col-sm-8 no-padding inline-block"
	ngDefaultControl>
</ng-select>