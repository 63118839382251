<div class="page-container">
    <h2 class="page-title">Orders Report</h2>

    <div class="container large-container filters-backdrop">
        <form class="filters-container form-inline">
            <div class="form-row col-sm-12">
                <div class="input-group col-md-6">
                    <label for="filterStartDate" class="col-form-label col-md-3 inline-block">Start</label>
                    <input type="text" placeholder="yyyy-mm-dd" [(ngModel)]="filters.startDate" name="filterStartDate" ngbDatepicker #sdp="ngbDatepicker" />
                    <button class="btn btn-primary" (click)="sdp.toggle()"><span class="far fa-calendar-alt"></span></button>
                </div>
                <div class="input-group col-md-6">
                    <label for="filterEndDate" class="col-form-label col-md-3 inline-block">End</label>
                    <input type="text" placeholder="yyyy-mm-dd" [(ngModel)]="filters.endDate" name="filterEndDate" ngbDatepicker #edp="ngbDatepicker" />
                    <button class="btn btn-primary" (click)="edp.toggle()"><span class="far fa-calendar-alt"></span></button>
                </div>
            </div>
            <div class="form-row col-sm-12">
                <div class="col-md-4 filter-field-container">
                    <label for="filtersSiteIdList" class="col-form-label col-md-4 inline-block">Facilities</label>
                    <ng-select [multiple]="true" name="filterSiteIdList" [(ngModel)]="filters.siteIdList" [loading]="loadingFacilities" [disabled]="loadingReport || loadingFacilities || !facilitiesLoaded" class="col-md-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let facility of filterOptions.facilities" [value]="facility.siteId">{{ facility.name }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filtersOutletIdList" class="col-form-label col-md-4 inline-block">Outlets</label>
                    <ng-select [multiple]="true" name="filtersOutletIdList" [(ngModel)]="filters.outletIdList" [loading]="loadingOutlets" [disabled]="loadingReport || loadingOutlets || !outletsLoaded" class="col-md-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let outlet of filterOptions.outlets" [value]="outlet.id">{{ outlet.name }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filtersDepositTypeIdList" class="col-form-label col-md-4 inline-block">Deposit Types</label>
                    <ng-select [multiple]="true" name="filtersDepositTypeIdList" [(ngModel)]="filters.depositTypeIdList" [loading]="loadingDepositTypes" [disabled]="loadingReport || loadingDepositTypes || !depositTypesLoaded" class="col-md-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let depositType of filterOptions.depositTypes" [value]="depositType.id">{{ depositType.name }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filterInmateName" class="col-form-label col-md-4 inline-block">Inmate Name</label>
                    <input [(ngModel)]="filters.inmateName" name="filterInmateName" id="filterInmateName" type="text" class="form-control full-width col-md-8" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filterCallerName" class="col-form-label col-md-4 inline-block">Caller Name</label>
                    <input [(ngModel)]="filters.callerName" name="filterCallerName" id="filterCallerName" type="text" class="form-control full-width col-md-8" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filterCallerEmail" class="col-form-label col-md-4 inline-block">Caller Email</label>
                    <input [(ngModel)]="filters.callerEmail" name="filterCallerEmail" id="filterCallerEmail" type="text" class="form-control full-width col-md-8" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filterCallerPhone" class="col-form-label col-md-4 inline-block">Caller Phone</label>
                    <input [(ngModel)]="filters.callerPhone" name="filterCallerPhone" id="filterCallerPhone" type="text" class="form-control full-width col-md-8" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filterUserName" class="col-form-label col-md-4 inline-block">Username</label>
                    <input [(ngModel)]="filters.userName" name="filterUserName" id="filterUserName" type="text" class="form-control full-width col-md-8" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filterTransactionId" class="col-form-label col-md-4 inline-block">Transaction ID</label>
                    <input [(ngModel)]="filters.transactionId" name="filterTransactionId" id="filterTransactionId" type="text" class="form-control full-width col-md-8" />
                </div>
            </div>

        </form>
        <div class="filters-footer">
            <button (click)="loadReport()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-search"></span> Search</button>
            <button (click)="loadExcelReport()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-file"></span> Excel</button>
            <button (click)="reset()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>

    <div class="container large-container results-container" *ngIf="hasPermission('Reports_Orders_Summary_View') && (reportLoaded || loadingReport)">
        <table *ngIf="!loadingReport && reportItems && reportItems.length > 0" class="table table-dark table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col">Cash</th>
                    <th scope="col">Credit Cards</th>
                    <th scope="col">Interface</th>
                    <th scope="col">Total</th>
                    <th scope="col">Bill Count</th>
                    <th scope="col"># of Transactions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="row">Deposits: {{ summary.cashDeposits | currency }}</td>
                    <td scope="row">Deposits: {{ summary.creditCardDeposits | currency }}</td>
                    <td scope="row">Deposits: {{ summary.interfaceDeposits | currency }}</td>
                    <td scope="row">{{ summary.total | currency }}</td>
                    <td scope="row">{{ summary.billCount }}</td>
                    <td scope="row">{{ summary.numberOfTransactions }}</td>
                </tr>
                <tr>
                    <td scope="row">Fees: {{ summary.cashTotalFees | currency }}</td>
                    <td scope="row">Fees: {{ summary.creditCardTotalFees | currency }}</td>
                    <td scope="row">Fees: {{ summary.interfaceTotalFees | currency }}</td>
                    <td scope="row"></td>
                    <td scope="row"></td>
                    <td scope="row"></td>
                </tr>
                <tr>
                    <td scope="row">Total: {{ summary.cashTotal | currency }}</td>
                    <td scope="row">Total: {{ summary.creditCardTotal | currency }}</td>
                    <td scope="row">Total: {{ summary.interfaceTotal | currency }}</td>
                    <td scope="row"></td>
                    <td scope="row"></td>
                    <td scope="row"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="container large-container results-container" *ngIf="reportLoaded || loadingReport">
        <table class="table table-dark table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" class="sortable headerSortDown" (click)="sortTable($event, 'DepositedOn')">Date</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'FacilityName')">Facility</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'DepositType')">Deposit Type</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'AccountPhone')">Phone</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'Amount')">Amount</th>
                    <th scope="col">Total <span class="fas fa-question-circle" title="Amount + Handling Fee + Credit Card Fee + Surcharge"></span></th>
                    <th scope="col" *ngIf="hasPermission('Payments_Refund')">Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="reportItems && reportItems.length > 0">
                <ng-container *ngFor="let item of reportItems; let i = index">
                    <tr [ngClass]="{'odd': (i % 2 == 0) && !item.isFlagged, 'flagged': item.isFlagged}">
                        <td scope="row" (click)="toggleDetails(item)">
                            <span class="clickable fas" [ngClass]="{'fa-minus': item.showDetails, 'fa-plus': !item.showDetails}"></span>
                        </td>
                        <td scope="row">{{ item.depositedOn | date: 'yyyy-MM-dd @ HH:mm' }}</td>
                        <td scope="row">{{ item.facilityName }}</td>
                        <td scope="row">{{ item.depositType }}</td>
                        <!-- <td scope="row">{{ item.accountPhone | phoneNumber }}</td> -->
                        <td scope="row">
                            <customer-phone [phoneNumber]="item.accountPhone"></customer-phone>
                        </td>
                        <td scope="row">
                            <span *ngIf="!item.refundState || item.refundState === 0">{{ item.amount | currency }}</span>
                            <span *ngIf="item.refundState && item.refundState === 1" class="color-orange" title="Order has been partially refunded">{{ item.amount | currency }}</span>
                            <span *ngIf="item.refundState && item.refundState === 2" class="color-red" title="Order has been fully refunded">{{ item.amount | currency }}</span>
                        </td>
                        <td scope="row">{{ item.amount + item.handlingFee + item.creditCardFee + item.surcharge | currency }}</td>
                        <span *ngIf="hasPermission('Payments_Refund') && item.refundState && item.refundState === 2" class="fas fa-undo-alt color-red modal-button" title="Order has been fully refunded"></span>
                        <div *ngIf="item.refundingPayment" class="spinner-border text-info psi-spinner-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <psi-modal *ngIf="hasPermission('Payments_Refund') && (!item.refundState || item.refundState === 0 || item.refundState === 1) && !item.refundingPayment" [settings]="item.refundPaymentModalSettings" (confirmBtnClick)="confirmRefundBtnClick(item)" (cancelBtnClick)="cancelRefundBtnClick(item)" (openClick)="getRefundAmount(item)" [disableConfirmBtn]="item.refundingPayment" [disableCancelBtn]="item.refundingPayment" class="modal-button">
                            <div *ngIf="!item.refundingPayment">
                                <div class="col-12" *ngIf="item.loadingRefundedAmount">
                                    <div class="spinner-border text-info psi-spinner-lg" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="!item.loadingRefundedAmount">Max Refund Amount: {{ (item.amount-item.refundedAmount) | currency }}</div>
                                <form class="col-12 text-center">
                                    <div class="form-row">
                                        <label for="refundAmount" class="col-form-label col-sm-5 inline-block">Refund Amount ($)</label>
                                        <div class="col-sm-6 inline-block">
                                            <input name="refundAmount" id="refundAmount" type="number" min="0.01" step="0.01" max="1000" class="form-control col-sm-12 inline-block" [(ngModel)]="refundArgs.amount" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="item.refundingPayment" class="spinner-border text-info psi-spinner-lg" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </psi-modal>

                        <psi-modal *ngIf="hasPermission('Payments_Flag') && !item.isFlagged" [settings]="item.flagPaymentModalSettings" (confirmBtnClick)="flagPayment(item)" [disableConfirmBtn]="item.flagging" [disableCancelBtn]="item.flagging" class="modal-button">
                          <div *ngIf="!item.flagging">
                              <p>Are you sure you want to flag this order?</p>
                          </div>
                          <div *ngIf="item.flagging" class="spinner-border text-info psi-spinner-lg" role="status">
                                <span class="sr-only">Flagging...</span>
                            </div>
                        </psi-modal>

                        <psi-modal *ngIf="hasPermission('Payments_Flag') && item.isFlagged" [settings]="item.unflagPaymentModalSettings" (confirmBtnClick)="unflagPayment(item)" [disableConfirmBtn]="item.unflagging" [disableCancelBtn]="item.unflagging" class="modal-button">
                          <div *ngIf="!item.unflagging">
                            <p>Are you sure you want to unflag this order?</p>
                          </div>
                          <div *ngIf="item.unflagging" class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Unflagging...</span>
                          </div>
                        </psi-modal>
                    </tr>
                    <tr *ngIf="item.showDetails" [ngClass]="{'odd': (i % 2 == 0) && !item.isFlagged, 'flagged': item.isFlagged}">
                        <td colspan="15" class="col-sm-12 order-report-row-details">
                            <div class="col-md-6">
                                <div class="bold">Prodigy Transaction ID:</div>
                                <div>&nbsp;&nbsp;{{ item.prodigyTransactionId }}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="bold">Processor Transaction ID:</div>
                                <div>&nbsp;&nbsp;{{ item.creditCardProcessorTransactionId }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Inmate Name:</div>
                                <div>&nbsp;&nbsp;{{ item.inmateName }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Outlet:</div>
                                <div>&nbsp;&nbsp;{{ item.outlet }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Purchase Method:</div>
                                <div>&nbsp;&nbsp;{{ item.purchaseMethod }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Customer Name:</div>
                                <div>&nbsp;&nbsp;{{ item.customerFirstName }} {{ item.customerLastName }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Customer Phone #:</div>
                                <div>&nbsp;&nbsp;{{ item.customerPhoneNumber | phoneNumber }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Customer Email:</div>
                                <div>&nbsp;&nbsp;{{ item.customerEmail }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Handling Fee:</div>
                                <div>&nbsp;&nbsp;{{ item.handlingFee | currency }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Credit Card Fee:</div>
                                <div>&nbsp;&nbsp;{{ item.creditCardFee | currency }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Surcharge:</div>
                                <div>&nbsp;&nbsp;{{ item.surcharge | currency }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Username:</div>
                                <div>&nbsp;&nbsp;{{ item.userName }}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="bold">Refunded ($):</div>
                                <div *ngIf="item.loadingRefundedAmount">
                                    <span>&nbsp;&nbsp;Loading...</span>
                                </div>
                                <div *ngIf="!item.loadingRefundedAmount">
                                    <span>&nbsp;&nbsp;{{ item.refundedAmount | currency }}</span>
                                </div>
                                <!-- <div *ngIf="item.loadingRefundedAmount" class="spinner-border text-info psi-spinner-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div> -->
                                <!-- <div *ngIf="!item.loadingRefundedAmount">&nbsp;&nbsp;{{ item.refundedAmount | currency }}</div> -->
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="!loadingReport && reportItems && reportItems.length === 0">
                <tr>
                    <td colspan="12">No Results Found</td>
                </tr>
            </tbody>
            <tbody *ngIf="loadingReport" class="text-center">
                <tr>
                    <td colspan="12">
                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="!loadingReport && reportItems && reportItems.length > 0" class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadReport()" [boundaryLinks]="true" [maxSize]="7"></ngb-pagination>
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadReport()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>
