import { HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../services/token-storage.service';
import { Observable } from 'rxjs';

import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { HelperService } from './helper.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private readonly _tokenHeaderKey = 'Authorization';


	constructor(
		private _tokenStorage: TokenStorageService,
		private _router: Router
	) { }


	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authReq = req;
		const token = this._tokenStorage.getToken();
		if (token != null) {
			authReq = req.clone({ headers: req.headers.set(this._tokenHeaderKey, `Bearer ${token}`) });
		}

		return next.handle(authReq).pipe(map(event => {
			if (event instanceof HttpResponse) {
				// Hacky way of handling redirect request being sent from back-end
				if (event.body === HelperService.redirectBodyValue) {
					this._tokenStorage.signOut();
					//window.location.reload();
					this._router.navigate(["/login"]);
					return null;
				}
			}
			return event;
		}));
	}
}

export const authInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];