<div class="page-container">
    <h2 class="page-title">Payments</h2>

    <div *ngIf="pageLoading()" class="text-center">
        <div class="spinner-border text-info psi-spinner-lg" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="container large-container filters-backdrop col-12" *ngIf="!pageLoading()">
        <div class="col-12 container-fluid" style="z-index: 1;" *ngIf="processSteps && processSteps.length > 0">
            <br />
            <ul class="list-unstyled step-progressbar">
                <li *ngFor="let processStep of processSteps; let i = index" [ngClass]="{'is-active': processStep.active}">{{ processStep.name }}</li>
            </ul>
            <br />

            <div *ngIf="loadingPurchaseInfo" class="text-center">
                <div class="spinner-border text-info psi-spinner-lg" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="!loadingPurchaseInfo">
                <div *ngIf="currentStep === 0">
                    <div class="col-6 col-lg-4 filter-field-container">
                        <label for="filterProductType" class="inline-block">Please select a payment type:</label>
                        <ng-select [items]="filterOptions.productTypes" bindLabel="name" bindValue="id" name="filterProductType" [(ngModel)]="paymentArgs.productType" class="form-control full-width col-12 no-padding" ngDefaultControl></ng-select>
                    </div>
                </div>
                <div *ngIf="currentStep === 1">
                    <div class="col-12">
                        Please select a Facility for the deposit
                    </div>
                    <br />
                    <div class="form-row col-12">
                        <div class="col-12 col-md-6 col-lg-4 filter-field-container">
                            <label for="filterState" class="col-form-label col-lg-4 inline-block">State</label>
                            <ng-select [items]="filterOptions.facilityStates" bindLabel="name" bindValue="abbreviation" (change)="loadFacilities()" name="filterState" [(ngModel)]="filters.state" class="col-lg-8 no-padding inline-block" [disabled]="loadingFacilityStates || loadingFacilities" [loading]="loadingFacilityStates || loadingFacilities" ngDefaultControl></ng-select>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 filter-field-container" *ngIf="filters.state">
                            <label for="filterState" class="col-form-label col-lg-4 inline-block">Facility</label>
                            <ng-select [items]="filterOptions.facilities" bindLabel="name" bindValue="siteId" name="filterState" [(ngModel)]="paymentArgs.siteId" class="col-lg-8 no-padding inline-block" [disabled]="loadingFacilityStates || loadingFacilities" [loading]="loadingFacilityStates || loadingFacilities" ngDefaultControl></ng-select>
                        </div>
                        <div class="col-4"></div>
                    </div>
                </div>
                <div *ngIf="currentStep === 2">
                    <div class="form-row col-12" *ngIf="paymentArgs.productType === 1">
                        <div class="col-12 col-lg-4 filter-field-container">
                            <label for="filterPhoneNumber" class="inline-block">Please enter the phone number of the customer account to deposit money into:</label>
                            <input [(ngModel)]="filters.customerPhoneNumber" (ngModelChange)="searchCustomer()" [disabled]="loadingCustomer" name="filterPhoneNumber" id="filterPhoneNumber" type="text" class="form-control full-width col-12" />
                        </div>
                        <div class="col-12 col-lg-4 filter-field-container" style="padding-left: 15px;">
                            <div *ngIf="loadingCustomer && !customerInfo" class="text-center">
                                <div class="spinner-border text-info psi-spinner-lg" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!loadingCustomer && customerInfo">
                                <span class="color-brand-blue bold">Customer Information:</span>
                                <br />
                                <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ customerInfo.firstName }} {{ customerInfo.lastName }}
                                <br />
                                <span class="bold">Phone Number:</span>&nbsp;&nbsp;&nbsp;{{ customerInfo.phoneNumber | phoneNumber }}
                                <br />
                                <span class="bold">Email:</span>&nbsp;&nbsp;&nbsp;{{ customerInfo.email }}
                                <br />
                                <span class="bold"><span class="fas fa-question-circle" title="Used to verify a customer's account"></span> Account PIN:</span>&nbsp;&nbsp;&nbsp;{{ customerInfo.accountPin }}
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 filter-field-container" *ngIf="customerInfo && customerInfo.customerAccounts" style="padding-left: 15px;">
                            <span class="color-brand-blue bold">Select an account:</span>
                            <br />
                            <table class="table table-dark table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Account Type</th>
                                        <th scope="col">Balance</th>
                                        <th scope="col">Reserve</th>
                                        <th scope="col">Active</th>
                                        <th scope="col">Primary</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="customerInfo && customerInfo.customerAccounts && customerInfo.customerAccounts.length > 0">
                                    <ng-container *ngFor="let customerAccount of customerInfo.customerAccounts; let i = index">
                                        <tr class="clickable" [ngClass]="{'row-selected': customerAccount.selected}" (click)="selectCustomerAccount(customerAccount.id)">
                                            <td scope="row">{{ customerAccount.account.accountType.description }}</td>
                                            <td scope="row">{{ customerAccount.account.balance | currency }}</td>
                                            <td scope="row">{{ customerAccount.account.reserve | currency }}</td>
                                            <td scope="row" [ngClass]="{'color-green': customerAccount.account.active, 'color-red': !customerAccount.account.active}">{{ customerAccount.account.active | boolYesNo }}</td>
                                            <td scope="row" [ngClass]="{'color-green': customerAccount.account.primary, 'color-red': !customerAccount.account.primary}">{{ customerAccount.account.primary | boolYesNo }}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="paymentArgs.productType === 2">
                        <!-- Input customer info -->
                        <div class="col-12 col-lg-4 inline-block">
                            <div class="col-12 filter-field-container">
                                <label for="filtersPinDebitPhoneNumber" class="inline-block">Please enter the phone number of the customer making the deposit:</label>
                                <input [(ngModel)]="filters.pinDebitPhoneNumber" (ngModelChange)="searchPinDebitCustomer()" [disabled]="loadingPinDebitCustomer || processingPayment" name="filtersPinDebitPhoneNumber" id="filtersPinDebitPhoneNumber" type="text" class="form-control full-width col-12" />
                            </div>
                            <br />
                            <div class="col-12 inline-block" *ngIf="!loadingPinDebitCustomer && pinDebitCustomerLoaded && !pinDebitCustomer">
                                <span>Customer must have an account to make a PIN Debit deposit!</span>
                                <br />
                                <div *ngIf="hasPermission('Customers_Create')">
                                    <a href="javascript:void(0)" (click)="redirectToCreateCustomer()" target="_blank" class="clickable">Create Customer Account</a>
                                    <br />
                                    <span>Once the customer account is created, re-type the phone number to load the new account information</span>
                                </div>
                                <span *ngIf="!hasPermission('Customers_Create')">Please have the customer visit prodigysales.com and create an account.</span>
                            </div>
                            <div class="col-12 inline-block" *ngIf="!loadingPinDebitCustomer && pinDebitCustomerLoaded && pinDebitCustomer">
                                <span class="color-brand-blue bold">Customer Information:</span>
                                <br />
                                <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ pinDebitCustomer.firstName }} {{ pinDebitCustomer.lastName }}
                                <br />
                                <span class="bold">Phone Number:</span>&nbsp;&nbsp;&nbsp;{{ pinDebitCustomer.phoneNumber | phoneNumber }}
                                <br />
                                <span class="bold">Email:</span>&nbsp;&nbsp;&nbsp;{{ pinDebitCustomer.email }}
                                <br />
                                <span class="bold"><span class="fas fa-question-circle" title="Used to verify a customer's account"></span> Account PIN:</span>&nbsp;&nbsp;&nbsp;{{ pinDebitCustomer.accountPin }}
                            </div>
                        </div>
                        <!-- Select inmate -->
                        <div class="col-12 col-lg-4 inline-block">
                            <div class="col-12 filter-field-container inline-block">
                                <label for="filterInmates" class="col-form-label col-12 col-lg-4 inline-block">Inmate</label>
                                <inmates-typeahead [(selectedInmates)]="inmateTypeaheadFilters.selectedInmate" [allowMultiple]="false" [siteIdList]="inmateTypeaheadFilters.siteIdList" [activeOnly]="true"></inmates-typeahead>
                            </div>
                            <div class="col-12 filter-field-container inline-block" *ngIf="inmateTypeaheadFilters.selectedInmate && inmateTypeaheadFilters.selectedInmate.fullName" style="padding-left: 15px;">
                                <span class="color-brand-blue bold">Selected Inmate Information:</span>
                                <br />
                                <span class="bold">Name:</span>&nbsp;&nbsp;&nbsp;{{ inmateTypeaheadFilters.selectedInmate.fullName }}
                                <br />
                                <span class="bold">DOB:</span>&nbsp;&nbsp;&nbsp;{{ inmateTypeaheadFilters.selectedInmate.dateOfBirthDisplay | phoneNumber }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentStep === 3">
                    <div class="form-row col-12">
                        <div class="col-lg-6">
                            <fieldset class="col-12 form-section">
                                <legend class="col-12">Credit Card Info</legend>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsCreditCardType" class="col-form-label col-lg-4 inline-block">Card Type</label>
                                    <ng-select [items]="filterOptions.creditCardTypes" bindLabel="name" bindValue="id" name="paymentArgsCreditCardType" [(ngModel)]="paymentArgs.creditCardType" class="col-lg-8 no-padding inline-block" [disabled]="processingPayment" [loading]="processingPayment" ngDefaultControl></ng-select>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsNameOnCard" class="col-form-label col-lg-4 inline-block">Name On Card</label>
                                    <input [(ngModel)]="paymentArgs.nameOnCard" name="paymentArgsNameOnCard" id="paymentArgsNameOnCard" (input)="resetErrors('nameOnCard')" type="text" class="col-8 form-control inline-block" [disabled]="processingPayment" />
                                    <div *ngIf="errors.nameOnCard && errors.nameOnCard.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.nameOnCard; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsCreditCardNumber" class="col-form-label col-lg-4 inline-block">Credit Card Number</label>
                                    <input [(ngModel)]="paymentArgs.creditCardNumber" name="paymentArgsCreditCardNumber" id="paymentArgsCreditCardNumber" (input)="resetErrors('creditCardNumber')" type="text" class="col-8 form-control inline-block" [disabled]="processingPayment" />
                                    <div *ngIf="errors.creditCardNumber && errors.creditCardNumber.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.creditCardNumber; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsCardExpirationMonth" class="col-form-label col-lg-4 inline-block">Card Expiration</label>
                                    <input [(ngModel)]="paymentArgs.cardExpirationMonth" name="paymentArgsCardExpirationMonth" id="paymentArgsCardExpirationMonth" (input)="resetErrors('cardExpiration')" type="text" class="col-2 col-lg-2 form-control inline-block" [disabled]="processingPayment" />
                                    <span class="col-1">/</span>
                                    <input [(ngModel)]="paymentArgs.cardExpirationYear" name="paymentArgsCardExpirationYear" id="paymentArgsCardExpirationYear" (input)="resetErrors('cardExpiration')" type="text" class="col-2 col-lg-2 form-control inline-block" [disabled]="processingPayment" />
                                    <span class="col-4">ex: 09 / 2023</span>
                                    <div *ngIf="errors.cardExpiration && errors.cardExpiration.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.cardExpiration; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container" style="margin-bottom: 10px;">
                                    <label for="paymentArgsSecurityCode" class="col-form-label col-lg-4 inline-block">Security Code</label>
                                    <input [(ngModel)]="paymentArgs.creditCardSecurityCode" name="paymentArgsSecurityCode" id="paymentArgsSecurityCode" (input)="resetErrors('securityCode')" type="text" class="col-2 form-control inline-block" [disabled]="processingPayment" />
                                    <div *ngIf="errors.securityCode && errors.securityCode.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.securityCode; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-lg-6">
                            <fieldset class="col-12 form-section">
                                <legend class="col-12">Billing Info</legend>
                                <div class="col-12 filter-field-container" *ngIf="paymentArgs.productType === 1 || (paymentArgs.productType === 2 && pinDebitCustomer)">
                                    <div class="col-6 checkbox inline-block">
                                        <input type="checkbox" [(ngModel)]="useCustomerAddress" (change)="useCustomerAddressChange()" class="form-check-input col-2" id="useCustomerAddressCheckbox" name="useCustomerAddressCheckbox" title="Use the address affiliated with the customer's Prodigy Sales account" />
                                        <label for="useCustomerAddressCheckbox" class="form-check-label col-10" title="Use the address affiliated with the customer's Prodigy Sales account"><span>Use Customer Address</span></label>
                                    </div>
                                    <div class="col-6"></div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsStreetAddress1" class="col-form-label col-lg-4 inline-block">Street Address 1</label>
                                    <input [(ngModel)]="paymentArgs.streetAddress1" name="paymentArgsStreetAddress1" id="paymentArgsStreetAddress1" (input)="resetErrors('streetAddress1')" type="text" class="col-8 form-control inline-block" [disabled]="processingPayment || useCustomerAddress" />
                                    <div *ngIf="errors.streetAddress1 && errors.streetAddress1.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.streetAddress1; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsStreetAddress2" class="col-form-label col-lg-4 inline-block">Street Address 2</label>
                                    <input [(ngModel)]="paymentArgs.streetAddress2" name="paymentArgsStreetAddress2" id="paymentArgsStreetAddress2" (input)="resetErrors('streetAddress2')" type="text" class="col-8 form-control inline-block" [disabled]="processingPayment || useCustomerAddress" />
                                    <div *ngIf="errors.streetAddress2 && errors.streetAddress2.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.streetAddress2; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsCity" class="col-form-label col-lg-4 inline-block">City</label>
                                    <input [(ngModel)]="paymentArgs.city" name="paymentArgsCity" id="paymentArgsCity" (input)="resetErrors('city')" type="text" class="col-8 col-lg-4 form-control inline-block" [disabled]="processingPayment || useCustomerAddress" />
                                    <div *ngIf="errors.city && errors.city.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.city; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsState" class="col-form-label col-lg-4 inline-block">State</label>
                                    <ng-select [items]="filterOptions.states" bindLabel="name" bindValue="abbreviation" name="paymentArgsState" [(ngModel)]="paymentArgs.state" (change)="resetErrors('state')" class="col-8 col-lg-4 form-control full-width no-padding inline-block" [ngClass]="{'disabled': !customerEditable}" [disabled]="processingPayment || useCustomerAddress" ngDefaultControl></ng-select>
                                    <div *ngIf="errors.state && errors.state.length > 0" class="psi-alert psi-alert-danger">
                                        <div *ngFor="let err of errors.state; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsZipCode" class="col-form-label col-lg-4 inline-block">Zip Code</label>
                                    <input [(ngModel)]="paymentArgs.zipCode" name="paymentArgsZipCode" id="paymentArgsZipCode" (input)="resetErrors('zipCode')" type="text" class="col-8 col-sm-4 col-lg-2 form-control inline-block" [disabled]="processingPayment || useCustomerAddress" />
                                    <div *ngIf="errors.zipCode && errors.zipCode.length > 0" class="psi-alert psi-alert-danger col-8 col-sm-4 col-lg-2 inline-block">
                                        <div *ngFor="let err of errors.zipCode; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="paymentArgsPaymentAmount" class="col-form-label col-lg-4 inline-block">Amount ($)</label>
                                    <input [(ngModel)]="paymentArgs.paymentAmount" (ngModelChange)="paymentAmountChanged()" name="paymentArgsPaymentAmount" id="paymentArgsPaymentAmount" (input)="resetErrors('paymentAmount')" type="text" class="col-8 col-sm-4 col-lg-2 inline-block form-control" [disabled]="processingPayment" />
                                    <div *ngIf="errors.paymentAmount && errors.paymentAmount.length > 0" class="psi-alert psi-alert-danger col-12 col-lg-8 offset-lg-4 inline-block">
                                        <div *ngFor="let err of errors.paymentAmount; let i = index">
                                            {{ err }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 filter-field-container">
                                    <label for="purchaseFees" class="col-form-label col-lg-4 inline-block">Taxes/Fees</label>
                                    <span name="purchaseFees" id="purchaseFees" class="col-8 col-sm-4 col-lg-2 form-control inline-block">{{ feeAmount | currency }}</span>
                                </div>
                                <div class="col-12 filter-field-container" style="margin-bottom: 10px;">
                                    <label for="purchaseTotal" class="col-form-label col-lg-4 inline-block">Total Charge</label>
                                    <span name="purchaseTotal" id="purchaseTotal" class="col-8 col-sm-4 col-lg-2 form-control inline-block">{{ totalAmount | currency }}</span>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentStep === processSteps.length">
                    Payment Complete
                </div>
            </div>
        </div>

        <div class="filters-footer">
            <button (click)="submit()" type="button" class="btn btn-success float-right report-button" *ngIf="currentStep === (processSteps.length - 1)"><span class="fas fa-arrow-right"></span> Submit</button>
            <button (click)="nextStep()" type="button" class="btn btn-primary float-right report-button" *ngIf="currentStep < (processSteps.length - 1)"><span class="fas fa-arrow-right"></span> Next</button>
            <button (click)="prevStep()" type="button" class="btn btn-primary float-right report-button" *ngIf="currentStep > 0 && currentStep < processSteps.length"><span class="fas fa-arrow-left"></span> Back</button>
        </div>
    </div>
</div>