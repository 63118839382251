<ng-select
	[items]="inmateAccounts | async"
	(change)="emitChange()"
	bindLabel="display"
	[addTag]="true"
	[multiple]="allowMultiple"
	[hideSelected]="true" 
	[trackByFn]="trackInmateAccountsByFn"
	[minTermLength]="3"
	[loading]="loadingInmateAccountsTypeahead"
	typeToSearchText="Please enter 3 or more characters" 
	[typeahead]="inmateAccountSearchInput"
	[(ngModel)]="selectedInmateAccounts"
	name="filterInmateAccounts"
	id="filterInmateAccounts" 
	class="col-sm-8 no-padding inline-block"
	ngDefaultControl>
</ng-select>