import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var $: any;

@Component({
	selector: 'psi-modal',
	templateUrl: './psi-modal.component.html'
})
export class PsiModalComponent implements OnInit {
	@Input() settings: PsiModalSettings;
	@Input() disableConfirmBtn: any;
	@Input() disableCancelBtn: any;
	@Input() disableAction: any = false;
	@Output('openClick') openClick: EventEmitter<any> = new EventEmitter();
	@Output('confirmBtnClick') confirmBtnClick: EventEmitter<any> = new EventEmitter();
	@Output('cancelBtnClick') cancelBtnClick: EventEmitter<any> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {

	}

	public openModalClick(): void {
		this.openClick.emit();
	}

	public closeClick(): void {
		this.cancelBtnClick.emit();
	}

	public confirmClick(): boolean {
		this.confirmBtnClick.emit();
		if (this.settings.closeOnConfirm) {
			$(`#psiModal${this.settings.uniqueId}`).modal('hide');
			return true;
		} else {
			return false;
		}
	}
}

export class PsiModalSettings {
	public openModalButtonText: string;
	public title: string;
	public spanClass: string;
	public btnClass?: string;
	public confirmBtnText: string;
	public closeBtnText: string;
	public openerType: 'button' | 'icon';
	public uniqueId: number;
	public closeOnConfirm: boolean;
}
