<div *ngIf="settings.uniqueId && settings.uniqueId > 0" class="modal-opener">
    <button *ngIf="settings.openerType === 'button'" type="button" (click)="openModalClick()" [ngClass]="settings.btnClass" data-toggle="modal" [attr.data-target]="'#psiModal' + settings.uniqueId" data-backdrop="static" data-keyboard="false" [title]="settings.openModalButtonText" [disabled]="disableAction">
        <span [ngClass]="settings.spanClass"></span> {{ settings.openModalButtonText }}
    </button>
    <span *ngIf="settings.openerType === 'icon'" (click)="openModalClick()" [ngClass]="settings.spanClass" data-toggle="modal" [attr.data-target]="'#psiModal' + settings.uniqueId" data-backdrop="static" data-keyboard="false" [title]="settings.openModalButtonText" [disabled]="disableAction"></span>

    <div class="modal fade modal-container" [attr.id]="'psiModal' + settings.uniqueId" tabindex="-1" role="dialog" aria-labelledby="psiModalTitle" aria-hidden="true">
        <div class="modal-dialog" [ngClass]="settings.modalSize" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="psiModalTitle">{{ settings.title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeClick()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body align-center">
                    <ng-content></ng-content>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" aria-label="Confirm" (click)="confirmClick()" [disabled]="disableConfirmBtn">{{ settings.confirmBtnText }}</button>
                    <button type="button" class="btn btn-warning" data-dismiss="modal" aria-label="Close" (click)="closeClick()" [disabled]="disableCancelBtn">{{ settings.closeBtnText }}</button>
                </div>
            </div>
        </div>
    </div>
</div>