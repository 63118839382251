import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsState } from '../helpers/helper.service';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	private readonly _apiUrl = `${environment.apiUrl}/Shared`;

	constructor(private http: HttpClient) { }


	public getDepositTypes(): Observable<SelectOption[]> {
		return this.http.get<any>(`${this._apiUrl}/DepositTypes`);
	}

  public getFacilities(): Observable<FacilityOption[]> {
    //const url = `${this._apiUrl}/Facilities`;
    //console.log('TESTING URL WOOO', url);
		return this.http.get<any>(`${this._apiUrl}/Facilities`);
	}

	public getFacilitiesByState(state: string): Observable<FacilityOption[]> {
		return this.http.get<any>(`${this._apiUrl}/FacilitiesByState?state=${state}`);
	}

	public getFacilityStates(): Observable<UsState[]> {
		return this.http.get<any>(`${this._apiUrl}/FacilityStates`);
	}

	public getOutlets(): Observable<SelectOption[]> {
		return this.http.get<any>(`${this._apiUrl}/Outlets`);
	}

	public getProdigyFeatures(): Observable<SelectOption[]> {
		return this.http.get<any>(`${this._apiUrl}/ProdigyFeatures`);
	}

	public getProductTypes(): Observable<SelectOption[]> {
		return this.http.get<any>(`${this._apiUrl}/ProductTypes`);
	}

	public getRoles(): Observable<SelectOptionGuid[]> {
		return this.http.get<any>(`${this._apiUrl}/Roles`);
	}

	public getUserActionTypes(): Observable<SelectOption[]> {
		return this.http.get<any>(`${this._apiUrl}/UserActionTypes`);
	}
}

export class FacilityOption {
	public name: string;
	public siteId: string;
}

export class SelectOption {
	public id: number;
	public name: string;
}

export class SelectOptionGuid {
	public id: string;
	public name: string;
}
