import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { CreateCustomerComponent } from './customers/create-customer/create-customer.component';
import { EditCustomerComponent } from './customers/edit-customer/edit-customer.component';
import { SearchCustomersComponent } from './customers/search-customers/search-customers.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { OrdersReportComponent } from './orders-report/orders-report.component';
import { PaymentProcessingComponent } from './payments/payment-processing/payment-processing.component';
import { SendFundsComponent } from './payments/send-funds/send-funds.component';
import { ProductRatesComponent } from './product-rates/product-rates.component';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { SearchUsersComponent } from './users/search-users/search-users.component';

const routes: Routes = [
	{ path: 'account/change-password', component: ChangePasswordComponent },
	{ path: 'customers/create', component: CreateCustomerComponent },
	{ path: 'customers/edit/:id', component: EditCustomerComponent },
	{ path: 'customers/search', component: SearchCustomersComponent },
	{ path: 'orders-report', component: OrdersReportComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'payments/payment-processing', component: PaymentProcessingComponent },
	{ path: 'payments/send-funds', component: SendFundsComponent },
	{ path: 'product-rates', component: ProductRatesComponent },
	{ path: 'user-actions', component: UserActionsComponent },
	{ path: 'users/create', component: CreateUserComponent },
	{ path: 'users/edit/:userid', component: EditUserComponent },
	{ path: 'users/search', component: SearchUsersComponent },
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: '*', redirectTo: 'login' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
