import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { HelperService, UsState } from '../../helpers/helper.service';
import { GetPaymentInfoArgs, PaymentsService, ProcessPaymentArgs, PurchaseInfo, PurchaseResult } from '../../services/payments.service';
import * as _ from 'lodash';
import { FacilityOption, SharedService } from '../../services/shared.service';
import { Customer, CustomersService } from '../../services/customers.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-payment-processing',
	templateUrl: './payment-processing.component.html'
})
export class PaymentProcessingComponent implements OnInit {

	private def: any = {
		errors: {
			nameOnCard: [],
			creditCardNumber: [],
			cardExpiration: [],
			securityCode: [],
			streetAddress1: [],
			streetAddress2: [],
			pinDebitCustomer: []
		},
		filterOptions: {
			creditCardTypes: [
				{ id: 0, name: 'Visa' },
				{ id: 1, name: 'MasterCard' },
				{ id: 2, name: 'Discover' }
				// { id: 3, name: 'American Express' }
			],
			productTypes: [
				{ id: 1, name: 'Prepaid Collect' },
				{ id: 2, name: 'PIN Debit' }
			],
			facilityStates: [],
			states: HelperService.getUsStateList()
		},
		filters: {
			customerPhoneNumber: null,
			pinDebitPhoneNumber: null,
			state: null
		},
		paymentArgs: {
			customerId: 0,
			creditCardType: 0,
			creditCardNumber: null,
			creditCardSecurityCode: null,
			nameOnCard: null,
			streetAddress1: null,
			streetAddress2: null,
			acceptsTerms: false,
			countryId: 1,
			city: null,
			state: null,
			zipCode: null,
			cardExpirationMonth: null,
			cardExpirationYear: null,
			paymentAmount: 0,
			inmateId: 0,
			siteId: null,
			productType: 0,
			accountId: 0
		},
		purchaseInfo: {
			flatFee: 0,
			percentFee: 0
		}
	};

	public errors: any = _.cloneDeep(this.def.errors);
	public filterOptions: any = _.cloneDeep(this.def.filterOptions);
	public filters: any = _.cloneDeep(this.def.filters);
	public paymentArgs: ProcessPaymentArgs = _.cloneDeep(this.def.paymentArgs);
	public customerInfo: Customer = null;
	public pinDebitCustomer: Customer = null;
	public purchaseInfo: PurchaseInfo = null;
	public purchaseResult: PurchaseResult = null;
	public useCustomerAddress: boolean = false;

	public currentStep: number = 0;
	public feeAmount: number = 0;
	public totalAmount: number = 0;
	public processSteps: any = [
		{ name: 'Deposit Type', active: true },
		{ name: 'Facility', active: false },
		{ name: 'Customer / Inmate', active: false },
		{ name: 'Payment Information', active: false }
	];

	public loadingCustomer: boolean = false;
	public loadingPinDebitCustomer: boolean = false;
	public loadingFacilityStates: boolean = false;
	public loadingFacilities: boolean = false;
	public loadingPurchaseInfo: boolean = false;
	public pinDebitCustomerLoaded: boolean = false;
	public processingPayment: boolean = false;

	public inmateTypeaheadFilters = {
		selectedInmate: null,
		siteIdList: []
	};


	constructor(
		private readonly _customersService: CustomersService,
		private readonly _paymentsService: PaymentsService,
		private readonly _router: Router,
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService,
		private readonly _tokenStorage: TokenStorageService
	) { }

	ngOnInit(): void {
		this.loadFacilityStates();
	}


	public hasPermission(permission: string): boolean {
		return this._tokenStorage.hasPermission(permission);
	}

	public pageLoading(): boolean {
		return (this.loadingFacilityStates || this.processingPayment);
	}

	public loadFacilities(): void {
		if (this.loadingFacilities) {
			return;
		}

		this.paymentArgs.siteId = null;
		this.filterOptions.facilities = [];

		if (!this.filters.state) {
			return;
		}

		this.loadingFacilities = true;

		this._sharedService.getFacilitiesByState(this.filters.state)
			.subscribe((facilities: FacilityOption[]): void => {
				this.filterOptions.facilities = HelperService.sortArrByProp(facilities, 'name', false);
				this.loadingFacilities = false;
			}, (err: any): void => {
				console.error('Failed to load facilities list', err);
				this._snotify.error('An unexpected error occurred attempting to load facilities.', 'Load Failed');
				this.loadingFacilities = false;
			}, (): void => {
				//
			});
	}

	private loadFacilityStates(): void {
		if (this.loadingFacilityStates) {
			return;
		}

		this.loadingFacilityStates = true;

		this._sharedService.getFacilityStates()
			.subscribe((states: UsState[]): void => {
				this.filterOptions.facilityStates = HelperService.sortArrByProp(states, 'name', false);
				this.loadingFacilityStates = false;
			}, (err: any): void => {
				console.error('Failed to load states list', err);
				this._snotify.error('An unexpected error occurred attempting to load states.', 'Load Failed');
				this.loadingFacilityStates = false;
			}, (): void => {
				//
			});
	}

	private loadPurchaseInfo(): void {
		if (this.loadingPurchaseInfo) {
			return;
		}

		this.loadingPurchaseInfo = true;

		const args: GetPaymentInfoArgs = {
			siteId: this.paymentArgs.siteId,
			productType: this.paymentArgs.productType
		};

		this._paymentsService.getPaymentInfo(args)
			.subscribe((purchaseInfo: PurchaseInfo): void => {
				this.purchaseInfo = purchaseInfo;
				this.updateFeeAndTotal();

				this.loadingPurchaseInfo = false;
			}, (err: any): void => {
				console.error('Failed to load purchase info', err);
				this._snotify.error('An unexpected error occurred attempting to load purchase information.', 'Load Failed');
				this.loadingPurchaseInfo = false;
			}, (): void => {
				//
			});
	}

	public paymentAmountChanged(): void {
		this.updateFeeAndTotal();
	}

	public nextStep(): void {
		if (this.currentStep >= this.processSteps.length) {
			return;
		}

		switch (this.currentStep) {
			case 0:
				if (!this.paymentArgs.productType) {
					this._snotify.warning('Please select a payment type');
					return;
				}
				break;
			case 1:
				if (!this.paymentArgs.siteId) {
					this._snotify.warning('Please select a facility');
					return;
				}

				this.inmateTypeaheadFilters.siteIdList = [];
				this.inmateTypeaheadFilters.siteIdList.push(this.paymentArgs.siteId);

				this.pinDebitCustomerLoaded = false;

				// Now that we have the site ID and product type,
				// load the taxes/fees for the purchase
				this.loadPurchaseInfo();
				break;
			case 2:
				if (this.paymentArgs.productType === 1) {
					// Prepaid Collect
					let accountSelected: boolean = false;
					for (let i = 0; i < this.customerInfo.customerAccounts.length; i++) {
						if (this.customerInfo.customerAccounts[i].selected) {
							accountSelected = true;
							break;
						}
					}
					if (!accountSelected) {
						this._snotify.warning('Please select an account for the deposit');
						return;
					}
				} else if (this.paymentArgs.productType === 2) {
					// PIN Debit
					if (!this.inmateTypeaheadFilters.selectedInmate || !this.inmateTypeaheadFilters.selectedInmate.inmateIdentifierAtFacility) {
						this._snotify.warning('Please select an inmate for the deposit');
						return;
					}

					if (!this.pinDebitCustomer || !this.pinDebitCustomer.id) {
						this._snotify.warning('Please enter an existing customer\'s phone number');
						return;
					}
				}
				break;
			default:
				break;
		}

		this.processSteps[this.currentStep].active = false;
		this.currentStep++;

		if (this.currentStep < this.processSteps.length) {
			this.processSteps[this.currentStep].active = true;
		}
	}

	public prevStep(): void {
		if (this.currentStep <= 0 || this.currentStep >= this.processSteps.length) {
			return;
		}

		switch (this.currentStep) {
			case 3:
				this.pinDebitCustomerLoaded = false;
				break;
			default:
				break;
		}

		this.processSteps[this.currentStep].active = false;
		this.currentStep--;

		if (this.currentStep >= 0 && this.currentStep < this.processSteps.length) {
			this.processSteps[this.currentStep].active = true;
		}
	}

	public redirectToCreateCustomer(): void {
		//this._router.navigate(['/customers/create']);

		const url = this._router.serializeUrl(this._router.createUrlTree(['/customers/create']));
		window.open(url, '_blank');
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	public searchCustomer(): void {
		if (this.loadingCustomer || !this.filters.customerPhoneNumber || this.filters.customerPhoneNumber.length < 10) {
			return;
		}

		this.loadingCustomer = true;
    this.customerInfo = null;
    const strippedPhoneNumber = HelperService.stripNonNumeric(this.filters.customerPhoneNumber);
    this._customersService.getCustomerByPhone(strippedPhoneNumber)
			.subscribe((customer: Customer): void => {
				this.customerInfo = customer;

				for (let i = 0; i < this.customerInfo.customerAccounts.length; i++) {
					switch (this.customerInfo.customerAccounts[i].account.accountType.id) {
						case 1: // PPC Voice
							this.customerInfo.customerAccounts[i].account.accountType.description = 'PPC Voice/Video';
							break;
						case 2: // PPC SMS
							this.customerInfo.customerAccounts[i].account.accountType.description = 'PPC Text/Email';
							break;
						default:
							break;
					}
				}

				this.loadingCustomer = false;
			}, (err: any): void => {
				console.error('Failed to load customer', err);
				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load the customer info.', 'Load Failed');
						break;
				}
				this.loadingCustomer = false;
			}, (): void => {
				//
			});
	}

	public searchPinDebitCustomer(): void {
		if (this.loadingPinDebitCustomer) {
			return;
		}

		this.pinDebitCustomerLoaded = false;
		this.pinDebitCustomer = null;

		if (!this.filters.pinDebitPhoneNumber || this.filters.pinDebitPhoneNumber.length !== 10) {
			return;
		}

		this.loadingPinDebitCustomer = true;

		this._customersService.getCustomerByPhone(this.filters.pinDebitPhoneNumber)
			.subscribe((customer: Customer): void => {
				this.pinDebitCustomer = customer;
				this.loadingPinDebitCustomer = false;
				this.pinDebitCustomerLoaded = true;
			}, (err: any): void => {
				switch (err.status) {
					case 418:
						// Customer coult not be found. Continue.
						this.pinDebitCustomerLoaded = true;
						break;
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						this.pinDebitCustomerLoaded = false;
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load the customer info.', 'Load Failed');
						this.pinDebitCustomerLoaded = false;
						break;
				}

				this.loadingPinDebitCustomer = false;
			}, (): void => {
				//
			});
	}

	private getSelectedCustomerAcountId(): number {
		for (let i = 0; i < this.customerInfo.customerAccounts.length; i++) {
			if (this.customerInfo.customerAccounts[i].selected) {
				return this.customerInfo.customerAccounts[i].accountId;
			}
		}

		return 0;
	}

	public selectCustomerAccount(customerAccountId: number): void {
		for (let i = 0; i < this.customerInfo.customerAccounts.length; i++) {
			if (this.customerInfo.customerAccounts[i].id === customerAccountId) {
				this.customerInfo.customerAccounts[i].selected = true;
			} else {
				this.customerInfo.customerAccounts[i].selected = false;
			}
		}
	}

	public submit(): void {
		if (this.processingPayment) {
			return;
		}

		this.processingPayment = true;

		this.purchaseResult = null;

		if (this.validatePaymentArgs()) {
			this.paymentArgs.acceptsTerms = true; // Only applicable for if customer is filling out form themselves

			if (this.paymentArgs.productType === 1) {
				// PPC Deposit
				this.paymentArgs.customerId = this.customerInfo.id;
				this.paymentArgs.accountId = this.getSelectedCustomerAcountId();
			} else if (this.paymentArgs.productType === 2) {
				// PIN Debit Deposit
				this.paymentArgs.inmateName = this.inmateTypeaheadFilters.selectedInmate.lastName + ', ' + this.inmateTypeaheadFilters.selectedInmate.firstName;
				this.paymentArgs.inmateIdentifierAtFacility = this.inmateTypeaheadFilters.selectedInmate.inmateIdentifierAtFacility;

				this.paymentArgs.customerId = this.pinDebitCustomer.id;
			}

			this._paymentsService.processPayment(this.paymentArgs)
				.subscribe((result: PurchaseResult): void => {
					this.purchaseResult = result;
					this.processingPayment = false;

					this._snotify.success('Payment processed successfully', 'Payment Success');

					window.location.reload();
				}, (err: any): void => {
					console.error('Failed to process payment', err);
					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An error occurred attempting to process the payment.', 'Payment Failed');
							break;
					}
					this.processingPayment = false;
				}, (): void => {
					//
				});
		} else {
			this.processingPayment = false;
		}
	}

	public updateFeeAndTotal(): void {
		if (!this.purchaseInfo) {
			this.feeAmount = 0;
			this.totalAmount = this.paymentArgs.paymentAmount;
		} else {
			if (this.purchaseInfo.percentFee > 0 && !isNaN(this.paymentArgs.paymentAmount)) {
				this.feeAmount = _.cloneDeep((parseFloat(this.paymentArgs.paymentAmount.toString()) * this.purchaseInfo.percentFee) + this.purchaseInfo.flatFee);
			} else {
				this.feeAmount = _.cloneDeep(this.purchaseInfo.flatFee);
			}

			if (isNaN(this.paymentArgs.paymentAmount)) {
				this.totalAmount = _.cloneDeep(this.feeAmount);
			} else {
				this.totalAmount = (this.feeAmount + parseFloat(this.paymentArgs.paymentAmount.toString()));
			}
		}
	}

	public useCustomerAddressChange(): void {
		if (this.useCustomerAddress) {
      let customer: Customer = null;

			if (this.paymentArgs.productType === 1) {
				customer = _.cloneDeep(this.customerInfo);
			} else if (this.paymentArgs.productType === 2 && this.pinDebitCustomer) {
				customer = _.cloneDeep(this.pinDebitCustomer);
			}

      if (customer) {
        let customerName = (customer.firstName + ' ' + customer.lastName);
        this.paymentArgs.nameOnCard = customerName;
				this.paymentArgs.streetAddress1 = customer.address1;
				this.paymentArgs.streetAddress2 = customer.address2;
				this.paymentArgs.city = customer.city;
				this.paymentArgs.state = customer.state;
				this.paymentArgs.zipCode = customer.zip;
				return;
			}
		}
    this.paymentArgs.nameOnCard = null;
		this.paymentArgs.streetAddress1 = null;
		this.paymentArgs.streetAddress2 = null;
		this.paymentArgs.city = null;
		this.paymentArgs.state = null;
		this.paymentArgs.zipCode = null;
	}

	public validatePaymentArgs(): boolean {
		let returnValue: boolean = true;

		if (!this.paymentArgs.paymentAmount) {
			this.errors.paymentAmount.push('Please enter a payment amount');
			returnValue = false;
		} else if (this.paymentArgs.paymentAmount < this.purchaseInfo.minAmount) {
			this.errors.paymentAmount.push(`The minimum deposit amount required by the facility is ${HelperService.currencyFormatter.format(this.purchaseInfo.minAmount)}`);
			returnValue = false;
		} else if (this.paymentArgs.paymentAmount > this.purchaseInfo.maxAmount) {
			this.errors.paymentAmount.push(`The maximum deposit amount allowed by the facility is ${HelperService.currencyFormatter.format(this.purchaseInfo.maxAmount)}`);
			returnValue = false;
		}

		if (!this.paymentArgs.creditCardNumber) {
			this.errors.creditCardNumber.push('Please enter a credit card number');
			returnValue = false;
		} else if (!HelperService.isValidCreditCardNumber(this.paymentArgs.creditCardNumber, this.paymentArgs.creditCardType)) {
			this.errors.creditCardNumber.push('Credit Card number is invalid');
			returnValue = false;
		}

		return returnValue;
	}
}
