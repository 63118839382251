import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { HelperService, UniqueIdType, UsState } from 'src/app/helpers/helper.service';
import { Customer, CustomersService } from 'src/app/services/customers.service';
import { InmateAccount, InmatesService } from 'src/app/services/inmates.service';
import { PaymentsService, SendFundsArgs } from 'src/app/services/payments.service';
import { FacilityOption, SharedService } from 'src/app/services/shared.service';
import { PsiModalSettings } from 'src/app/shared/components/psi-modal/psi-modal.component';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';


@Component({
	selector: 'app-send-funds',
	templateUrl: './send-funds.component.html'
})
export class SendFundsComponent implements OnInit {
	private def = {
		errors: {
			srcUserType: [],
			destUserType: []
		},
		filterOptions: {
			srcUserTypes: [
				{ id: 2, name: 'Friends & Family' }
			],
			destUserTypes: [
				{ id: 1, name: 'Inmate' },
				{ id: 2, name: 'Friends & Family' }
			],
			facilityStates: [],
			states: HelperService.getUsStateList(),
			srcFacilities: [],
			destFacilities: []
		},
		source: {
			userType: 2,
			phoneNumber: null,
			accountId: null,
			pin: null,
			siteId: null,
			state: null,
			customerInfo: null
		},
		destination: {
			userType: null,
			phoneNumber: null,
			accountId: null,
			pin: null,
			siteId: null,
			state: null,
			customerInfo: null
		},
		amountDollars: 0.00,
		inmateAccountTypeaheadFilters: {
			selectedInmateAccount: null,
			siteIdList: []
		}
  };

  public isSourceAccountRestricted: boolean = false;
  public isDestinationAccountRestricted: boolean = false;


	public errors: any = _.cloneDeep(this.def.errors);
	public filterOptions: any = _.cloneDeep(this.def.filterOptions);
	public source: any = _.cloneDeep(this.def.source);
	public destination: any = _.cloneDeep(this.def.destination);
	public amountDollars: number = _.cloneDeep(this.def.amountDollars);
	public srcInmateAccountTypeaheadFilters = _.cloneDeep(this.def.inmateAccountTypeaheadFilters);
	public destInmateAccountTypeaheadFilters = _.cloneDeep(this.def.inmateAccountTypeaheadFilters);

  

  public sendFundsModalSettings: PsiModalSettings = {
		openModalButtonText: '',
		title: 'Send Funds',
		spanClass: 'fas fa-3x fa-paper-plane clickable',
		btnClass: 'btn btn-success',
		confirmBtnText: 'Yes',
		closeBtnText: 'No',
		openerType: 'button',
		uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
	};

	public sendingFunds: boolean = false;
	public loadingFacilities: boolean = false;
	public loadingFacilityStates: boolean = false;
	public loadingSrcData: boolean = false;
	public loadingDestData: boolean = false;


	constructor(
		private readonly _customersService: CustomersService,
		private readonly _inmatesService: InmatesService,
		private readonly _paymentsService: PaymentsService,
		private readonly _phoneNumberPipe: PhoneNumberPipe,
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService
	) { }

	ngOnInit(): void {
		this.filterOptions.srcUserTypes = _.cloneDeep(this.filterOptions.srcUserTypes);
		this.filterOptions.destUserTypes = _.cloneDeep(this.filterOptions.destUserTypes);

		this.loadFacilityStates();
	}


	public busy(): boolean {
		return (this.loadingFacilityStates || this.loadingFacilities || this.loadingSrcData || this.loadingDestData || this.sendingFunds);
	}

	public cancelSendFundsBtnClick(): void {
		console.log('Cancelled send funds');
	}

	public disableSendFunds(): boolean {
		if (this.amountDollars <= 0) {
			return true;
		}

		if (!this.source || !this.destination) {
			return true;
		}

		if (this.source.userType === 1) {
			if (!this.source.siteId) {
				return true;
			}

			if (!this.srcInmateAccountTypeaheadFilters || !this.srcInmateAccountTypeaheadFilters.selectedInmateAccount) {
				return true;
			} else if (!this.srcInmateAccountTypeaheadFilters.selectedInmateAccount.pin) {
				return true;
			}
		} else if (this.source.userType === 2) {
			if (!this.source.phoneNumber) {
				return true;
			}
		} else {
			return true;
		}

		if (this.destination.userType === 1) {
			if (!this.destination.siteId) {
				return true;
			}

			if (!this.destInmateAccountTypeaheadFilters || !this.destInmateAccountTypeaheadFilters.selectedInmateAccount) {
				return true;
			} else if (!this.destInmateAccountTypeaheadFilters.selectedInmateAccount.pin) {
				return true;
			}
		} else if (this.destination.userType === 2) {
			if (!this.destination.phoneNumber) {
				return true;
			}
		} else {
			return true;
		}

		return false;
	}

	public getBalance(srcOrDest: 'src' | 'dest'): void {
		let account: InmateAccount;

		if (srcOrDest === 'src'){
			account = this.srcInmateAccountTypeaheadFilters.selectedInmateAccount;
		} else if (srcOrDest === 'dest'){
			account = this.destInmateAccountTypeaheadFilters.selectedInmateAccount;
		}

		if (!account.pin || !account.pin) {
			return;
		}

		if (srcOrDest === 'src') {
			if (this.loadingSrcData) {
				return;
			}

			this.loadingSrcData = true;
		} else if (srcOrDest === 'dest') {
			if (this.loadingDestData) {
				return;
			}

			this.loadingDestData = true;
		} else {
			return;
		}

		this._inmatesService.getInmateAccountBalance(account.pin, account.siteId)
			.subscribe((balance: number): void => {
				// Note: Balance returned is in dollars
				account.balance = balance;

				if (srcOrDest === 'src') {
					this.loadingSrcData = false;
				} else if (srcOrDest === 'dest') {
					this.loadingDestData = false;
				}
			}, (err: any): void => {
				console.error('Failed to load account balance', err);
				this._snotify.error('An unexpected error occurred attempting to load account balance.', 'Load Failed');
				if (srcOrDest === 'src') {
					this.loadingSrcData = false;
				} else if (srcOrDest === 'dest') {
					this.loadingDestData = false;
				}
			}, (): void => {
				//
			});
	}

	public loadingPageData(): boolean {
		return this.loadingFacilityStates;
	}

	public loadFacilities(srcOrDest: 'src' | 'dest'): void {
		if (this.loadingFacilities) {
			return;
		}

		let state: string;
		if (srcOrDest === 'src') {
			this.filterOptions.srcFacilities = [];
			this.source.siteId = null;

			if (!this.source.state) {
				return;
			}

			state = this.source.state;
		} else if (srcOrDest === 'dest') {
			this.filterOptions.destFacilities = [];
			this.destination.siteId = null;

			if (!this.destination.state) {
				return;
			}

			state = this.destination.state;
		}

		this.loadingFacilities = true;

		this._sharedService.getFacilitiesByState(state)
			.subscribe((facilities: FacilityOption[]): void => {
				if (srcOrDest === 'src') {
					this.filterOptions.srcFacilities = HelperService.sortArrByProp(facilities, 'name', false);
				} else if (srcOrDest === 'dest') {
					this.filterOptions.destFacilities = HelperService.sortArrByProp(facilities, 'name', false);
				}

				this.loadingFacilities = false;
			}, (err: any): void => {
				console.error('Failed to load facilities list', err);
				this._snotify.error('An unexpected error occurred attempting to load facilities.', 'Load Failed');
				this.loadingFacilities = false;
			}, (): void => {
				//
			});
	}

	public reloadDestinationData(): void {
		switch (this.destination.userType) {
			case 1:
				this.getBalance('dest');
				break;
			case 2:
				this.searchCustomer('dest');
				break;
			default:
				break;
		}
	}

	public reloadSourceData(): void {
		switch (this.source.userType) {
			case 1:
				this.getBalance('src');
				break;
			case 2:
				this.searchCustomer('src');
				break;
			default:
				break;
		}
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	public resetInputData(): void {
		this.errors = _.cloneDeep(this.def.errors);
		this.amountDollars = _.cloneDeep(this.def.amountDollars);
	}

	public resetSource(): void {
		this.source = _.cloneDeep(this.def.source);
		this.srcInmateAccountTypeaheadFilters = _.cloneDeep(this.def.inmateAccountTypeaheadFilters);
		this.errors = _.cloneDeep(this.def.errors);
	}

	public resetDestination(): void {
		this.destination = _.cloneDeep(this.def.destination);
		this.destInmateAccountTypeaheadFilters = _.cloneDeep(this.def.inmateAccountTypeaheadFilters);
		this.errors = _.cloneDeep(this.def.errors);
	}

	public async searchCustomer(srcOrDest: 'src' | 'dest') {
		let phoneNumber: string;
		if (srcOrDest === 'src') {
			phoneNumber = this.source.phoneNumber;
		} else if (srcOrDest === 'dest') {
			phoneNumber = this.destination.phoneNumber;
		} else {
			return;
		}

		phoneNumber = HelperService.stripNonNumeric(phoneNumber);

		if (!phoneNumber || phoneNumber.length < 10) {
			return;
    }

    const isRestricted: boolean = await this._customersService.isPhoneNumberGloballyRestricted(phoneNumber).toPromise();
    if (srcOrDest === 'src')
      this.isSourceAccountRestricted = isRestricted;
    else if (srcOrDest === 'dest')
      this.isDestinationAccountRestricted = isRestricted;

    if (isRestricted) {
      const phoneNumberDisplay = this._phoneNumberPipe.transform(phoneNumber, null);
      this._snotify.warning(`${phoneNumberDisplay} is restricted.`, 'Warning');
      //return;
    }

		if (srcOrDest === 'src') {
			this.loadingSrcData = true;
		} else if (srcOrDest === 'dest') {
			this.loadingDestData = true;
		}

		this._customersService.getCustomerByPhone(phoneNumber)
			.subscribe((customer: Customer): void => {
				// Set display values
				for (let i = 0; i < customer.customerAccounts.length; i++) {
					switch (customer.customerAccounts[i].account.accountType.id) {
						case 1: // PPC Voice
							customer.customerAccounts[i].account.accountType.description = 'PPC Voice/Video';
							break;
						case 2: // PPC SMS
							customer.customerAccounts[i].account.accountType.description = 'PPC Text/Email';
							break;
						default:
							break;
					}
				}

				const phoneNumberDisplay = this._phoneNumberPipe.transform(customer.phoneNumber, null);
				customer.customerDisplay = `${customer.firstName} ${customer.lastName} ${phoneNumberDisplay}`;

				if (srcOrDest === 'src') {
					this.source.customerInfo = customer;
					this.loadingSrcData = false;
				} else if (srcOrDest === 'dest') {
					this.destination.customerInfo = customer;
					this.loadingDestData = false;
				}
			}, (err: any): void => {
				console.error('Failed to load customer', err);
				switch (err.status) {
					case 418:
						this._snotify.warning('No customer account was found with the given phone number');
						break;
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load the customer info.', 'Load Failed');
						break;
				}

				if (srcOrDest === 'src') {
					this.loadingSrcData = false;
				} else if (srcOrDest === 'dest') {
					this.loadingDestData = false;
				}
			}, (): void => {
				//
			});
	}

	public selectCustomerAccount(srcOrDest: 'src' | 'dest', customerAccountId: number): void {
		let customerInfo: any;
		if (srcOrDest === 'src') {
			customerInfo = this.source.customerInfo;
		} else if (srcOrDest === 'dest') {
			customerInfo = this.destination.customerInfo;
		} else {
			return;
		}

		for (let i = 0; i < customerInfo.customerAccounts.length; i++) {
			if (customerInfo.customerAccounts[i].id === customerAccountId) {
				customerInfo.customerAccounts[i].selected = true;
			} else {
				customerInfo.customerAccounts[i].selected = false;
			}
		}
	}

	public sendFunds(): void {
		if (this.sendingFunds) {
			return;
		}

		this.sendingFunds = true;

		const args: SendFundsArgs = this.getSendFundArgs();

		if (this.validateSendFundArgs(args)) {
			this._paymentsService.sendFunds(args)
				.subscribe((): void => {
					this.reloadSourceData();
					this.reloadDestinationData();
					this.resetInputData();
					this.sendingFunds = false;
					this._snotify.success('Funds sent successfully');
				}, (err: any): void => {
					console.error('Failed to send funds', err);
					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred attempting to send funds.', 'Send Failed');
							break;
					}
					this.sendingFunds = false;
				}, (): void => {
					//
				});
		} else {
			this.sendingFunds = false;
		}
	}

	public setInmateSiteId(srcOrDest: string): void {
		if (srcOrDest === 'src') {
			this.srcInmateAccountTypeaheadFilters.siteIdList = [];
			if (this.source.siteId) {
				this.srcInmateAccountTypeaheadFilters.siteIdList.push(this.source.siteId);
			}
		} else if (srcOrDest === 'dest') {
			this.destInmateAccountTypeaheadFilters.siteIdList = [];
			if (this.destination.siteId) {
				this.destInmateAccountTypeaheadFilters.siteIdList.push(this.destination.siteId);
			}
		}
	}

	public swapSrcAndDest(): void {
		const src = _.cloneDeep(this.source);
		this.source = _.cloneDeep(this.destination);
		this.destination = src;

		const srcFacilities = _.cloneDeep(this.filterOptions.srcFacilities);
		this.filterOptions.srcFacilities = _.cloneDeep(this.filterOptions.destFacilities);
		this.filterOptions.destFacilities = srcFacilities;

		this.setInmateSiteId('src');
		this.setInmateSiteId('dest');

		const srcInmateTypeahead = _.cloneDeep(this.srcInmateAccountTypeaheadFilters);
		this.srcInmateAccountTypeaheadFilters = _.cloneDeep(this.destInmateAccountTypeaheadFilters);
		this.destInmateAccountTypeaheadFilters = srcInmateTypeahead;
	}

	private getSendFundArgs(): SendFundsArgs {
		let returnValue: SendFundsArgs = new SendFundsArgs();

		returnValue.sourceUserType = this.source.userType;
		returnValue.sourcePhoneNumber = this.source.phoneNumber;
		returnValue.sourceSiteId = this.source.siteId;
		if (this.srcInmateAccountTypeaheadFilters && this.srcInmateAccountTypeaheadFilters.selectedInmateAccount) {
			returnValue.sourcePin = this.srcInmateAccountTypeaheadFilters.selectedInmateAccount.pin;
		}

		if (this.source.userType === 2) {
			for (let i = 0; i < this.source.customerInfo.customerAccounts.length; i++) {
				if (this.source.customerInfo.customerAccounts[i].selected === true) {
					returnValue.sourceAccountId = this.source.customerInfo.customerAccounts[i].accountId;
					break;
				}
			}
		}

		returnValue.destinationUserType = this.destination.userType;
		returnValue.destinationPhoneNumber = this.destination.phoneNumber;
		returnValue.destinationSiteId = this.destination.siteId;
		if (this.destInmateAccountTypeaheadFilters && this.destInmateAccountTypeaheadFilters.selectedInmateAccount) {
			returnValue.destinationPin = this.destInmateAccountTypeaheadFilters.selectedInmateAccount.pin;
		}

		if (this.destination.userType === 2) {
			for (let i = 0; i < this.destination.customerInfo.customerAccounts.length; i++) {
				if (this.destination.customerInfo.customerAccounts[i].selected === true) {
					returnValue.destinationAccountId = this.destination.customerInfo.customerAccounts[i].accountId;
					break;
				}
			}
		}

		returnValue.amountCents = Math.round(this.amountDollars * 100);
		console.log(returnValue.amountCents);

		return returnValue;
	}

	private loadFacilityStates(): void {
		if (this.loadingFacilityStates) {
			return;
		}

		this.loadingFacilityStates = true;

		this._sharedService.getFacilityStates()
			.subscribe((states: UsState[]): void => {
				this.filterOptions.facilityStates = HelperService.sortArrByProp(states, 'name', false);
				this.loadingFacilityStates = false;
			}, (err: any): void => {
				console.error('Failed to load states list', err);
				this._snotify.error('An unexpected error occurred attempting to load states.', 'Load Failed');
				this.loadingFacilityStates = false;
			}, (): void => {
				//
			});
	}

	private validateSendFundArgs(args: SendFundsArgs): boolean {
		let returnValue: boolean = true;

		if (args.sourceUserType === 1) {
			// Source is inmate account

			if (!args.sourceSiteId) {
				this._snotify.warning('Please select a source facility');
				returnValue = false;
			}

			if (!args.sourcePin) {
				this._snotify.warning('Please select a source inmate account');
				returnValue = false;
			}

			if (this.srcInmateAccountTypeaheadFilters.selectedInmateAccount.balance < this.amountDollars) {
				this._snotify.warning('The amount being sent cannot exceed the source account\'s balance');
				returnValue = false;
			}
		} else if (args.sourceUserType === 2) {
			// Source is Customer/F&F account

			if (!args.sourcePhoneNumber) {
				this._snotify.warning('Please enter a source phone number');
				returnValue = false;
			}

			if (!args.sourceAccountId) {
				this._snotify.warning('Please select a source account');
				returnValue = false;
			}

			// TODO Check source account's balance against amount being sent
		} else {
			this._snotify.warning('Please select a source user type');
			returnValue = false;
		}

		if (args.destinationUserType === 1) {
			// Destination is inmate account

			if (!args.destinationSiteId) {
				this._snotify.warning('Please select a destination facility');
				returnValue = false;
			}

			if (!args.destinationPin) {
				this._snotify.warning('Please select a destination inmate account');
				returnValue = false;
			}
		} else if (args.destinationUserType === 2) {
			// Destination is Customer/F&F account

			if (!args.destinationPhoneNumber) {
				this._snotify.warning('Please enter a destination phone number');
				returnValue = false;
			}

			if (!args.destinationAccountId) {
				this._snotify.warning('Please select a destination account');
				returnValue = false;
			}
		} else {
			this._snotify.warning('Please select a destination user type');
			returnValue = false;
		}

		if (args.sourceUserType === 1 && args.destinationUserType === 1 && args.sourceSiteId !== args.destinationSiteId) {
			this._snotify.warning('Cannot transfer funds between different facilities. The source and destination facilities must be the same.');
			returnValue = false;
		}

		if (this.amountDollars <= 0.00) {
			this._snotify.warning('Please enter an amount to transfer');
			returnValue = false;
		} else if (this.amountDollars > 1000.00) {
			this._snotify.warning('Amount to transfer cannot exceed $1000.00');
			returnValue = false;
		}

		return returnValue;
  }

  public accountRestricted(): boolean {
    return (this.isSourceAccountRestricted || this.isDestinationAccountRestricted);
  }
}
