import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PaymentsService {
	private readonly _apiUrl = `${environment.apiUrl}/Payments`;

	constructor(private http: HttpClient) { }


	public getBulkRefundSummary(args: GetBulkRefundSummaryArgs): Observable<ProcessRefundArgs[]> {
		return this.http.post<any>(`${this._apiUrl}/GetBulkRefundSummary`, args);
	}

	public getPaymentInfo(args: GetPaymentInfoArgs): Observable<PurchaseInfo> {
		return this.http.post<any>(`${this._apiUrl}/GetPaymentInfo`, args);
	}

	public processPayment(args: ProcessPaymentArgs): Observable<PurchaseResult> {
		return this.http.post<any>(`${this._apiUrl}/Process`, args);
	}

	public processBulkRefund(args: ProcessRefundArgs[]): Observable<PurchaseResult[]> {
		return this.http.post<any>(`${this._apiUrl}/RefundBulk`, args);
	}

	public processRefund(args: ProcessRefundArgs): Observable<PurchaseResult> {
		return this.http.post<any>(`${this._apiUrl}/Refund`, args);
	}

	public sendFunds(args: SendFundsArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/SendFunds`, args);
	}

	public transferFunds(args: TransferFundsArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/Transfer`, args);
  }

  public getTransferFundFee(): Observable<number> {
    return this.http.get<any>(`${this._apiUrl}/TransferFundFee`);
  }
}

export class GetBulkRefundSummaryArgs {
	public accountId: number;
	public amount: number;
	public customerId: number;
}

export class GetPaymentInfoArgs {
	public siteId: string;
	public productType: number;
}

export class ProcessPaymentArgs {
	public customerId: number;
	public creditCardType: number;
	public creditCardNumber: string;
	public creditCardSecurityCode: string;
	public nameOnCard: string;
	public streetAddress1: string;
	public streetAddress2: string;
	public acceptsTerms: boolean;
	public countryId: number;
	public city: string;
	public state: string;
	public zipCode: string;
	public cardExpirationMonth: number;
	public cardExpirationYear: number;
	public paymentAmount: number;
	//public inmateId: number;
	public inmateName: string;
	public inmateIdentifierAtFacility: string;
	public siteId: string;
	public productType: number;
	public accountId: number;
	// public customerEmail: string;
	// public customerFirstName: string;
	// public customerLastName: string;
	// public customerPhoneNumber: string;
}

export class ProcessRefundArgs {
	public transactionId: string;
	public amount: number;
	public notes: string | null;
	public depositTypeId: number;
  public customerId: number;
  public phoneNumber: string;
}

export class PurchaseInfo {
	public flatFee: number;
	public percentFee: number;
	public minAmount: number;
	public maxAmount: number;
}

export class PurchaseResult {
	public success: boolean;
	public messages: string[];
	public transactionId: string;
	public creditAuthorizationTransactionId: string;
	public callerEmail: string;
}

export class SendFundsArgs {
	public sourceUserType: number;
	public sourcePhoneNumber: string;
	public sourceAccountId: number;
	public sourcePin: string;
	public sourceSiteId: string;
	public destinationUserType: number;
	public destinationPhoneNumber: string;
	public destinationAccountId: number;
	public destinationPin: string;
	public destinationSiteId: string;
	public amountCents: number;
}

export class TransferFundsArgs {
	public customerId: number;
	public siteId: string;
	public amount: number;
	public phoneNumber: string;
	public sourceAccountId: number;
	public destinationAccountId: number;
}
