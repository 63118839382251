<div class="page-container">
    <h2 class="page-title">User Actions</h2>

    <div class="container large-container filters-backdrop">
        <form class="filters-container form-inline">
            <div class="form-row col-sm-12">
                <div class="input-group col-md-6">
                    <label for="filterStartDate" class="col-form-label col-md-3 inline-block">Start</label>
                    <input type="text" placeholder="yyyy-mm-dd" [(ngModel)]="filters.startDate" name="filterStartDate" ngbDatepicker #sdp="ngbDatepicker" />
                    <button class="btn btn-primary" (click)="sdp.toggle()"><span class="far fa-calendar-alt"></span></button>
                </div>
                <div class="input-group col-md-6">
                    <label for="filterEndDate" class="col-form-label col-md-3 inline-block">End</label>
                    <input type="text" placeholder="yyyy-mm-dd" [(ngModel)]="filters.endDate" name="filterEndDate" ngbDatepicker #edp="ngbDatepicker" />
                    <button class="btn btn-primary" (click)="edp.toggle()"><span class="far fa-calendar-alt"></span></button>
                </div>
            </div>
            <div class="form-row col-sm-12">
                <div class="col-sm-6 col-md-4 filter-field-container">
                    <label for="filterUserName" class="col-form-label col-sm-4 inline-block">Username</label>
                    <input [(ngModel)]="filters.userName" name="filterUserName" id="filterUserName" type="text" class="form-control full-width col-sm-8" maxlength="256" />
                </div>
                <div class="col-md-4 filter-field-container">
                    <label for="filtersUserActionTypeIdList" class="col-form-label col-md-4 inline-block">Action Types</label>
                    <ng-select [multiple]="true" name="filtersUserActionTypeIdList" [(ngModel)]="filters.userActionTypeIdList" [loading]="loadingUserActionTypes" [disabled]="loadingReport || loadingUserActionTypes || !userActionTypesLoaded" class="col-md-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let actionType of filterOptions.userActionTypes" [value]="actionType.id">{{ actionType.name }}</ng-option>
                    </ng-select>
                </div>
            </div>
        </form>
        <div class="filters-footer">
            <button (click)="loadReport()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-search"></span> Search</button>
            <button (click)="reset()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>

    <div class="container large-container results-container">
        <table class="table table-dark table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'Created')">Date</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'UserName')">Username</th>
                    <th scope="col" class="sortable" (click)="sortTable($event, 'UserActionTypeDescription')">Action</th>
                    <th scope="col">Data</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="reportItems && reportItems.length > 0">
                <ng-container *ngFor="let item of reportItems; let i = index">
                    <tr [ngClass]="{'odd': (i % 2 == 0)}">
                        <td scope="row">{{ item.created | date: 'MM/dd/yyyy @ HH:mm' }}</td>
                        <td scope="row">{{ item.userName }}</td>
                        <td scope="row">{{ item.userActionTypeDescription }}</td>
                        <td scope="row" [title]="item.jsonDataString">{{ item.jsonDataString | truncate: [125, '...'] }}</td>
                        <td scope="row">
                            <span *ngIf="item.jsonDataString" class="fas fa-copy clickable color-blue modal-button" title="Copy Data to Clipboard" (click)="copyToClipboard(item.jsonDataString)"></span>
                        </td>
                    </tr>
                    <!-- <tr *ngIf="item.showDetails" [ngClass]="{'odd': (i % 2 == 0)}">
                        <td colspan="15" class="col-sm-12 order-report-row-details">
                            <div class="col-md-6">
                                <div class="bold">Prodigy Transaction ID:</div>
                                <div>&nbsp;&nbsp;{{ item.prodigyTransactionId }}</div>
                            </div>
                        </td>
                    </tr> -->
                </ng-container>
            </tbody>
            <tbody *ngIf="!loadingReport && reportItems && reportItems.length === 0">
                <tr>
                    <td colspan="12">No Results Found</td>
                </tr>
            </tbody>
            <tbody *ngIf="loadingReport" class="text-center">
                <tr>
                    <td colspan="12">
                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="!loadingReport && reportItems && reportItems.length > 0 && collectionSize > pageSize" class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadReport()" [boundaryLinks]="true" [maxSize]="7"></ngb-pagination>
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadReport()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>