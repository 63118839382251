import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
	transform(value: string | undefined | null, args: any[]): string {
		if (!value) {
			return value;
		}

		if (value.length !== 10) {
			return value;
		}

		if (isNaN(value as any)) {
			return value;
		}

		// Formatted example:
		// 1234567890 --> (123) 456-7890
		return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
	}
}
