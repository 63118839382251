import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { HelperService } from '../helpers/helper.service';
import { AuthService, Credentials } from '../services/auth.service';
import { BroadcastService } from '../services/broadcast.service';
import { TokenStorageService } from '../services/token-storage.service';
import { User } from '../services/user.service';

@Component({
	selector: 'login',
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

	public credentials: Credentials = {
		username: null,
		password: null
	};

	public forgotPasswordOpen: boolean = false;
	public loading: boolean = false;
	public loggedIn: boolean = false;


	constructor(
		private _authService: AuthService,
		private _broadcastService: BroadcastService,
		private _tokenStorage: TokenStorageService,
		private _snotify: SnotifyService,
		private _router: Router
	) { }

	ngOnInit(): void {
		// if (this._tokenStorage.getToken()) {
		// 	this.loggedIn = true;
		// }
		this.logout();
	}

	public openForgotPassword(): void {
		this.forgotPasswordOpen = true;
	}

	public closeForgotPassword(): void {
		this.forgotPasswordOpen = false;
	}

	public login(): void {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (this.validateCredentials()) {
			this._authService.login(this.credentials)
				.subscribe((data: User): void => {
					this._tokenStorage.saveToken(data.authToken);
					this._tokenStorage.saveUser(data);
					this._tokenStorage.saveIsLoggedIn(true);

					this._broadcastService.broadcast("LOGIN");

					this._router.navigate(["/home"]);
				}, (err: any): void => {
					console.log(err);
					switch (err.status) {
						case 401:
							this._snotify.warning('Invalid username and/or password');
							break;
						default:
							this._snotify.error('Unexpected error has occurred. Please contact your system administrator.', 'App Error');
							break;
					}

					this.loading = false;
				});
		} else {
			this.loading = false;
		}
	}

	public logout(): void {
		if (this.loading) {
			return;
		}

		this.loading = true;

		this._authService.logout()
			.subscribe((): void => {
				this._tokenStorage.signOut();
				this._broadcastService.broadcast("LOGOUT");
				this.loading = false;
			}, (err: any): void => {
				console.log(err);
				this._snotify.error('Unexpected error has occurred. Please contact your system administrator.', 'App Error');
				this.loading = false;
			});
	}

	public resetPassword(): void {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (this.validateUsername()) {
			this._authService.resetPassword(this.credentials.username)
				.subscribe((): void => {
					this._snotify.success('An email has been sent containing your new password.', 'Password Reset');
					this.closeForgotPassword();
					this.loading = false;
				}, (err: any): void => {
					console.error('Error resetting password', err);

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred attempting to reset your password.', 'Reset Failed');
							break;
					}

					this.loading = false;
				});
		} else {
			this.loading = false;
		}
	}

	public validateUsername(): boolean {
		let returnValue: boolean = true;

		if (!this.credentials.username) {
			this._snotify.warning('Username is required', 'Invalid Data');
			returnValue = false;
		}

		return returnValue;
	}

	public validateCredentials(): boolean {
		let returnValue: boolean = true;

		if (!this.credentials.username) {
			this._snotify.warning('Username is required', 'Invalid Data');
			returnValue = false;
		}

		if (!this.credentials.password) {
			this._snotify.warning('Password is required', 'Invalid Data');
			returnValue = false;
		}

		return returnValue;
	}
}