import { Component, OnInit } from '@angular/core';
import { HelperService } from '../helpers/helper.service';
import * as _ from 'lodash';
import { GetProductRateReportArgs, ProductRateReportItem, ProductRatesService } from '../services/product-rates.service';
import { FacilityOption, SelectOption, SharedService } from '../services/shared.service';
import { SnotifyService } from 'ng-snotify';
import { CacheService } from '../services/cache.service';

@Component({
	selector: 'app-product-rates',
	templateUrl: './product-rates.component.html'
})
export class ProductRatesComponent implements OnInit {
	private def: any = {
		filters: {
			siteIdList: [],
			productTypeIdList: [],
			productRateTypeIdList: []
		},
		filterOptions: {
			facilities: [],
			productTypes: [],
			productRateTypes: []
		},
		reportArgs: {
			siteIdList: [],
			productTypeIdList: [],
			productRateTypeIdList: []
		}
	}

	public filters: ProductRateFilters = _.cloneDeep(this.def.filters);
	public filterOptions: any = _.cloneDeep(this.def.filterOptions);
	public reportArgs: GetProductRateReportArgs = _.cloneDeep(this.def.reportArgs);

	public productRates: ProductRateReportItem[] = [];

	public page: number = 1;
	public pageSize: number = 10;
	public collectionSize: number = 0;
	public sortField: string = 'facilityName';
	public sortDesc: boolean = true;

	public facilitiesLoaded: boolean = false;
	public initLoad: boolean = true;
	public loading: boolean = false;
	public loadingFacilities: boolean = false;
	public loadingProductTypes: boolean = false;
	public loadingProductRateTypes: boolean = false;
	public productTypesLoaded: boolean = false;
	public productRateTypesLoaded: boolean = false;
	public reportLoaded: boolean = false;

	constructor(
		private _productRateService: ProductRatesService,
		private _sharedService: SharedService,
		private _snotify: SnotifyService
	) {
		this.loadProductTypes();
		this.loadProductRateTypes();
		this.loadFacilities(); // on init page load (if initLoad is true), this will also load the product rates after facilities are loaded
	}

	ngOnInit(): void {
		const savedFilters = CacheService.getItem(CacheService.keys.PRODUCT_RATE_FILTERS);
		if (savedFilters) {
			this.filters = _.cloneDeep(savedFilters);
		}
	}

	public busy(): boolean {
		return (this.loading || this.loadingFacilities || this.loadingProductTypes || this.loadingProductRateTypes);
	}

	public getFacilityName(siteId: string): string {
		for (let i = 0; i < this.filterOptions.facilities.length; i++) {
			if (this.filterOptions.facilities[i].siteId.toUpperCase() === siteId.toUpperCase()) {
				return this.filterOptions.facilities[i].name;
			}
		}
		return null;
	}

	public loadFacilities(): void {
		if (this.loadingFacilities) {
			return;
		}

		this.loadingFacilities = true;

		if (this.facilitiesLoaded) {
			this.facilitiesLoaded = false;
		}

		this._sharedService.getFacilities()
			.subscribe((result: FacilityOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.facilities = [...result];

				this.facilitiesLoaded = true;
				this.loadingFacilities = false;

				// This is called here ot make sure that facilities are loaded first on page load
				// if (this.initLoad) {
				// 	this.refreshProductRates();
				// 	this.initLoad = false;
				// }
			}, (err: any): void => {
				console.error('Failed to retrieve list of all facilities', err);
				this.facilitiesLoaded = false;
				this.loadingFacilities = false;
				this._snotify.error('Failed to retrieve list of all facilities', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public loadProductTypes(): void {
		if (this.loadingProductTypes) {
			return;
		}

		this.loadingProductTypes = true;

		if (this.productTypesLoaded) {
			this.productTypesLoaded = false;
		}

		this._sharedService.getProductTypes()
			.subscribe((result: SelectOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.productTypes = [...result];

				this.productTypesLoaded = true;
				this.loadingProductTypes = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all product types', err);
				this.productTypesLoaded = false;
				this.loadingProductTypes = false;
				this._snotify.error('Failed to retrieve list of all product types', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public loadProductRateTypes(): void {
		if (this.loadingProductRateTypes) {
			return;
		}

		this.loadingProductRateTypes = true;

		if (this.productRateTypesLoaded) {
			this.productRateTypesLoaded = false;
		}

		this._productRateService.getProductRateTypes()
			.subscribe((result: SelectOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.productRateTypes = [...result];

				this.productRateTypesLoaded = true;
				this.loadingProductRateTypes = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all product rate types', err);
				this.productRateTypesLoaded = false;
				this.loadingProductRateTypes = false;
				this._snotify.error('Failed to retrieve list of all product rate types', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public refreshProductRates(): void {
		if (this.loading || !this.facilitiesLoaded) {
			return;
		}

		this.loading = true;

		this.productRates = [];

		this.reportArgs = { ...this.filters };

		this._productRateService.getProductRatesReport(this.reportArgs).subscribe(
			(data: ProductRateReportItem[]) => {
				this.collectionSize = data.length;

				this.productRates = data.map((group, i) => ({ id: i + 1, ...group }));
				this.productRates = HelperService.sortArrByProp(this.productRates, this.sortField, this.sortDesc);
				this.productRates = this.productRates.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

				CacheService.setItem(CacheService.keys.PRODUCT_RATE_FILTERS, this.filters);

				this.reportLoaded = true;
				this.loading = false;
			},
			(err: any) => {
				console.error('Error getting product rates', err);
				this.loading = false;

				switch (err.status) {
					case 400:
						this._snotify.warning(err.error.message, 'Invalid Data');
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load product rates.', 'Load Failed');
						break;
				}
			}
		);
	}

	public resetFilters(): void {
		this.filters = _.cloneDeep(this.def.filters);
	}

	public search(): void {
		this.refreshProductRates();
	}

	public sortTable($event: any, col: string): void {
		if (!this.loading) {
			const target = $event.target || $event.srcElement || $event.currentTarget;
			for (let i = 0; i < target.parentElement.children.length; i++) {
				target.parentElement.children[i].classList.remove('headerSortUp');
				target.parentElement.children[i].classList.remove('headerSortDown');
			}

			this.sortDesc = !this.sortDesc;
			if (this.sortDesc) {
				target.classList.remove('headerSortUp');
				target.classList.add('headerSortDown');
			} else {
				target.classList.remove('headerSortDown');
				target.classList.add('headerSortUp');
			}
			this.sortField = col;

			this.refreshProductRates();
		}
	}
}

export class ProductRateFilters {
	public siteIdList: string[];
	public productTypeIdList: number[];
	public productRateTypeIdList: number[];
}