<div class="page-container">
    <h2 class="page-title">Product Rates</h2>
    <div class="container filters-backdrop">
        <form class="filters-container form-inline" id="productRatesFilterForm">
            <div class="form-row col-sm-12">
                <div class="col-sm-4 filter-field-container">
                    <label for="filtersSiteIdList" class="col-form-label col-sm-4 inline-block">Facilities</label>
                    <ng-select [multiple]="true" name="filterSiteIdList" [(ngModel)]="filters.siteIdList" [loading]="loadingFacilities" [disabled]="busy() || !facilitiesLoaded" class="col-sm-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let facility of filterOptions.facilities" [value]="facility.siteId">{{ facility.name }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-4 filter-field-container">
                    <label for="filtersProductTypeIdList" class="col-form-label col-sm-4 inline-block">Products</label>
                    <ng-select [multiple]="true" name="filtersProductTypeIdList" [(ngModel)]="filters.productTypeIdList" [loading]="loadingProductTypes" [disabled]="busy() || !productTypesLoaded" class="col-sm-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let productType of filterOptions.productTypes" [value]="productType.id">{{ productType.name }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-4 filter-field-container">
                    <label for="filtersProductRateTypeIdList" class="col-form-label col-sm-4 inline-block">Rate Types</label>
                    <ng-select [multiple]="true" name="filtersProductRateTypeIdList" [(ngModel)]="filters.productRateTypeIdList" [loading]="loadingProductRateTypes" [disabled]="busy() || !productRateTypesLoaded" class="col-sm-8 no-padding inline-block" ngDefaultControl>
                        <ng-option *ngFor="let productRateType of filterOptions.productRateTypes" [value]="productRateType.id">{{ productRateType.name }}</ng-option>
                    </ng-select>
                </div>
            </div>
        </form>
        <div class="filters-footer">
            <button type="button" (click)="search()" [disabled]="busy()" class="btn btn-primary float-right report-button"><span class="fa fa-search"></span> Search</button>
            <button type="button" (click)="resetFilters()" class="btn btn-primary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
        </div>
    </div>

    <div class="container results-container" *ngIf="reportLoaded || loading">
        <table class="table table-dark table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th class="sortable headerSortDown" scope="col" (click)="sortTable($event, 'facilityName')">Facility</th>
                    <th class="sortable" scope="col" (click)="sortTable($event, 'productTypeName')">Product</th>
                    <th class="sortable" scope="col" (click)="sortTable($event, 'productRateTypeName')">Rate Type</th>
                    <th class="sortable" scope="col" (click)="sortTable($event, 'rateCents')">Rate ($)</th>
                    <th scope="col">Commission (%) <span class="fas fa-question-circle" title="The percentage of the revenue the facility earns on the given product."></span></th>
                    <th scope="col">Rented Rate <span class="fas fa-question-circle" title="If enabled, indicates this entry represents one-time purchases. Otherwise, indicates this entry represents a charge every x minutes (Rate Minutes)."></span></th>
                    <th scope="col">Rate Minutes <span class="fas fa-question-circle" title="How often the Rate should be charged on the account. This is currently only applicable for tablet features when Rented Rate is enabled."></span></th>
                </tr>
            </thead>
            <tbody *ngIf="!busy() && productRates && productRates.length > 0">
                <tr *ngFor="let pr of productRates;let i = index">
                    <td scope="row">{{ pr.facilityName }}</td>
                    <td scope="row">{{ pr.productTypeName }}</td>
                    <td scope="row">{{ pr.productRateTypeName }}</td>
                    <td scope="row">{{ pr.rateCents / 100 | currency }}</td>
                    <td scope="row">{{ pr.commissionPercent }}</td>
                    <th scope="row" [ngClass]="{'color-green': pr.rentedRate, 'color-red': !pr.rentedRate}">{{ pr.rentedRate | boolYesNo }}</th>
                    <td scope="row">{{ pr.rateMinutes }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="!busy() && productRates && productRates.length === 0 && !initLoad">
                <tr>
                    <td colspan="12">No Results Found</td>
                </tr>
            </tbody>
            <tbody *ngIf="busy()" class="text-center">
                <tr>
                    <td colspan="12">
                        <div class="spinner-border text-info psi-spinner-lg" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="!busy() && productRates && productRates.length > 0" class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshProductRates()" [boundaryLinks]="true" [maxSize]="7"></ngb-pagination>
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshProductRates()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>