import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { GetUserActionsArgs, UserActionReport, UserActionReportItem, UserService } from '../services/user.service';
import { CacheService } from '../services/cache.service';
import { HelperService } from '../helpers/helper.service';
import { SelectOption, SharedService } from '../services/shared.service';

@Component({
	selector: 'app-user-actions',
	templateUrl: './user-actions.component.html'
})
export class UserActionsComponent implements OnInit {
	public page: number = 1;
	public pageSize: number = 10;
	public collectionSize: number = 0;
	public sortField: string = 'Created';
	public sortDesc: boolean = true;

	private defStart = moment().subtract(1, 'months');
	private defEnd = moment();

	private def: any = {
		filters: {
			startDate: { day: this.defStart.date(), month: this.defStart.month() + 1, year: this.defStart.year() },
			endDate: { day: this.defEnd.date(), month: this.defEnd.month() + 1, year: this.defEnd.year() },
			userName: null,
			userActionTypeIdList: []
		},
		filterOptions: {
			userActionTypes: []
		}
	};

	public filters: any = _.cloneDeep(this.def.filters);
	public filterOptions: any = _.cloneDeep(this.def.filterOptions);

	public reportItems: UserActionReportItem[] = [];
	public reportArgs: GetUserActionsArgs = new GetUserActionsArgs();

	public loadingReport: boolean = false;
	public loadingUserActionTypes: boolean = false;
	public userActionTypesLoaded: boolean = false;


	constructor(
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService,
		private readonly _userService: UserService
	) { }

	ngOnInit(): void {
		this.loadReport();
		this.loadUserActionTypes();
	}


	public copyToClipboard(val: string): void {
		let selBox = document.createElement('textarea');

		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;

		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();

		document.execCommand('copy');
		document.body.removeChild(selBox);

		this._snotify.success('Copied to Clipboard!');
	}

	public loadReport(): void {
		if (this.loadingReport) {
			return;
		}

		this.loadingReport = true;

		this.updateArgs();

		this.reportItems = [];

		this._userService.getUserActions(this.reportArgs)
			.subscribe((result: UserActionReport): void => {
				this.reportItems = result.items;
				this.collectionSize = result.totalRowCount;

				CacheService.setItem(CacheService.keys.USER_ACTION_FILTERS, this.filters);

				this.loadingReport = false;
			}, (err: any): void => {
				console.error('Failed to retrieve user actions', err);
				this.loadingReport = false;
				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load user actions.', 'Load Failed');
						break;
				}
			}, (): void => {
				//
			});
	}

	public loadUserActionTypes(): void {
		if (this.loadingUserActionTypes) {
			return;
		}

		this.loadingUserActionTypes = true;

		if (this.userActionTypesLoaded) {
			this.userActionTypesLoaded = false;
		}

		this._sharedService.getUserActionTypes()
			.subscribe((result: SelectOption[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.userActionTypes = [...result];

				this.userActionTypesLoaded = true;
				this.loadingUserActionTypes = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all user action types', err);
				this.userActionTypesLoaded = false;
				this.loadingUserActionTypes = false;
				this._snotify.error('Failed to retrieve list of all user action types', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public reset(): void {
		this.filters = _.cloneDeep(this.def.filters);
	}

	public sortTable($event: any, col: string): void {
		if (!this.loadingReport) {
			const target = $event.target || $event.srcElement || $event.currentTarget;
			for (let i = 0; i < target.parentElement.children.length; i++) {
				target.parentElement.children[i].classList.remove('headerSortUp');
				target.parentElement.children[i].classList.remove('headerSortDown');
			}

			this.sortDesc = !this.sortDesc;
			if (this.sortDesc) {
				target.classList.remove('headerSortUp');
				target.classList.add('headerSortDown');
			} else {
				target.classList.remove('headerSortDown');
				target.classList.add('headerSortUp');
			}
			this.sortField = col;

			this.loadReport();
		}
	}


	private updateArgs(): void {
		this.reportArgs = { ...this.filters };

		this.reportArgs.startDate = HelperService.getMomentDtNoTimeZone(new Date(this.filters.startDate.year, this.filters.startDate.month - 1, this.filters.startDate.day, 0, 0, 0));
		this.reportArgs.endDate = HelperService.getMomentDtNoTimeZone(new Date(this.filters.endDate.year, this.filters.endDate.month - 1, this.filters.endDate.day, 23, 59, 59));

		this.reportArgs.startIndex = this.pageSize * (this.page - 1);
		this.reportArgs.itemsPerPage = this.pageSize;
		this.reportArgs.sortField = this.sortField;
		this.reportArgs.sortOrder = this.sortDesc ? 'DESC' : 'ASC';
	}
}