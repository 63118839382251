<div class="page-container">
	<h2 class="page-title">Customers</h2>

	<div class="container large-container filters-backdrop">
		<form class="filters-container form-inline">
			<div class="form-row col-sm-12">
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterFirstName" class="col-form-label col-sm-4 inline-block">First Name</label>
					<input [(ngModel)]="filters.firstName" name="filterFirstName" id="filterFirstName" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="50" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterLastName" class="col-form-label col-sm-4 inline-block">Last Name</label>
					<input [(ngModel)]="filters.lastName" name="filterLastName" id="filterLastName" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="50" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterAddress1" class="col-form-label col-sm-4 inline-block">Address</label>
					<input [(ngModel)]="filters.address1" name="filterAddress1" id="filterAddress1" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="100" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterCity" class="col-form-label col-sm-4 inline-block">City</label>
					<input [(ngModel)]="filters.city" name="filterCity" id="filterCity" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="50" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterState" class="col-form-label col-sm-4 inline-block">State</label>
					<ng-select [items]="filterOptions.states" bindLabel="name" bindValue="abbreviation" name="filterState" [(ngModel)]="filters.state" class="form-control full-width col-sm-8 no-padding" ngDefaultControl></ng-select>
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterZip" class="col-form-label col-sm-4 inline-block">Zip</label>
					<input [(ngModel)]="filters.zip" name="filterZip" id="filterZip" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="50" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterEmail" class="col-form-label col-sm-4 inline-block">Email</label>
					<input [(ngModel)]="filters.email" name="filterEmail" id="filterEmail" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="100" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterAccountPin" class="col-form-label col-sm-4 inline-block">Account PIN</label>
					<input [(ngModel)]="filters.accountPin" name="filterAccountPin" id="filterAccountPin" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="8" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterUserName" class="col-form-label col-sm-4 inline-block">Username</label>
					<input [(ngModel)]="filters.userName" name="filterUserName" id="filterUserName" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="256" />
				</div>
				<div class="col-sm-6 col-md-4 filter-field-container">
					<label for="filterPhoneNumber" class="col-form-label col-sm-4 inline-block">Phone</label>
					<input [(ngModel)]="filters.phoneNumber" name="filterPhoneNumber" id="filterPhoneNumber" type="search" class="form-control full-width col-sm-8" (keydown.enter)="loadReport()" maxlength="20" />
				</div>
			</div>
		</form>
		<div class="filters-footer">
			<button type="button" class="btn btn-success float-left report-button" (click)="redirectToCreateCustomer()" *ngIf="hasPermission('Customers_Create')"><span class="fas fa-plus"></span> Create Customer</button>
			<button (click)="loadReport()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-search"></span> Search</button>
			<button (click)="reset()" type="button" class="btn btn-primary float-right report-button"><span class="fa fa-trash"></span> Reset</button>
		</div>
	</div>

	<div class="container large-container results-container" *ngIf="reportLoaded || loadingReport">
		<table class="table table-dark table-bordered table-sm">
			<thead>
				<tr>
					<th scope="col"></th>
					<th scope="col">ID</th>
					<th scope="col" class="sortable" (click)="sortTable($event, 'FirstName')">First Name</th>
					<th scope="col" class="sortable" (click)="sortTable($event, 'LastName')">Last Name</th>
					<th scope="col" class="sortable" (click)="sortTable($event, 'PhoneNumber')">Phone</th>
					<th scope="col" class="sortable" (click)="sortTable($event, 'Email')">Email</th>
					<th scope="col" class="sortable" (click)="sortTable($event, 'AccountPin')">Account PIN <span class="fas fa-question-circle" title="The account PIN is used to verify a customer's account."></span></th>
					<th scope="col" class="sortable" (click)="sortTable($event, 'UserName')">Username</th>
					<th scope="col">Actions</th>
					<!-- <th scope="col">Total <span class="fas fa-question-circle" title="Amount + Handling Fee + Credit Card Fee + Surcharge"></span></th> -->
				</tr>
			</thead>
			<tbody *ngIf="report.items && report.items.length > 0">
				<ng-container *ngFor="let item of report.items; let i = index">
					<tr [ngClass]="{'odd': (i % 2 == 0)}">
						<td scope="row" (click)="toggleDetails(item)">
							<span class="clickable fas" [ngClass]="{'fa-minus': item.showDetails, 'fa-plus': !item.showDetails}"></span>
						</td>
						<td scope="row">{{ item.id }}</td>
						<td scope="row">{{ item.firstName }}</td>
						<td scope="row">{{ item.lastName }}</td>
						<td scope="row">{{ item.phoneNumber | phoneNumber }}</td>
						<td scope="row">{{ item.email }}</td>
						<td scope="row">{{ item.accountPin }}</td>
						<td scope="row">{{ item.userName }}</td>
						<td scope="row">
							<span (click)="editCustomer(item)" class="fas fa-user-edit clickable" *ngIf="hasPermission('Customers_Edit')"></span>
						</td>
					</tr>
					<tr [hidden]="!item.showDetails" [ngClass]="{'odd': (i % 2 == 0)}">
						<td colspan="15" class="col-sm-12 order-report-row-details">
							<div class="col-md-4">
								<div class="bold">Address:</div>
								<div>&nbsp;&nbsp;{{ item.address1 }}</div>
							</div>
							<div class="col-md-4">
								<div class="bold">City:</div>
								<div>&nbsp;&nbsp;{{ item.city }}</div>
							</div>
							<div class="col-md-4">
								<div class="bold">State:</div>
								<div>&nbsp;&nbsp;{{ item.state }}</div>
							</div>
							<div class="col-md-4">
								<div class="bold">Zip/Postal:</div>
								<div>&nbsp;&nbsp;{{ item.zip }}</div>
							</div>
							<div class="col-md-4">
								<div class="bold">Flagged:</div>
								<div *ngIf="item.loadingGlobalNumber">
									<span class="color-orange">&nbsp;&nbsp;Checking...</span>
								</div>
								<div *ngIf="!item.loadingGlobalNumber">
									<span [ngClass]="{'color-red': isFlagged(item), 'color-green': !isFlagged(item)}">&nbsp;&nbsp;{{getFlaggedStatusLabel(item)}}</span>

									<div *ngIf="flaggingCustomer || unflaggingCustomer" class="spinner-border text-info psi-spinner-sm" role="status">
										<span class="sr-only">Loading...</span>
									</div>
									<span *ngIf="hasPermission('Customer_Unflag_Fraud') && isFlagged(item) && !flaggingCustomer && !unflaggingCustomer" (click)="openEditModal(item)" class="fas fa-flag clickable color-green">
									</span>
									<psi-modal *ngIf="hasPermission('Customer_Flag_Fraud') && !isFlagged(item) && !flaggingCustomer && !unflaggingCustomer" [settings]="item.flagAccountModalSettings" (confirmBtnClick)="confirmFlagCustomerBtnClick(item)" (cancelBtnClick)="cancelFlagCustomerBtnClick(item)" [disableConfirmBtn]="flaggingCustomer || !flagCustomerDescription" [disableCancelBtn]="flaggingCustomer" class="modal-button">
											<p>Are you sure you want to <span class="bold color-red">flag</span> the customer <span class="bold">{{ item.firstName }} {{ item.lastName }} (Phone {{ item.phoneNumber | phoneNumber }})</span> for fraud?</p>
											<br />
											<p>This will block their phone number from phone/video/SMS in the Prodigy system.</p>
											<p>Please enter a reason for flagging the customer:</p>
											<input type="text" [(ngModel)]="flagCustomerDescription" class="form-control" />
											<div *ngIf="flaggingCustomer" class="spinner-border text-info psi-spinner-lg" role="status">
													<span class="sr-only">Loading...</span>
											</div>
											<div class="col-md-4">
												<label for="blockRefund" class="inline-block">Block Refund</label>
												<input type="checkbox"
															 [(ngModel)]="blockRefund"
															 [ngModelOptions]="{standalone: true}" />
											</div>
									</psi-modal>
									<!--<psi-modal *ngIf="hasPermission('Customer_Unflag_Fraud') && isFlagged(item) && !flaggingCustomer && !unflaggingCustomer" [settings]="item.unflagAccountModalSettings" (confirmBtnClick)="confirmUnflagCustomerBtnClick(item)" (cancelBtnClick)="cancelUnflagCustomerBtnClick(item)" [disableConfirmBtn]="unflaggingCustomer" [disableCancelBtn]="unflaggingCustomer" class="modal-button">
										<p>Are you sure you want to <span class="bold color-green">unflag</span> the customer <span class="bold">{{ item.firstName }} {{ item.lastName }} (Phone {{ item.phoneNumber | phoneNumber }})</span> for fraud?</p>
										<br />
										<p>This will unblock their phone number from phone/video/SMS in the Prodigy system.</p>
										<div *ngIf="unflaggingCustomer" class="spinner-border text-info psi-spinner-lg" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</psi-modal>-->
								</div>
							</div>
							<div class="col-md-6" *ngIf="item.globalNumber" style="margin-top: 10px;">
								<div class="bold col-4" style="vertical-align: top;">Flag Description:</div>
								<div *ngIf="!hasPermission('Customers_Flag_Fraud')" class="col-8">&nbsp;&nbsp;{{ item.globalNumber.description }}</div>
								<div *ngIf="hasPermission('Customers_Flag_Fraud')" class="col-8">
									<textarea type="text" [(ngModel)]="item.globalNumber.description" class="form-control col-12" maxlength="20" style="display: inline-block;"></textarea>
									<button type="button" (click)="saveFlagDescription(item)" class="btn btn-success" [disabled]="item.savingDescription">Save Description</button>
								</div>
							</div>
							<div class="col-md-6" *ngIf="item.globalNumber" style="margin-top: 10px;">
								<div class="bold col-4" style="vertical-align: top;">Flag Notes:</div>
								<div *ngIf="!hasPermission('Customers_Flag_Fraud')" class="col-8">&nbsp;&nbsp;{{ item.globalNumber.notes }}</div>
								<div *ngIf="hasPermission('Customers_Flag_Fraud')" class="col-8">
									<textarea type="text" [(ngModel)]="item.globalNumber.notes" class="form-control col-12" maxlength="254" style="display: inline-block;"></textarea>
									<button type="button" (click)="saveFlagNotes(item)" class="btn btn-success" [disabled]="item.savingNotes">Save Notes</button>
								</div>
							</div>
						</td>
					</tr>
				</ng-container>
			</tbody>
			<tbody *ngIf="!loadingReport && report.items && report.items.length === 0">
				<tr>
					<td colspan="12">No Results Found</td>
				</tr>
			</tbody>
			<tbody *ngIf="loadingReport" class="text-center">
				<tr>
					<td colspan="12">
						<div class="spinner-border text-info psi-spinner-lg" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div *ngIf="!loadingReport && report.items && report.items.length > 0" class="d-flex justify-content-between p-2">
			<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadReport()" [boundaryLinks]="true" [maxSize]="7"></ngb-pagination>
			<select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="loadReport()">
				<option [ngValue]="10">10 items per page</option>
				<option [ngValue]="20">20 items per page</option>
				<option [ngValue]="50">50 items per page</option>
				<option [ngValue]="100">100 items per page</option>
			</select>
		</div>
	</div>
</div>
