import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { ChangeCustomerArgs, ChangeCustomerPhoneArgs, CreateCustomerNoteArgs, Customer, CustomerApproval, CustomerNote, CustomerPhoto, CustomersService, CustomerVerification, CustomerVerificationStatus, DetachPhoneNumberReturnData, GlobalNumber, PhoneNumberRestriction, VerifyCodeArgs } from 'src/app/services/customers.service';
import * as _ from 'lodash';
import { HelperService, UniqueIdType } from 'src/app/helpers/helper.service';
import { PsiModalSettings } from 'src/app/shared/components/psi-modal/psi-modal.component';
import { GetBulkRefundSummaryArgs, PaymentsService, ProcessRefundArgs, SendFundsArgs, TransferFundsArgs } from 'src/app/services/payments.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { FacilityOption, SelectOption, SharedService } from 'src/app/services/shared.service';
import { __classPrivateFieldGet } from 'tslib';
import { Observable, forkJoin } from 'rxjs';


@Component({
	selector: 'app-edit-customer',
	templateUrl: './edit-customer.component.html'
})
export class EditCustomerComponent implements OnInit {
	private customerId: number = 0;
	private def: any = {
		changePhoneArgs: {
			customerId: null,
			currentPhoneNumber: null,
			phoneNumber: null,
			userName: null
		},
		changePhoneErrors: [],
		connectedInmates: [],
		errors: {
			email: [],
			secretQuestion: [],
			secretAnswer: [],
			firstName: [],
			lastName: [],
			address1: [],
			address2: [],
			city: [],
			state: [],
			zip: [],
			//phoneNumber: [],
			accountPin: []
		},
		facilities: [],
		featureRestrictions: [],
		globalNumbers: [],
		prodigyFeatures: [],
		refundFilters: {
			accountId: null,
			amount: null
		},
		transferFilters: {
			amount: null,
			sourceAccountId: null,
			destinationAccountId: null
		},
    videoApprovals: [],
    transferFundFee: 0
	};

	public changePhoneModalSettings: PsiModalSettings = {
		openModalButtonText: 'Change Phone Number',
		title: 'Change Account Phone Number',
		spanClass: 'fas fa-phone',
		btnClass: 'btn btn-primary',
		confirmBtnText: 'Save',
		closeBtnText: 'Cancel',
		openerType: 'button',
		uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
	};

	public refundOrdersModalSettings: PsiModalSettings = {
		openModalButtonText: 'Refund Orders',
		title: 'Refund Account Orders',
		spanClass: 'fas fa-undo-alt',
		btnClass: 'btn btn-primary',
		confirmBtnText: 'Confirm',
		closeBtnText: 'Cancel',
		openerType: 'button',
		uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
	};

	public transferFundsModalSettings: PsiModalSettings = {
		openModalButtonText: 'Transfer Funds',
		title: 'Transfer Account Funds',
		spanClass: 'fas fa-exchange-alt',
		btnClass: 'btn btn-primary',
		confirmBtnText: 'Transfer',
		closeBtnText: 'Cancel',
		openerType: 'button',
		uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
		closeOnConfirm: true
  };

  public enforcePhoneCallsModalSettings: PsiModalSettings = {
    openModalButtonText: 'Disconnect From App',
    title: 'Disconnect From App',
    spanClass: 'fas fa-phone',
    btnClass: 'btn btn-warning',
    confirmBtnText: 'Confirm',
    closeBtnText: 'Cancel',
    openerType: 'button',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public resetPasswordModalSettings: PsiModalSettings = {
    openModalButtonText: "Reset Customer's Password",
    title: "Reset Customer's Password",
    spanClass: 'fas fa-lock',
    btnClass: 'btn btn-info',
    confirmBtnText: 'Confirm',
    closeBtnText: 'Cancel',
    openerType: 'button',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public detachPhoneNumberModalSettings: PsiModalSettings = {
    openModalButtonText: "Detach Phone #",
    title: "Detach Phone Number",
    spanClass: 'fas fa-trash',
    btnClass: 'btn btn-danger',
    confirmBtnText: 'Confirm',
    closeBtnText: 'Cancel',
    openerType: 'button',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public verifyPhoneNumberModalSettings: PsiModalSettings = {
    openModalButtonText: "Verify Phone #",
    title: "VerifyPhone Number",
    spanClass: 'fas fa-mark',
    btnClass: 'btn btn-primary',
    confirmBtnText: 'Confirm',
    closeBtnText: 'Cancel',
    openerType: 'button',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public driverLicensePhotoModalSettings: PsiModalSettings = {
    openModalButtonText: "Driver's License Photo",
    title: "Driver's License Photo",
    spanClass: 'fas fa-image',
    btnClass: 'btn btn-info',
    confirmBtnText: 'Ok',
    closeBtnText: 'Cancel',
    openerType: 'button',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

  public selfiePhotoModalSettings: PsiModalSettings = {
    openModalButtonText: "Selfie Photo",
    title: "Selfie Photo",
    spanClass: 'fas fa-image',
    btnClass: 'btn btn-info',
    confirmBtnText: 'Ok',
    closeBtnText: 'Cancel',
    openerType: 'button',
    uniqueId: HelperService.getUniqueId(UniqueIdType.MODAL),
    closeOnConfirm: true
  };

	public CustomerVerificationStatus = CustomerVerificationStatus;

	public changeCustomerArgs: ChangeCustomerArgs = null;
	public changePhoneArgs: ChangeCustomerPhoneArgs = _.cloneDeep(this.def.changePhoneArgs);
	public connectedInmates: CustomerApproval[] = _.cloneDeep(this.def.connectedInmates);
	public customer: Customer = null;
	public facilities: FacilityOption[] = _.cloneDeep(this.def.facilities);
	public featureRestrictions: PhoneNumberRestriction[] = _.cloneDeep(this.def.featureRestrictions);
	public globalNumbers: GlobalNumber[] = _.cloneDeep(this.def.globalNumbers);
	public newNote: string = null;
	public notes: CustomerNote[] = [];
	public pendingCustomer: Customer = null;
	public prodigyFeatures: SelectOption[] = _.cloneDeep(this.def.prodigyFeatures);
	public refundFilters: any = _.cloneDeep(this.def.refundFilters);
	public refundOrders: ProcessRefundArgs[] = [];
	public transferFilters: any = _.cloneDeep(this.def.transferFilters);
	public videoApprovals: CustomerVerification[] = _.cloneDeep(this.def.videoApprovals);
  public transferFundFee: number = _.cloneDeep(this.def.transferFundFee);
  public transferFundFeeLabel: string = 'N/A';

  public selfiePhoto: CustomerPhoto = null;
  public driverLicensePhoto: CustomerPhoto = null;


	public changePhoneErrors: any = _.cloneDeep(this.def.changePhoneErrors);
	public errors: any = _.cloneDeep(this.def.errors);

	public filterOptions: any = {
		states: HelperService.getUsStateList(),
		sourceAccounts: [],
		destinationAccounts: [],
		refundAccounts: []
  };

  public verificationCode: string = '';

	public customerEditable: boolean = false;
	public loadingConnectedInmates: boolean = false;
	public loadingCustomer: boolean = false;
	public loadingFacilities: boolean = false;
	public loadingFeatureRestrictions: boolean = false;
	public loadingGlobalNumber: boolean = false;
	public loadingNotes: boolean = false;
	public loadingProdigyFeatures: boolean = false;
	public loadingRefundSummary: boolean = false;
	public loadingVideoApprovals: boolean = false;
	public loadingTransferFundFee: boolean = false;
	public loadingSelfiePhoto: boolean = false;
	public loadingDriverLicensePhoto: boolean = false;
	public refundingOrders: boolean = false;
	public refundSummaryLoaded: boolean = false;
	public savingChangePhone: boolean = false;
	public savingCustomer: boolean = false;
	public savingNote: boolean = false;
  public transferringFunds: boolean = false;

  public isCodeSent: boolean = false;
  public isCustomerVerified: boolean = false;

  private accountNames: Record<number, string> = {
    1: 'PPC Voice/Video',
    2: 'PPC Text/Email'
  }; 
	//public customerString: string = null;
  private driverLicenseFound = false;
  private selfiePhotoFound = false;

	constructor(
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _customersService: CustomersService,
		private readonly _paymentsService: PaymentsService,
		private readonly _router: Router,
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService,
		private _tokenStorage: TokenStorageService
	) { }

	ngOnInit(): void {
    this.customerId = parseInt(this._activatedRoute.snapshot.paramMap.get("id"));
    //this.getCustomerDriverLicense();
    //this.getCustomerSelfiePhoto();
		// Facilities and prodigyfeatures need to load before feature restrictions
		const tasks: Observable<any>[] = [];
		tasks.push(this.loadFacilities());
    tasks.push(this.loadProdigyFeatures());
		forkJoin(tasks).subscribe(() => {
      this.loadFeatureRestrictions(this.customerId);
      this.loadGlobalNumber(this.customerId);
      this.loadVideoApprovals(this.customerId);
      this.loadNotes(this.customerId);
      this.loadCustomer(this.customerId);
      this.loadConnectedInmates(this.customerId);
      this.loadTransferFundFee();
      
    });

    setTimeout(() => {
      this.loadCustomerSelfiePhoto();
      this.loadCustomerDriverLicense();
    }, 3000);
	}


	public hasPermission(permission: string): boolean {
		return this._tokenStorage.hasPermission(permission);
	}

	public cancel(): void {
		this.pendingCustomer = _.cloneDeep(this.customer);
		this.errors = _.cloneDeep(this.def.errors);
		this.customerEditable = false;
	}

	public async confirmChangePhoneBtnClick() {
		if (this.savingChangePhone) {
			return;
		}

		this.savingChangePhone = true;

    if (this.validateChangePhone()) {
      try {
        const newCustomerId: number = await this._customersService.changeCustomerPhone(this.changePhoneArgs).toPromise();
        this._snotify.success('Customer phone changed!', 'Change Success');

        // conduct automatic funds transfer
        const oldAccountInfo: Customer = await this._customersService.getCustomerByPhone(this.changePhoneArgs.currentPhoneNumber).toPromise();
        const newAccountInfo: Customer = await this._customersService.getCustomer(newCustomerId).toPromise();
        console.log(oldAccountInfo);
        console.log(newAccountInfo);
        
        let sendFundsArgs: SendFundsArgs = new SendFundsArgs();
        sendFundsArgs.sourceUserType = 2;
        sendFundsArgs.sourcePhoneNumber = this.changePhoneArgs.currentPhoneNumber;
        sendFundsArgs.sourceAccountId = 0; //variable
        sendFundsArgs.destinationUserType = 2;
        sendFundsArgs.destinationPhoneNumber = this.changePhoneArgs.phoneNumber;
        sendFundsArgs.destinationAccountId = 0; // variable
        sendFundsArgs.amountCents = 0;
        
        const accounts = oldAccountInfo.customerAccounts.filter(x => x.account.accountType.id === 1 || x.account.accountType.id === 2);
        if (accounts.length !== 2) {
          this._snotify.warning('Warning.', 'Could not automatically transfer funds to new account. Try to do it manually.');
        }
        else {
          for (let i = 0; i < oldAccountInfo.customerAccounts.length; i++) {
            let account = oldAccountInfo.customerAccounts[i];
            if (!(account.account.balance > 0))
              continue;

            let accountId: number = account.account.accountType.id;
            let accountName: string = this.accountNames[accountId];

            let targetAccount = newAccountInfo.customerAccounts.find(x => x.account.accountType.id === accountId);
            if (!targetAccount)
              continue;

            let sourceAccountId = account.accountId;
            let destinationAccountId = targetAccount.accountId;

            sendFundsArgs.sourceAccountId = sourceAccountId; 
            sendFundsArgs.destinationAccountId = destinationAccountId;
            sendFundsArgs.amountCents = Math.round(account.account.balance * 100);

            try {
              await this._paymentsService.sendFunds(sendFundsArgs).toPromise();
              this._snotify.success(`Automatically transferred funds from ${accountName} account.`, 'Success');
            } catch (err) {
              console.error(err);
              this._snotify.error(`Could not automatically transfer funds from ${accountName} account.`, 'Send Funds Error');
            }
          }
        }
        

        this.savingChangePhone = false;
        //this._router.navigate(['/customers/edit', newCustomerId]);
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([`/customers/edit/${newCustomerId}`]));

      } catch (err) {
        console.error('Failed to change customer phone number', err);

        switch (err.status) {
          case 400:
            HelperService.getModelStateErrors(err).forEach(errMsg => {
              this._snotify.warning(errMsg, 'Invalid Data');
            });
            break;
          default:
            this._snotify.error('An unexpected error occurred attempting to change customer phone number.', 'Change Failed');
            break;
        }

        this.changePhoneArgs.phoneNumber = null;
        this.changePhoneArgs.userName = null;

        this.savingChangePhone = false;
      }
		} else {
			this.savingChangePhone = false;
		}

		console.log('Save change phone clicked');
	}

	public cancelChangePhoneBtnClick(): void {
		console.log('Cancel change phone button clicked');
	}

	public cancelRefundOrdersBtnClick(): void {
		this.resetBulkRefund();
	}

	public cancelTransferFundsBtnClick(): void {
		this.transferFilters = _.cloneDeep(this.def.transferFilters);
	}

	public createNote(): void {
		if (this.savingNote) {
			return;
		}

		this.savingNote = true;

		if (this.validateNewNote()) {
			const args: CreateCustomerNoteArgs = {
				customerId: this.customerId,
				note: this.newNote
			};

			this._customersService.createCustomerNote(args)
				.subscribe((): void => {
					this._snotify.success('Customer note created');
					this.savingNote = false;
					this.newNote = null;
					this.loadNotes(this.customerId);
				}, (err: any): void => {
					console.error('Failed to create customer note', err);
					this._snotify.error('An unexpected error occurred attempting to create the customer note.', 'Create Failed');
					this.savingNote = false;
				}, (): void => {
					//
				});
		} else {
			this.savingNote = false;
		}
	}

	public edit(): void {
		this.customerEditable = true;
	}

	public loadCustomer(id: number): void {
		if (this.loadingCustomer) {
			return;
		}

		this.loadingCustomer = true;

		this.customer = null;
		this.pendingCustomer = null;

		this._customersService.getCustomer(id)
			.subscribe((result: Customer): void => {
				this.customer = _.cloneDeep(result);
				console.log(result);
				this.pendingCustomer = _.cloneDeep(result);

				this.changePhoneArgs.customerId = this.customer.id;
				this.changePhoneArgs.currentPhoneNumber = this.customer.phoneNumber;

				for (let i = 0; i < this.customer.customerAccounts.length; i++) {
					switch (this.customer.customerAccounts[i].account.accountType.id) {
						case 1: // PPC Voice
							this.customer.customerAccounts[i].account.accountType.description = 'PPC Voice/Video';
							break;
						case 2: // PPC SMS
							this.customer.customerAccounts[i].account.accountType.description = 'PPC Text/Email';
							break;
						default:
							break;
					}
				}

				this.filterOptions.sourceAccounts = _.cloneDeep(this.customer.customerAccounts);
				this.filterOptions.destinationAccounts = _.cloneDeep(this.customer.customerAccounts);
				this.filterOptions.refundAccounts = _.cloneDeep(this.customer.customerAccounts);

				for (let i = 0; i < this.filterOptions.sourceAccounts.length; i++) {
					this.filterOptions.sourceAccounts[i].display = this.filterOptions.sourceAccounts[i].account.accountType.description + ' (Balance: $' + this.filterOptions.sourceAccounts[i].account.balance + ')';
					this.filterOptions.sourceAccounts[i].id = this.filterOptions.sourceAccounts[i].account.id;
				}

				for (let i = 0; i < this.filterOptions.destinationAccounts.length; i++) {
					this.filterOptions.destinationAccounts[i].display = this.filterOptions.destinationAccounts[i].account.accountType.description + ' (Balance: $' + this.filterOptions.destinationAccounts[i].account.balance + ')';
					this.filterOptions.destinationAccounts[i].id = this.filterOptions.destinationAccounts[i].account.id;
				}

				for (let i = 0; i < this.filterOptions.refundAccounts.length; i++) {
					this.filterOptions.refundAccounts[i].display = this.filterOptions.refundAccounts[i].account.accountType.description + ' (Balance: $' + this.filterOptions.refundAccounts[i].account.balance + ')';
					this.filterOptions.refundAccounts[i].id = this.filterOptions.refundAccounts[i].account.id;
				}

				this.loadingCustomer = false;
			}, (err: any): void => {
				console.error('Failed to retrieve customer data', err);
				this._snotify.error('An unexpected error occurred attempting to load customer data.', 'Load Failed');
				this.loadingCustomer = false;
			}, (): void => {
				//
			});
	}

	public loadConnectedInmates(id: number): void {
		if (this.loadingConnectedInmates) {
			return;
		}

		if (!this.hasPermission('Customers_Connected_Inmates_View')) {
			return;
		}

		this.loadingConnectedInmates = true;

		this.connectedInmates = _.cloneDeep(this.def.connectedInmates);

		this._customersService.getConnectedInmates(id)
			.subscribe((result: CustomerApproval[]): void => {
				console.log("THIS ONE!!!");
				//console.log(result);
				this.connectedInmates = result;

				this.loadingConnectedInmates = false;
			}, (err: any): void => {
				console.error('Failed to retrieve customer connected inmates', err);
				this._snotify.error('An unexpected error occurred attempting to load customer connected inmates.', 'Load Failed');
				this.loadingConnectedInmates = false;
			}, (): void => {
				//
			});
	}

	public loadFacilities(): Observable<FacilityOption[]> {
		if (this.loadingFacilities) {
			return;
		}

		this.loadingFacilities = true;

		this.facilities = _.cloneDeep(this.def.facilities);

		return new Observable((observer) => {
			this._sharedService.getFacilities()
			.subscribe((result: FacilityOption[]): void => {
				this.facilities = result;

				this.loadingFacilities = false;

				observer.next();
				observer.complete();
			}, (err: any): void => {
				console.error('Failed to retrieve facilities', err);
				this._snotify.error('An unexpected error occurred attempting to load facilities.', 'Load Failed');
				this.loadingFacilities = false;
				observer.error();
			});
		});
	}

	public loadFeatureRestrictions(id: number): void {
		if (this.loadingFeatureRestrictions) {
			return;
		}

		if (!this.hasPermission('Customers_Connected_Inmates_View')) {
			return;
		}

		this.loadingFeatureRestrictions = true;

		this.featureRestrictions = _.cloneDeep(this.def.featureRestrictions);

		this._customersService.getFeatureRestrictions(id)
			.subscribe((result: PhoneNumberRestriction[]): void => {
				this.featureRestrictions = result;

				for (let i = 0; i < this.featureRestrictions.length; i++){
					this.featureRestrictions[i].facilityName = HelperService.getFacilityName(this.facilities, this.featureRestrictions[i].siteId);
					this.featureRestrictions[i].prodigyFeatureName = HelperService.getOptionName(this.prodigyFeatures, this.featureRestrictions[i].prodigyFeature);
				}

				this.featureRestrictions = HelperService.sortArrByProp(this.featureRestrictions, 'prodigyFeatureName', false);

				this.loadingFeatureRestrictions = false;
			}, (err: any): void => {
				console.error('Failed to retrieve customer feature restrictions', err);
				this._snotify.error('An unexpected error occurred attempting to load customer feature restrictions.', 'Load Failed');
				this.loadingFeatureRestrictions = false;
			}, (): void => {
				//
			});
	}

	public loadGlobalNumber(customerId: number): void {
		if (this.loadingGlobalNumber) {
			return;
		}

		this.loadingGlobalNumber = true;

    this._customersService.getRestrictedGlobalNumbers(customerId)
			.subscribe((result: GlobalNumber[]): void => {
				this.globalNumbers = result;
				this.loadingGlobalNumber = false;
			}, (err: any): void => {
				console.error('Failed to retrieve customer global number', err);
				this.loadingGlobalNumber = false;
				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load the customer\'s flagged status.', 'Load Failed');
						break;
				}
			}, (): void => {
				//
			});
  }

  public loadTransferFundFee(): void {
    if (this.loadingTransferFundFee) {
      return;
    }

    if (!this.hasPermission('Customers_Transfer_Funds')) {
      return;
    }

    this.loadingTransferFundFee = true;

    this.transferFundFee = _.cloneDeep(this.def.transferFundFee);

    this._paymentsService.getTransferFundFee()
      .subscribe((fee: number): void => { // cents
        this.transferFundFee = fee;
        this.transferFundFeeLabel = this.getMoneyLabel(fee);
        this.loadingTransferFundFee = false;
      }, (err: any): void => {
        console.error('Failed to retrieve Transfer Fund Fee', err);
        this._snotify.error('An unexpected error occurred attempting to load Transfer Fund Fee.', 'Load Failed');
        this.loadingTransferFundFee = false;
      }, (): void => {
        //
      });
  }

  public isGloballyRestricted(): boolean {
    if (this.globalNumbers.length === 0) return false;

    const globalNumber = this.globalNumbers.find(x => x.siteId.toUpperCase() === "GLOBAL");
    return (globalNumber) ? true : false;
  }

  public isRestricted(): boolean {
    return (this.globalNumbers.length > 0);
  }

  public getGlobalRestrictionReason(): string {
    const globalNumber = this.globalNumbers.find(x => x.siteId.toUpperCase() === "GLOBAL");
    return globalNumber.description;
  }

	public loadNotes(customerId: number): void {
		if (this.loadingNotes) {
			return;
		}

		this.loadingNotes = true;

		this._customersService.getCustomerNotes(customerId)
			.subscribe((result: CustomerNote[]): void => {
				this.notes = result;
				this.loadingNotes = false;
			}, (err: any): void => {
				console.error('Failed to retrieve customer notes', err);
				this.loadingNotes = false;
				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load the customer\'s notes.', 'Load Failed');
						break;
				}
			}, (): void => {
				//
			});
	}

	public loadProdigyFeatures(): Observable<SelectOption[]> {
		if (this.loadingProdigyFeatures) {
			return;
		}

		this.loadingProdigyFeatures = true;

		this.facilities = _.cloneDeep(this.def.facilities);

		return new Observable((observer) => {
			this._sharedService.getProdigyFeatures()
			.subscribe((result: SelectOption[]): void => {
				this.prodigyFeatures = result;

				this.loadingProdigyFeatures = false;

				observer.next();
				observer.complete();
			}, (err: any): void => {
				console.error('Failed to retrieve prodigy features', err);
				this._snotify.error('An unexpected error occurred attempting to load prodigy features.', 'Load Failed');
				this.loadingProdigyFeatures = false;
				observer.error();
			});
		});
	}

	public loadRefundSummary(): void {
		if (this.loadingRefundSummary) {
			return;
		}

		this.loadingRefundSummary = true;

		const args: GetBulkRefundSummaryArgs = {
			accountId: this.refundFilters.accountId,
			customerId: this.customerId,
			amount: this.refundFilters.amount
		};

		this._paymentsService.getBulkRefundSummary(args)
			.subscribe((result: ProcessRefundArgs[]): void => {
				this.refundOrders = result;
				this.loadingRefundSummary = false;
				this.refundSummaryLoaded = true;
			}, (err: any): void => {
				console.error('Failed to load bulk refund summary', err);

				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting to load bulk refund summary.', 'Load Failed');
						break;
				}

				this.loadingRefundSummary = false;
				this.refundSummaryLoaded = false;
			});
	}

	public loadVideoApprovals(id: number): void {
		if (this.loadingVideoApprovals) {
			return;
		}

		if (!this.hasPermission('Customers_Video_Approvals_View')) {
			return;
		}

		this.loadingVideoApprovals = true;

		this.connectedInmates = _.cloneDeep(this.def.connectedInmates);

		this._customersService.getVideoApprovals(id)
			.subscribe((result: CustomerVerification[]): void => {
				this.videoApprovals = result;
				//console.log(this.videoApprovals);

				this.loadingVideoApprovals = false;
			}, (err: any): void => {
				console.error('Failed to retrieve customer video approvals', err);
				this._snotify.error('An unexpected error occurred attempting to load customer video approvals.', 'Load Failed');
				this.loadingVideoApprovals = false;
			}, (): void => {
				//
			});
	}

	public processBulkRefund(): void {
		if (this.refundingOrders) {
			return;
		}

		this.refundingOrders = true;

		this._paymentsService.processBulkRefund(this.refundOrders)
			.subscribe((result: any): void => {
				this._snotify.success('Bulk refund processed successfully!');
				this.refundingOrders = false;
				this.resetBulkRefund();
				this.loadCustomer(this.customerId); // Reload the customer and accounts to get the new account balances
			}, (err: any): void => {
				console.error('Failed to process bulk refund', err);

				switch (err.status) {
					case 400:
						HelperService.getModelStateErrors(err).forEach(errMsg => {
							this._snotify.warning(errMsg, 'Invalid Data');
						});
						break;
					default:
						this._snotify.error('An unexpected error occurred attempting process bulk refund.', 'Refunds Failed');
						break;
				}

				this.refundingOrders = false;
				this.resetBulkRefund();
			});
	}

	public resetBulkRefund(): void {
		this.refundFilters = _.cloneDeep(this.def.refundFilters);
		this.refundSummaryLoaded = false;
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	public save(): void {
		if (this.savingCustomer) {
			return;
		}

		this.savingCustomer = true;

		this.updateChangeCustomerArgs();

		if (this.validatePendingCustomer()) {
			this._customersService.changeCustomer(this.changeCustomerArgs)
				.subscribe((): void => {
					this.loadCustomer(this.customerId);
					this.customerEditable = false;
					this.savingCustomer = false;

					this._snotify.success('Customer changes saved!', 'Save Success');
				}, (err: any): void => {
					console.error('Failed to save customer changes', err);
					this._snotify.error('An unexpected error occurred save customer changes.', 'Save Failed');
					this.pendingCustomer = _.cloneDeep(this.customer);
					this.customerEditable = false;
					this.savingCustomer = false;
				}, (): void => {
					//
				});
		} else {
			this.savingCustomer = false;
		}
	}

	public transferFunds(): void {
		if (this.transferringFunds) {
			return;
		}

		this.transferringFunds = true;

		const transferArgs: TransferFundsArgs = {
			customerId: this.customerId,
			siteId: null,
			amount: this.transferFilters.amount,
			phoneNumber: this.customer.phoneNumber,
			sourceAccountId: this.transferFilters.sourceAccountId,
			destinationAccountId: this.transferFilters.destinationAccountId
		};

		if (this.validateTransferFundsArgs()) {
			this._paymentsService.transferFunds(transferArgs)
				.subscribe((): void => {
					this.loadCustomer(this.customerId);

					this._snotify.success('Funds transfer completed successfully!', 'Transfer Success');
					this.transferringFunds = false;
				}, (err: any): void => {
					console.error('Failed to transfer funds', err);

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred transfering funds.', 'Transfer Failed');
							break;
					}

					this.transferringFunds = false;
				}, (): void => {
					//
				});
		} else {
			this.transferringFunds = false;
		}
	}

  public async enforcePhoneCalls() {
    try {
      const customerId: number = this.customer.id;
      const success: boolean = await this._customersService.enforcePhoneCalls(customerId).toPromise();
      if (success) {
        this._snotify.success('Successfully enforced phone calls', 'Success');
      } else {
        this._snotify.error('Failed to enforce phone calls', 'Error');
      }
    }
    catch (err) {
      console.error(err);
      this._snotify.error('Failed to enforce phone calls', 'Error');
    }
  }

	private updateChangeCustomerArgs(): void {
		this.changeCustomerArgs = new ChangeCustomerArgs();
		this.changeCustomerArgs.id = this.pendingCustomer.id;
		this.changeCustomerArgs.firstName = this.pendingCustomer.firstName;
		this.changeCustomerArgs.lastName = this.pendingCustomer.lastName;
		this.changeCustomerArgs.email = this.pendingCustomer.email;
		//this.changeCustomerArgs.phoneNumber = HelperService.stripNonNumeric(this.pendingCustomer.phoneNumber);
		this.changeCustomerArgs.address1 = this.pendingCustomer.address1;
		this.changeCustomerArgs.address2 = this.pendingCustomer.address2;
		this.changeCustomerArgs.city = this.pendingCustomer.city;
		this.changeCustomerArgs.state = this.pendingCustomer.state;
		this.changeCustomerArgs.zip = this.pendingCustomer.zip;
		this.changeCustomerArgs.accountPin = this.pendingCustomer.accountPin;
  }

  public async resetCustomerPassword() {
    try {
      const customerId: number = this.customer.id;
      const success: boolean = await this._customersService.resetCustomerPassword(customerId).toPromise();
      if (success) {
        this._snotify.success("Successfully reset customer's password", 'Success');
      } else {
        this._snotify.error("Failed to reset customer's password", 'Error');
      }
    }
    catch (err) {
      console.error(err);
      this._snotify.error("Failed to reset customer's password", 'Error');
    }
  }

	private validateChangePhone(): boolean {
		let returnValue: boolean = true;

		this.changePhoneErrors = _.cloneDeep(this.def.changePhoneErrors);

		// Validate new phone number
		if (!this.changePhoneArgs.phoneNumber) {
			this.changePhoneErrors.push('Phone Number is required');
			returnValue = false;
		} else if (this.changePhoneArgs.phoneNumber.length < 10 || this.changePhoneArgs.phoneNumber.length > 20) {
			this.changePhoneErrors.push('Invalid Phone Number');
			returnValue = false;
		}

		// Validate userName
		// Username is only needed if this is already a child account
		if (this.customer.parentCustomerId && this.customer.parentCustomerId > 0) {
			if (!this.changePhoneArgs.userName) {
				this.changePhoneErrors.push('Username is required');
				returnValue = false;
			} else if (this.changePhoneErrors.userName.length < 5 || this.changePhoneArgs.userName.length > 256) {
				this.changePhoneErrors.push('Username must be at least 5 characters long.');
				returnValue = false;
			}
		}

		return returnValue;
	}

	private validateNewNote(): boolean {
		let returnValue: boolean = true;

		if (!this.newNote) {
			this._snotify.warning('Please enter a note');
			returnValue = false;
		} else if (this.newNote.length > 1000) {
			this._snotify.warning('Note cannot exceed 1000 characters');
			returnValue = false;
		}

		return returnValue;
	}

	private validatePendingCustomer(): boolean {
		let returnValue: boolean = true;

		this.errors = _.cloneDeep(this.def.errors);

		// Validate email
		if (this.changeCustomerArgs.email !== this.customer.email) {
			if (!this.changeCustomerArgs.email) {
				this.errors.email.push('Email Address is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.email.length < 1 || this.changeCustomerArgs.email.length > 256) {
				this.errors.email.push('Email Address must be between 5 and 256 characters in length');
				returnValue = false;
			} else if (!HelperService.isValidEmailAddress(this.changeCustomerArgs.email)) {
				this.errors.email.push('Email Address is invalid');
				returnValue = false;
			}
		}

		// Validate first name
		if (this.changeCustomerArgs.firstName !== this.customer.firstName) {
			if (!this.changeCustomerArgs.firstName) {
				this.errors.firstName.push('First Name is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.firstName.length < 1 || this.changeCustomerArgs.firstName.length > 50) {
				this.errors.firstName.push('First Name cannot exceed 50 characters in length');
				returnValue = false;
			}
		}

		// Validate last name
		if (this.changeCustomerArgs.lastName !== this.customer.lastName) {
			if (!this.changeCustomerArgs.lastName) {
				this.errors.lastName.push('Last Name is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.lastName.length < 1 || this.changeCustomerArgs.lastName.length > 50) {
				this.errors.lastName.push('Last Name cannot exceed 50 characters in length');
				returnValue = false;
			}
		}

		// Validate address 1
		if (this.changeCustomerArgs.address1 !== this.customer.address1) {
			if (!this.changeCustomerArgs.address1) {
				this.errors.address1.push('Address 1 is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.address1.length < 1 || this.changeCustomerArgs.address1.length > 100) {
				this.errors.address1.push('Address 1 cannot exceed 100 characters in length');
				returnValue = false;
			}
		}

		// Validate address 2
		if (this.changeCustomerArgs.address2 !== this.customer.address2) {
			if (this.changeCustomerArgs.address2) {
				if (this.changeCustomerArgs.address2.length < 1 || this.changeCustomerArgs.address2.length > 100) {
					this.errors.address2.push('Address 2 cannot exceed 100 characters in length');
					returnValue = false;
				}
			}
		}

		// Validate city
		if (this.changeCustomerArgs.city !== this.customer.city) {
			if (!this.changeCustomerArgs.city) {
				this.errors.city.push('City is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.city.length < 1 || this.changeCustomerArgs.city.length > 50) {
				this.errors.city.push('City cannot exceed 50 characters in length');
				returnValue = false;
			}
		}

		// Validate state
		if (!this.changeCustomerArgs.state) {
			this.errors.state.push('State is required');
			returnValue = false;
		}

		// Validate Postal/Zip
		if (this.changeCustomerArgs.zip !== this.customer.zip) {
			if (!this.changeCustomerArgs.zip) {
				this.errors.zip.push('Postal/Zip is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.zip.length > 10) {
				this.errors.zip.push('Postal/Zip cannot exceed 10 characters in length');
				returnValue = false;
			}
		}

		// Validate Phone Number
		// if (this.changeCustomerArgs.phoneNumber !== this.customer.phoneNumber) {
		// 	if (!this.changeCustomerArgs.phoneNumber) {
		// 		this.errors.phoneNumber.push('Phone Number is required');
		// 		returnValue = false;
		// 	} else {
		// 		if (this.changeCustomerArgs.phoneNumber.length < 10 || this.changeCustomerArgs.phoneNumber.length > 20) {
		// 			this.errors.phoneNumber.push('Invalid Phone Number');
		// 			returnValue = false;
		// 		}
		// 	}
		// }

		// Validate account PIN
		if (this.changeCustomerArgs.accountPin !== this.customer.accountPin) {
			if (!this.changeCustomerArgs.accountPin) {
				this.errors.accountPin.push('Account PIN is required');
				returnValue = false;
			} else if (this.changeCustomerArgs.accountPin.length < 1 || this.changeCustomerArgs.accountPin.length > 8) {
				this.errors.accountPin.push('Account PIN cannot exceed 50 characters in length');
				returnValue = false;
			}
		}

		return returnValue;
	}

	private validateTransferFundsArgs(): boolean {
		let returnValue: boolean = true;

		if (!this.transferFilters.amount) {
			this._snotify.warning('Please enter an amount to transfer');
			returnValue = false;
		} else if (!this.transferFilters.sourceAccountId || !this.transferFilters.destinationAccountId) {
			this._snotify.warning('Please select a source account and a destination account');
			returnValue = false;
		} else {
			let selectedSourceAccount = null;
			for (let i = 0; i < this.customer.customerAccounts.length; i++) {
				if (this.customer.customerAccounts[i].accountId === this.transferFilters.sourceAccountId) {
					selectedSourceAccount = this.customer.customerAccounts[i];
				}
			}

			if (selectedSourceAccount) {
				if (selectedSourceAccount.account.balance < this.transferFilters.amount) {
					this._snotify.warning('The transfer amount cannot be greater than the source account balance');
					returnValue = false;
				}
			} else {
				this._snotify.warning('Invalid source account selected');
				returnValue = false;
			}
		}

		return returnValue;
  }

  public getMoneyLabel(cents: number): string {
    const dollars: string = (cents / 100).toFixed(2);
    return dollars;
  }

  public getSourceMaxAmount(): number {
    const sourceAccountId = this.transferFilters.sourceAccountId;
    if (!sourceAccountId) {
      return 0.0;
    }

    const sourceAccount = this.customer.customerAccounts.find(x => x.accountId === sourceAccountId);
    if (!sourceAccount) {
      return 0.0;
    }
    const maxAmount = sourceAccount.account.balance - (this.transferFundFee / 100);
    return maxAmount;
  }

  public loadCustomerDriverLicense(): void {
    
    if (this.loadingDriverLicensePhoto)
      return;

    if (this.driverLicensePhoto) {
      return;
    }

    this.loadingDriverLicensePhoto = true;
    const customerId: number = this.customerId;

    this._customersService.getCustomerDriverLicensePhoto(customerId)
      .subscribe((driverLicensePhoto: CustomerPhoto): void => {
        if (!driverLicensePhoto) {
          this.driverLicenseFound = false;
          this._snotify.warning("Customer's has no driver license photo.");
          return;
        }
        const base64: string = `data:${driverLicensePhoto.mimeType};base64,${driverLicensePhoto.imageBase64String}`;
        driverLicensePhoto.data = base64;
        this.driverLicensePhoto = _.cloneDeep(driverLicensePhoto);
        this.driverLicenseFound = true;
        this._snotify.success("Successfully loaded customer's driver license photo.");
        this.loadingDriverLicensePhoto = false;
    }, (err: any): void => {
        console.error(err);
        this._snotify.error("Failed to load customer's driver license photo.");
        this.driverLicenseFound = false;
        this.loadingDriverLicensePhoto = false;
    }, (): void => {
      //
    });
  }

  public loadCustomerSelfiePhoto(): void {

    if (this.loadingSelfiePhoto)
      return;

    if (this.selfiePhoto) {
      return;
    }

    this.loadingSelfiePhoto = true;
    const customerId: number = this.customerId;

    this._customersService.getCustomerSelfiePhoto(customerId)
      .subscribe((selfiePhoto: CustomerPhoto): void => {
        if (!selfiePhoto) {
          this.selfiePhotoFound = false;
          this._snotify.warning("Customer's has no selfie photo.");
          return;
        }
        const base64: string = `data:${selfiePhoto.mimeType};base64,${selfiePhoto.imageBase64String}`;
        selfiePhoto.data = base64;
        this.selfiePhoto = _.cloneDeep(selfiePhoto);
        this.selfiePhotoFound = true;
        this._snotify.success("Successfully loaded customer's selfie photo.");
        this.loadingSelfiePhoto = false;
      }, (err: any): void => {
        console.error(err);
        this._snotify.error("Failed to load customer's selfie photo.");
        this.selfiePhotoFound = false;
        this.loadingSelfiePhoto = false;
      }, (): void => {
        //
      });
  }

  public async sendVerificationCode() {
    console.log('clicked');
    this.isCodeSent = true;
    try {
      await this._customersService.send2FA(this.customerId).toPromise();
    } catch (err) {
      this._snotify.error('Cannot send verification codes at this point of time. Contact Prodigy team.');
      console.log(err);
    }
  }

  public async verifyCode() {
    try {
      const args: VerifyCodeArgs = {
        customerId: this.customerId,
        code: this.verificationCode
      };

      const success = await this._customersService.verifyCode(args).toPromise();
      if (success) {
        this._snotify.success('Phone number ownership was verified', 'Success');
        this.isCustomerVerified = true;
      } else {
        this._snotify.error('Wrong code.');
      }
    } catch (err) {
      this._snotify.error('Wrong code or server error.');
      console.log(err);
    }
  }

  public isCodeInputValid(): boolean {
    return (!this.isCodeSent || this.verificationCode.length === 6);
  }

  public async detachPhone() {
    try {
      const result: DetachPhoneNumberReturnData = await this._customersService.detachPhoneNumber(this.customerId).toPromise();
      if (result.errorMsgs.length > 0) {
        // partial success, display errors
        this._snotify.warning("Detach Phone Number request partially succeeded with minor errors. Contact Prodigy");
        for (let i = 0; i < result.errorMsgs.length; i++) {
          const errorMsgs = result.errorMsgs[i];
          this._snotify.error(errorMsgs);
        }
      } else {
        this._snotify.success('Detach Phone Number was successful');
      }
      this._router.navigate(["/customers/search"]);

    } catch (err) {
      console.log(err);
      this._snotify.error("Failed to detach customer's phone number. Contact Prodigy!");
    }
  }

}
