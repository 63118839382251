<div class="page-container">
    <h2 class="page-title">User</h2>

    <div class="container large-container col-12">
        <div class="col-12" style="display:inline-block">
            <div class="filters-backdrop col-12">
                <div *ngIf="loadingUser || savingUser || !pendingUser || loadingRoles || !rolesLoaded" class="text-center">
                    <div class="spinner-border text-info psi-spinner-lg" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <form *ngIf="!loadingUser && pendingUser && !loadingRoles && rolesLoaded" class="filters-container form-inline align-items-flex-start">
                    <div class="col-12">
                        <div class="form-row col-12">
                            <div class="col-12 col-md-6 filter-field-container">
                                <label for="userUsername" class="inline-block">Username</label>
                                <input [(ngModel)]="pendingUser.userName" name="userUsername" id="userUsername" type="text" class="form-control full-width col-12" [disabled]="true" />
                            </div>
                            <div class="col-12 col-md-6 filter-field-container">
                                <label for="userEmail" class="inline-block">Email Address</label>
                                <input [(ngModel)]="pendingUser.membership.email" name="userEmail" id="userEmail" (input)="resetErrors('email')" type="text" class="form-control full-width col-12" [disabled]="savingUser" />
                                <div *ngIf="errors.email && errors.email.length > 0" class="psi-alert psi-alert-danger">
                                    <div *ngFor="let err of errors.email; let i = index">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 filter-field-container">
                                <label for="userComment" class="inline-block">Comment</label>
                                <input [(ngModel)]="pendingUser.membership.comment" name="userComment" id="userComment" (input)="resetErrors('comment')" type="text" class="form-control full-width col-12" [disabled]="savingUser" />
                                <div *ngIf="errors.comment && errors.comment.length > 0" class="psi-alert psi-alert-danger">
                                    <div *ngFor="let err of errors.comment; let i = index">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 filter-field-container">
                                <label for="userMobilePin" class="inline-block">Mobile PIN</label>
                                <input [(ngModel)]="pendingUser.membership.mobilePin" name="userMobilePin" id="userMobilePin" (input)="resetErrors('mobilePin')" type="text" class="form-control full-width col-12" [disabled]="savingUser" />
                                <div *ngIf="errors.mobilePin && errors.mobilePin.length > 0" class="psi-alert psi-alert-danger">
                                    <div *ngFor="let err of errors.mobilePin; let i = index">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 filter-field-container">
                                <label for="userRole" class="inline-block">Role</label>
                                <ng-select [items]="filterOptions.roles" bindLabel="name" bindValue="id" name="userRole" [(ngModel)]="pendingUser.roleId" (change)="resetErrors('role')" class="form-control full-width col-12 no-padding" [ngClass]="{'disabled': savingUser}" [disabled]="savingUser" ngDefaultControl></ng-select>
                                <div *ngIf="errors.role && errors.role.length > 0" class="psi-alert psi-alert-danger">
                                    <div *ngFor="let err of errors.role; let i = index">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-2 checkbox inline-block">
                                <input type="checkbox" [(ngModel)]="pendingUser.membership.isApproved" class="form-check-input col-2" id="userIsApproved" name="userIsApproved" [disabled]="savingUser" />
                                <label for="userIsApproved" class="form-check-label col-10"><span>Approved</span></label>
                            </div>
                            <div class="col-6 col-md-2 checkbox inline-block">
                                <input type="checkbox" [(ngModel)]="pendingUser.membership.isLockedOut" class="form-check-input col-2" id="userIsLockedOut" name="userIsLockedOut" [disabled]="savingUser" />
                                <label for="userIsLockedOut" class="form-check-label col-10"><span>Locked Out</span></label>
                            </div>
                        </div>
                    </div>
                </form>
                <div *ngIf="!loadingUser && pendingUser" class="filters-footer">
                    <button (click)="resetUser()" type="button" class="btn btn-warning float-left report-button"><span class="fas fa-ban"></span> Reset</button>
                    <button (click)="save()" type="button" class="btn btn-success float-right report-button"><span class="fa fa-save"></span> Save</button>
                </div>
            </div>
        </div>
    </div>
</div>