import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
	providedIn: 'root'
})
export class UserService {
	private readonly _apiUrl = `${environment.apiUrl}/Users`;


	constructor(private http: HttpClient) { }


	public changeUser(args: ChangeUserArgs): Observable<any> {
		return this.http.post<any>(`${this._apiUrl}/Change`, args);
	}

	public createUser(args: CreateUserArgs): Observable<string> {
		return this.http.post<any>(`${this._apiUrl}/Create`, args);
	}

	public getUserActions(args: GetUserActionsArgs): Observable<UserActionReport> {
		return this.http.post<any>(`${this._apiUrl}/Actions`, args);
	}

	public getUserReport(args: GetUserReportArgs): Observable<UserReport> {
		return this.http.post<any>(`${this._apiUrl}/Report`, args);
	}

	public getUser(userId: string): Observable<User> {
		return this.http.get<any>(`${this._apiUrl}/${userId}`);
	}
}

export class ChangeUserArgs {
	public userId: string;
	public email: string;
	public comment: string;
	public roleId: string;
	public mobilePin: string;
	public isApproved: boolean;
	public isLockedOut: boolean;
}

export class CreateUserArgs {
	public userName: string;
	public password: string;
	public email: string;
	public secretQuestion: string;
	public secretAnswer: string;
	public roleId: string;
}

export class GetUserActionsArgs {
	public startDate: string; // .NET should parse this to a DateTime automatically
	public endDate: string; // .NET should parse this to a DateTime automatically
	public userName: string;
	public userActionTypeIdList: number[];
	public startIndex: number;
	public itemsPerPage: number;
	public sortField: string;
	public sortOrder: string;
}

export class GetUserReportArgs {
	public userName: string;
	public email: string;
	public roleIdList: string[];
	public startIndex: number;
	public itemsPerPage: number;
	public sortField: string;
	public sortOrder: string;
}

export class UserActionReport {
	public items: UserActionReportItem[];
	public totalRowCount: number;
}

export class UserActionReportItem {
	public id: number;
	public jsonData: string;
	public created: Date;
	public userId: string;
	public userName: string;
	public userActionTypeId: number;
	public userActionTypeName: string;
	public userActionTypeDescription: string;
}

export class User {
	public applicationId: string;
	public userId: string;
	public userName: string;
	public mobileAlias: string;
	public lastActivityDate: Date;
	public authToken: string;
	public permissionList: string[];
	public roleId: string;
	public roleName: string;
	public membership: UserMembership;
}

export class UserMembership {
	public applicationId: string;
	public userId: string;
	public mobilePin: string;
	public email: string;
	public createDate: Date;
	public comment: string;
	public password: string;
	public passwordQuestion: string;
	public passwordAnswer: string;
	public isApproved: boolean;
	public isLockedOut: boolean;
}

export class UserReport {
	public items: UserReportItem[];
	public totalRowCount: number;
}

export class UserReportItem {
	public userId: string;
	public applicationId: string;
	public userName: string;
	public lastActivityDate: Date;
	public email: string;
	public passwordQuestion: string;
	public passwordAnswer: string;
	public isApproved: boolean;
	public isLockedOut: boolean;
	public createDate: Date;
	public lastLoginDate: Date;
	public lastPasswordChangedDate: Date;
	public lastLockoutDate: Date;
	public failedPasswordAttemptCount: number;
	public roleId: string;
	public roleName: string;
	public showDetails: boolean;
}