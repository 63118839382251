import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { InmateAccount, InmatesService, SearchInmatesArgs } from 'src/app/services/inmates.service';

@Component({
	selector: 'inmate-accounts-typeahead',
	templateUrl: './inmate-accounts-typeahead.component.html'
})
export class InmateAccountsTypeaheadComponent implements OnInit {
	@Input() public activeOnly: boolean = false;
	@Input() public allowMultiple: boolean = false;
	@Input() public siteIdList: string[] = [];
	@Input() public selectedInmateAccounts: InmateAccount[] = [];
	@Output() public selectedInmateAccountsChange: EventEmitter<InmateAccount[]> = new EventEmitter<InmateAccount[]>();

	public inmateAccounts: Observable<InmateAccount[]>;
	public loadingInmateAccountsTypeahead: boolean = false;
	public inmateAccountSearchInput = new Subject<string>();


	constructor(
		private _inmatesService: InmatesService
	) { }

	ngOnInit(): void {
		this.loadInmateAccounts();
	}


	public emitChange(): void {
		this.selectedInmateAccountsChange.emit(this.selectedInmateAccounts);
	}

	public trackInmateAccountsByFn(item: InmateAccount) {
		return item.id;
	}

	public loadInmateAccounts() {
		this.inmateAccounts = concat(
			of([]), // default items
			this.inmateAccountSearchInput.pipe(
				distinctUntilChanged(),
				tap((): void => {
					this.loadingInmateAccountsTypeahead = true;
				}),
				switchMap((term): Observable<InmateAccount[]> => {
					const args: SearchInmatesArgs = {
						activeOnly: this.activeOnly,
						search: term,
						siteIdList: this.siteIdList,
						returnCount: 10
					};
					return this._inmatesService.searchInmateAccounts(args).pipe(
						catchError((): Observable<any[]> => {
							return of([]);
						}), // empty list on error
						tap((): void => {
							this.loadingInmateAccountsTypeahead = false;
						})
					)
				})
			)
		);
	}
}