import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SnotifyService } from 'ng-snotify';
import { HelperService } from 'src/app/helpers/helper.service';
import { SelectOptionGuid, SharedService } from 'src/app/services/shared.service';
import { CreateUserArgs, UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-create-user',
	templateUrl: './create-user.component.html'
})
export class CreateUserComponent implements OnInit {
	private def: any = {
		filters: {
			userName: null,
			password: null,
			passwordConfirm: null,
			email: null,
			secretQuestion: null,
			secretAnswer: null,
			roleId: null
		},
		errors: {
			userName: [],
			password: [],
			passwordConfirm: [],
			email: [],
			secretQuestion: [],
			secretAnswer: [],
			role: []
		}
	};

	public filters: any;
	public errors: any;

	public filterOptions: any = {
		roles: [],
		secretQuestions: HelperService.getSecurityQuestionList()
	};

	public creating: boolean = false;
	public loadingRoles: boolean = false;
	public rolesLoaded: boolean = false;


	constructor(
		private readonly _router: Router,
		private readonly _sharedService: SharedService,
		private readonly _snotify: SnotifyService,
		private readonly _userService: UserService
	) { }

	ngOnInit(): void {
		this.reset();
		this.loadRoles();
	}


	public cancel(): void {
		this._router.navigate(["/users/search"]);
	}

	public create(): void {
		if (this.creating) {
			return;
		}

		this.creating = true;

		if (this.validateFormData()) {
			const args: CreateUserArgs = { ...this.filters };

			this._userService.createUser(args)
				.subscribe(() => {
					this._snotify.success('User successfully created!', 'Create Success');
					this.reset();
					this.creating = false;
				}, (err: any): void => {
					console.error('Failed to create user', err);

					switch (err.status) {
						case 400:
							HelperService.getModelStateErrors(err).forEach(errMsg => {
								this._snotify.warning(errMsg, 'Invalid Data');
							});
							break;
						default:
							this._snotify.error('An unexpected error occurred creating the user.', 'Create Failed');
							break;
					}

					this.creating = false;
				});
		} else {
			this._snotify.warning('Please correct the indicated data fields', 'Invalid Data');
			this.creating = false;
		}
	}

	public loadRoles(): void {
		if (this.loadingRoles) {
			return;
		}

		this.loadingRoles = true;

		if (this.rolesLoaded) {
			this.rolesLoaded = false;
		}

		this._sharedService.getRoles()
			.subscribe((result: SelectOptionGuid[]) => {
				result = HelperService.sortArrByProp(result, 'name', false);

				this.filterOptions.roles = [...result];

				this.rolesLoaded = true;
				this.loadingRoles = false;
			}, (err: any): void => {
				console.error('Failed to retrieve list of all user roles', err);
				this.rolesLoaded = false;
				this.loadingRoles = false;
				this._snotify.error('Failed to retrieve list of all user roles', 'Load Failed');
			}, (): void => {
				//
			});
	}

	public reset(): void {
		this.filters = _.cloneDeep(this.def.filters);
		this.errors = _.cloneDeep(this.def.errors);
	}

	public resetErrors(key: string): void {
		this.errors[key] = [];
	}

	private validateFormData(): boolean {
		let returnValue: boolean = true;

		this.errors = _.cloneDeep(this.def.errors);

		// Validate username
		if (!this.filters.userName) {
			this.errors.userName.push('Username is required');
			returnValue = false;
		} else if (this.filters.userName.length < 5 || this.filters.userName.length > 256) {
			this.errors.userName.push('Username must be between 5 and 256 characters in length');
			returnValue = false;
		}

		// Validate email
		if (!this.filters.email) {
			this.errors.email.push('Email Address is required');
			returnValue = false;
		} else if (this.filters.email.length < 5 || this.filters.email.length > 256) {
			this.errors.email.push('Email Address must be between 5 and 256 characters in length');
			returnValue = false;
		} else if (!HelperService.isValidEmailAddress(this.filters.email)) {
			this.errors.email.push('Email Address is invalid');
			returnValue = false;
		}

		// Validate password
		if (!this.filters.password) {
			this.errors.password.push('Password is required');
			returnValue = false;
		} else {
			if (this.filters.password.length < 8 || this.filters.password.length > 30) {
				this.errors.password.push('Password must be between 8 and 30 characters in length');
				returnValue = false;
			}
			if (this.filters.password.search(/[a-z]/) < 0) {
				this.errors.password.push('Password must contain at least one lowercase letter');
				returnValue = false;
			}
			if (this.filters.password.search(/[A-Z]/) < 0) {
				this.errors.password.push('Password must contain at least one uppercase letter');
				returnValue = false;
			}
			if (this.filters.password.search(/[0-9]/) < 0) {
				this.errors.password.push('Password must contain at least number');
				returnValue = false;
			}
			if (!/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(this.filters.password)) {
				this.errors.password.push('Password must contain at least special character');
				returnValue = false;
			}
		}

		if (!this.filters.passwordConfirm) {
			this.errors.passwordConfirm.push('Confirm Password is required');
			returnValue = false;
		}

		if (this.filters.password && this.filters.passwordConfirm && this.filters.password !== this.filters.passwordConfirm) {
			this.errors.password.push('Passwords do not match');
			this.errors.passwordConfirm.push('Passwords do not match');
			returnValue = false;
		}

		// Validate Secret Question
		if (!this.filters.secretQuestion) {
			this.errors.secretQuestion.push('Security Question is required');
			returnValue = false;
		}

		// Validate Secret Answer
		if (!this.filters.secretAnswer) {
			this.errors.secretAnswer.push('Security Answer is required');
			returnValue = false;
		} else if (this.filters.secretAnswer.length > 100) {
			this.errors.secretAnswer.push('Security Answer cannot exceed 100 characters in length');
			returnValue = false;
		}

		// Validate Role
		if (!this.filters.roleId) {
			this.errors.role.push('Role is required');
			returnValue = false;
		}

		return returnValue;
	}
}